import React from 'react'
import AgentDataService from '../../../context/AgentDatabase';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Container, Divider, Grid, IconButton, Link, Paper, Stack, styled, Tabs, tabsClasses, Typography, useMediaQuery } from '@mui/material'
import coverImg from '../../../images/BgImages/event.jpg'
import { AccessTime, ExpandMore, LocationCity, LocationOn, LocationOnRounded, PunchClock, StorefrontRounded } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading';


const CustomCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 700,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',

}))

const CustomCard1 = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',
}))

const CustomPaper = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    padding: 40,
    width: '100%',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em',
}))

const CustomPaper1 = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em',
    // marginLeft: '-1em',
    padding: '14px'
}))

const slideUpAnimation = {
    from: {
        transform: 'translateY(100%)',
    },
    to: {
        transform: 'translateY(0)',
    },
};

const scaleAnimation = {
    from: {
        transform: 'scale(1)',
        opacity: 0.8,
    },
    to: {
        transform: 'scale(1.05)',
        opacity: 1,
    },
};

const Event = () => {
    const [agentData, setAgentData] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [value, setValue] = React.useState(0);
    const isMatch = useMediaQuery('(max-width:480px)');
    const isMatch1 = useMediaQuery('(max-width:400px)');
    const title = agentData.filter(item => item.categorySlug === 'events');
    const history = useNavigate();
    const [isHovered, setIsHovered] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const hoverBoxStyles = styled(Box)(({ theme }) => ({
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        backgroundColor: '#f0f0f0',
        overflow: 'hidden',
        maxHeight: isHovered ? 1000 : 0,
        transition: 'max-height 0.3s ease-in-out',
        zIndex: 1,
    }))


    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }

    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent();
        // console.log("agents", data.docs);
        setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const filteredClient = React.useMemo(() => {
        if (searchTerm.length > 0) {
            return title.filter(item => item.city.title === searchTerm)
        }
        return title
    }, [searchTerm, title])

    React.useEffect(() => {
        getAgents();
    }, []);

    if (title.length === 0) {
        return (
            <>
                {isMatch ?
                    <div style={{ padding: '16em 0em 16em 12em' }}>
                        <ReactLoading type="spokes" color="crimson" height={30} width={30} />
                    </div>

                    :
                    <div style={{ paddingTop: '25em', height: '1000px', paddingLeft: '48em', }}>
                        <ReactLoading type="spokes" color="crimson" height={50} width={50} />
                    </div>}
            </>
        )
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, p: isMatch ? 1 : 2 }}>
            {
                isMatch ?
                    <CustomPaper1>
                        <Typography gutterBottom
                            sx={{
                                pt: '20px',
                                pb: '20px',
                                color: '#fff',
                                fontSize: '2.5em',
                                textAlign: 'center',

                            }}>
                            Events
                        </Typography>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="visible arrows tabs example"
                            textColor="inherit"
                            indicatorColor='none'
                            sx={{
                                width: isMatch1 ? 300 : 352,
                                backgroundColor: 'rgba(0,0,0,.5) !important',
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                    color: '#fff'
                                },
                            }}
                        >
                            <Chip
                                sx={{
                                    fontSize: '15px',
                                    color: '#fff',
                                    mt: '7px',
                                    '&:hover': {
                                        background: 'linear-gradient(to right, #134e5e, #71b280)',
                                        color: '#fff',
                                        cursor: 'pointer'
                                    }
                                }}
                                label="All"
                                variant="outlined"
                                onClick={() => setSearchTerm("")}
                            />
                            {
                                [
                                    'Redmond',
                                    'Bothell',
                                    'Sammamish',
                                    'Bellevue',
                                    'Lynnwood',
                                    'Kirkland',
                                    'Lake Stevens',
                                    'Issaquah',
                                    'Everett',
                                    // 'Covington'
                                ].map((item) => {
                                    return (
                                        <>
                                            {
                                                searchTerm === item ?
                                                    <Chip
                                                        sx={{
                                                            fontSize: '13px',
                                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                            color: '#fff',
                                                            mt: '7px',
                                                            ml: '10px'
                                                        }}
                                                        label={item}
                                                        variant="filled"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                                    :
                                                    <Chip
                                                        sx={{
                                                            fontSize: '13px',
                                                            color: '#fff',
                                                            mt: '7px',
                                                            ml: '10px',
                                                            '&:hover': {
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        label={item}
                                                        variant="outlined"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                            }
                                        </>

                                    )
                                })
                            }
                        </Tabs>
                    </CustomPaper1>
                    :
                    <CustomPaper>
                        <Typography gutterBottom
                            sx={{
                                pt: '1em',
                                pb: '1em',
                                color: '#fff',
                                fontSize: '4em',
                                textAlign: 'center',

                            }}
                        >
                            Events
                        </Typography>
                        <Paper sx={{ backgroundColor: 'rgba(0,0,0,.5) !important' }}>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignContent="center"
                                sx={{ p: '2em', ml: '2em' }}
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                            background: 'white',
                                            m: '2px',
                                            ml: '16px'
                                        }} />
                                }>
                                <Chip
                                    sx={{
                                        fontSize: '1em',
                                        color: '#fff',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                            color: '#fff',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    label="All"
                                    variant="outlined"
                                    onClick={() => setSearchTerm("")}
                                />
                                {
                                    [
                                        'Redmond',
                                        'Bothell',
                                        'Sammamish',
                                        'Bellevue',
                                        'Lynnwood',
                                        'Kirkland',
                                        'Lake Stevens',
                                        'Issaquah',
                                        'Everett',
                                        // 'Covington'
                                    ].map((item) => {
                                        return (
                                            <>
                                                {
                                                    searchTerm === item ?
                                                        <Chip
                                                            sx={{
                                                                fontSize: '1em',
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                            }}
                                                            label={item}
                                                            variant="filled"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                        :
                                                        <Chip
                                                            sx={{
                                                                fontSize: '1em',
                                                                color: '#fff',
                                                                '&:hover': {
                                                                    background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                    color: '#fff',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                            label={item}
                                                            variant="outlined"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                }
                                            </>

                                        )
                                    })
                                }

                            </Stack>
                        </Paper>
                    </CustomPaper>
            }
            <Container maxWidth={isMatch ? "md" : "lg"}>
                <Grid container spacing={isMatch ? 1 : 0}
                    sx={{
                        mt: isMatch ? '1em' : '3em',
                        borderRadius: '5px',
                        backgroundColor: 'none',
                        mb: '1em'
                    }}
                >
                    <Grid xs={12}>
                        {
                            searchTerm ?
                                <>
                                    {
                                        isMatch ?
                                            <CustomCard1>
                                                <Stack direction="row" >
                                                    <StorefrontRounded sx={{ color: '#fff', fontSize: '1.8em', mr: '10px' }} />
                                                    <Typography
                                                        variant='h5'
                                                        sx={{ color: '#fff', fontSize: '1.5em', textTransform: 'capitalize', }}
                                                    >
                                                        Events in {searchTerm}
                                                    </Typography>
                                                </Stack>
                                            </CustomCard1>
                                            :
                                            <CustomCard>
                                                <Stack direction="row" >
                                                    <StorefrontRounded sx={{ color: '#fff', fontSize: '2.3em', mt: '1px', mr: '10px' }} />
                                                    <Typography
                                                        variant='h5'
                                                        sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                                    >
                                                        Events in {searchTerm}
                                                    </Typography>
                                                </Stack>
                                            </CustomCard>
                                    }
                                </>
                                :
                                null
                        }

                    </Grid>
                    {
                        title && filteredClient.sort((a, b) => (a.releaseDate > b.releaseDate) ? -1 : 1).map((agent) => {
                            return (
                                <>
                                    <Grid item xs={12} sm={6} md={4} key={agent.title} sx={{ p: isMatch ? null : '2em'}}>
                                        <Card sx={{
                                            mt: '2em',
                                            mb: '1em',
                                            borderRadius: '10px',
                                            maxWidth: 400,
                                            margin: 'auto',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '8px',
                                            transition: 'transform 0.3s, opacity 0.3s',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)',
                                            },
                                        }} >
                                            <CardMedia
                                                component="img"
                                                alt={agent.businessName}
                                                width={isMatch ? '250' : "300"}
                                                height={isMatch ? '300' : "250"}
                                                sx={{
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'norepeat',
                                                    backgroundPosition: 'center center',
                                                    transition: 'transform 0.3s, opacity 0.3s',
                                                    ...scaleAnimation,
                                                }}
                                                image={agent.mainImage}
                                                src="decoration and jeweleries"
                                                onClick={() => history(`/${agent.categorySlug}/${agent.agentSlug}`)}
                                                onClick1={handleClick}
                                            />

                                            <CardContent sx={{
                                                width: '100%',
                                                height: '100%',
                                                transition: 'transform 0.3s',
                                            }}>
                                                <Typography sx={{ fontSize: 18 }}>{agent.businessName} </Typography>
                                                <Stack display="flex" flexDirection="row" sx={{ mt: 2 }}>
                                                    <Stack sx={{ mr: '10px' }}>
                                                        <LocationOn fontSize='20px' />
                                                    </Stack>
                                                    <Stack>
                                                        <Typography variant="subtitle2" color="text.secondary" component="div"> {agent.address}</Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack display="flex" flexDirection="row" sx={{ mt: 2 }} justifyContent="start" alignItems="center">
                                                    <Stack sx={{ mr: '10px' }}>
                                                        <AccessTime fontSize='20px' />
                                                    </Stack>
                                                    <Stack>
                                                        <Typography variant="subtitle2" color="text.secondary" component="div"> {agent.date}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </CardContent>
                                            <CardActions disableSpacing sx={{ justifyContent: 'end', alignItems: 'center' }}>
                                                <Button variant='contained' color="error" size="medium"
                                                    onClick={() => history(`/${agent.categorySlug}/${agent.agentSlug}`)}
                                                    onClick1={handleClick}>
                                                    More Info
                                                </Button>
                                                {/* <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography>More Info</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Stack display="flex" flexDirection="row" sx={{ mt: 2 }}>
                                                            <Stack sx={{ mr: '10px' }}>
                                                                <LocationOn fontSize='20px' />
                                                            </Stack>
                                                            <Stack>
                                                                <Typography variant="subtitle2" color="text.secondary" component="div"> {agent.address}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack display="flex" flexDirection="row" sx={{ mt: 2 }} justifyContent="start" alignItems="center">
                                                            <Stack sx={{ mr: '10px' }}>
                                                                <AccessTime fontSize='20px' />
                                                            </Stack>
                                                            <Stack>
                                                                <Typography variant="subtitle2" color="text.secondary" component="div"> {agent.date}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </AccordionDetails>
                                                </Accordion> */}
                                            </CardActions>
                                        </Card >
                                    </Grid >

                                </>


                            )
                        })
                    }
                </Grid>
            </Container>


        </Box >
    )
}

export default Event
import React from 'react'
import { Box, Button, Card, CardMedia, Divider, Grid, Link, Paper, styled, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom'
import Img from '../../images/BgImages/temple.jpg'
import Img1 from '../../images/BgImages/Food2.jpg'
import Img2 from '../../images/BgImages/Img.jpg'
import Img3 from '../../images/BgImages/Img2.jpg'
import Img4 from '../../images/BgImages/Img3.jpg'
import Drawers from './Drawer';
import AgentDataService from '../../context/AgentDatabase';
import { LocationOnRounded } from '@mui/icons-material';
import slugify from 'react-slugify';
import noImg from '../../images/NoImage_Available.png'

// const CustomPaper = styled(Paper)(({ theme }) => ({
//     background: ` linear-gradient(
//       rgb(197 192 192 / 7%), 
//       rgb(0 0 0 / 28%)
//     ),url(${Img})`,
//     backgroundSize: 'cover',
//     backgroundRepeat: 'norepeat',
//     backgroundPosition: 'center center',
//     overflow: 'hidden',
//     // minHeight: '65vh',
//     width: '100%',
//     alignItems: 'start',
//     justifyContent: 'start',
//     marginTop: '7em',
// }))

// const CustomPaper1 = styled(Paper)(({ theme }) => ({
//     background: ` linear-gradient(
//         rgb(197 192 192 / 7%), 
//         rgb(0 0 0 / 28%)
//       ),url(${Img})`,
//     backgroundSize: 'cover',
//     backgroundRepeat: 'norepeat',
//     backgroundPosition: 'center center',
//     overflow: 'hidden',
//     // minHeight: '65vh',
//     width: '108%',
//     alignItems: 'start',
//     justifyContent: 'start',
//     marginTop: '6em',
//     marginLeft: '-1em',
//     padding: '24px'
// }))
const BgImages = [`${Img}`, `${Img1}`, `${Img2}`, `${Img3}`, `${Img4}`]

const DrawerLinks = () => {
    const { otherlinks } = useParams();
    const [agentData, setAgentData] = React.useState([]);
    const history = useNavigate();
    const title = agentData.filter(item => item.categorySlug === otherlinks);
    const title1 = agentData.filter(item => item.categorySlug === otherlinks);
    const isMatch = useMediaQuery('(max-width:480px)');
    const subHeading = title.map(item => item.title);
    const subHeading1 = [...new Set(subHeading)];
    const bgImage = title1.sort((a, b) => (a.releaseDate > b.releaseDate) ? 1 : -1).map(item => item.bgImage);
    const bgImage1 = bgImage.filter(item => item !== []);
    const str = otherlinks.replace(/[^a-zA-Z ]/g, " ");
    const match = window.location.pathname.split('/other-links/')[1] === 'indian-movies' ? true : false;

    //    console.log('slug', bgImage);

    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }

    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent();
        console.log("agents", data.docs);
        setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    React.useEffect(() => {
        getAgents();
    }, []);


    const CustomPaper = styled(Paper)(({ theme }) => ({
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${bgImage[0]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'norepeat',
        backgroundPosition: 'center center',
        overflow: 'hidden',
        // minHeight: '65vh',
        width: '100%',
        alignItems: 'start',
        justifyContent: 'start',
        marginTop: '7em',
    }))

    const CustomPaper1 = styled(Paper)(({ theme }) => ({
        background: ` linear-gradient(
            rgb(197 192 192 / 7%), 
            rgb(0 0 0 / 28%)
          ),url(${bgImage[0]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'norepeat',
        backgroundPosition: 'center center',
        overflow: 'hidden',
        // minHeight: '65vh',
        width: '108%',
        alignItems: 'start',
        justifyContent: 'start',
        marginTop: '6em',
        marginLeft: '-1em',
        padding: '24px'
    }))

    return (
        <Box sx={{ display: 'flex' }}>
            {
                isMatch ? null : <Drawers />
            }
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {
                    isMatch ?
                        <CustomPaper1>
                            <Typography
                                gutterBottom
                                sx={{
                                    color: '#fff',
                                    fontSize: '21px',
                                    fontFamily: 'ITC Clearface,serif',
                                    textTransform: 'capitalize'
                                }}
                            >
                                Seattle Indian {str}
                            </Typography>
                            <Typography gutterBottom
                                sx={{
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontFamily: 'ITC Clearface,serif'
                                }}
                            >
                                {subHeading}
                            </Typography>
                        </CustomPaper1>
                        :
                        <CustomPaper >
                            <Typography gutterBottom
                                sx={{
                                    p: '2em 2em 0em 2em',
                                    color: '#fff', fontSize: '3.5em',
                                    fontFamily: 'ITC Clearface,serif',
                                    textTransform: 'capitalize'
                                }}
                            >
                                Seattle Indian {str}
                            </Typography>
                            <Typography gutterBottom
                                sx={{
                                    pl: '3.9em',
                                    pb: '3em',
                                    color: '#fff',
                                    fontSize: '2em',
                                    fontFamily: 'ITC Clearface,serif'
                                }}
                            >
                                {subHeading1}
                            </Typography>
                        </CustomPaper>
                }
                <Grid container spacing={2}
                    sx={{
                        mt: isMatch ? '1em' : '3em',
                        borderRadius: '5px'
                    }}
                >
                    {
                        title
                        .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
                        .map((item) => {
                            return (
                                <Grid item xs={isMatch ? 12 : 6} key={item.title}>
                                    <Card
                                        elevation={2}
                                        sx={{
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            color: 'rgb(18, 24, 40)',
                                            transition: 'boxShadow 300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
                                            borderRadius: '5px',
                                            boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
                                            backgroundImage: 'none',
                                            overflow: 'hidden',

                                        }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box sx={{}}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 200, height: 180, p: '1em', borderRadius: '20px' }}
                                                    image={item.mainImage[0] ? item.mainImage : noImg}
                                                    alt="Live from space album cover"
                                                />
                                            </Box>
                                            {/* <div style={{ marginLeft: '1em' }}>
                                                <Typography
                                                    sx={{
                                                        textAlign: 'start',
                                                        fontWeight: 500,
                                                        fontSize: '1.0825rem',
                                                        lineHeight: '1.375',
                                                        fontFamily: 'ITC Clearface,serif',
                                                        color: 'rgb(18, 24, 40)',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    {item.businessName}
                                                </Typography>
                                            </div> */}
                                            <Grid xs={12} sx={{ mt: '10px', ml: '2em' }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1.3rem',
                                                        fontFamily: 'ITC Clearface,serif',
                                                        color: '#02367d'
                                                    }}
                                                >
                                                    {item.businessName}
                                                </Typography>
                                                {
                                                    (match === true) ?
                                                        <div>
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                    
                                                                }}
                                                            >
                                                                Start Date : {item.contactNumber}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                    
                                                                }}
                                                            >
                                                                End Date : {item.phoneNumber}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                    
                                                                }}
                                                            >
                                                                City : {item.city.title}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                    textAlign: 'start'
                                                                }}
                                                                color='text.secondary'
                                                            >
                                                                Venue : {item.address}
                                                            </Typography>
                                                        </div>
                                                        :
                                                        <div>
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                }}
                                                            >
                                                                Contact : {item.agentName}
                                                            </Typography>
                                                            {/* <Typography
                                                    sx={{
                                                        ml: '2px',
                                                        fontWeight: 500,
                                                        fontSize: isMatch ? '1rem' : '1.0825rem',
                                                        lineHeight: '1.375',
                                                        fontFamily: 'ITC Clearface,serif',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Cell : {item.contactNumber}
                                                </Typography> */}
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                }}

                                                            >
                                                                Phone : {item.phoneNumber}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                }}
                                                            >
                                                                Email :
                                                                <Link underline='none' sx={{ ml: '10px' }}>
                                                                    {item.emailId}
                                                                </Link>
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                }}
                                                            >
                                                                Website :
                                                                <Link underline='none' sx={{ ml: '10px' }}>
                                                                    {item.website}
                                                                </Link>
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    ml: '2px',
                                                                    fontWeight: 500,
                                                                    fontSize: isMatch ? '1rem' : '1.0825rem',
                                                                    lineHeight: '1.375',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    textDecoration: 'none',
                                                                }}
                                                                color='text.secondary'
                                                            >
                                                                {item.address}
                                                            </Typography>

                                                        </div>
                                                }

                                                {/* <Divider direction='row' sx={{ color: '#000', width: '100%', mt: '10px', mb: '10px' }} />
                                                    <Typography
                                                        sx={{
                                                            ml: '2px',
                                                            fontWeight: 500,
                                                            fontSize: isMatch ? '1rem' : '1.0825rem',
                                                            lineHeight: '1.375',
                                                            fontFamily: 'ITC Clearface,serif',
                                                            textDecoration: 'none',
                                                        }}
                                                        color='text.secondary'
                                                    >
                                                        {agent.address}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            ml: '2px',
                                                            fontWeight: 500,
                                                            fontSize: isMatch ? '1rem' : '1.0825rem',
                                                            lineHeight: '1.375',
                                                            fontFamily: 'ITC Clearface,serif',
                                                            textDecoration: 'none',
                                                        }}
                                                        color='text.secondary'
                                                    >
                                                        Email :
                                                        <Link underline='none' sx={{ ml: '10px' }}>
                                                            {agent.emailId}
                                                        </Link>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            ml: '2px',
                                                            fontWeight: 500,
                                                            fontSize: isMatch ? '1rem' : '1.0825rem',
                                                            lineHeight: '1.375',
                                                            fontFamily: 'ITC Clearface,serif',
                                                            textDecoration: 'none',
                                                        }}
                                                        color='text.secondary'
                                                    >
                                                       Website :
                                                        <Link underline='none' sx={{ ml: '10px' }}>
                                                            {agent.website}
                                                        </Link>
                                                    </Typography> */}
                                            </Grid>

                                        </Box>
                                        <Divider sx={{ m: '1em' }} />
                                        <Box sx={{ m: '10px 20px', display: 'flex', flexDirection: 'end', justifyContent: 'flex-end' }}>
                                            <Button variant='contained' onClick={() => history(`/${otherlinks}/${slugify(item.businessName)}`)} onClick1={handleClick}>Know More</Button>
                                        </Box>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
        </Box >
    )
}

export default DrawerLinks


const bgImages = [
    {
        value: '0',
        img: `${Img}`,
    },
    {
        value: '0',
        img: `${Img1}`,
    },
    {
        value: '0',
        img: `${Img2}`,
    },
    {
        value: '0',
        img: `${Img3}`,
    },
    {
        value: '0',
        img: `${Img4}`,
    },
    {
        value: '0',
        img: `${Img}`,
    },
]




//how to sort empty array of values in reactjs?
import React, { useState, useEffect } from 'react'
import { styled, alpha } from '@mui/material/styles';
import { Backdrop, Button, CircularProgress, Container, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, CardContent, Stack, Grid } from '@mui/material';
import AgentPannel from './AgentPannel';
import { database } from '../../firebase/firebase';
import { DeleteForever, Edit, EditAttributes, Refresh, SortRounded } from '@mui/icons-material';
import AgentDataService from '../../context/AgentDatabase';
import { getDocs, orderBy, query } from 'firebase/firestore';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    head: {
        backgroundColor: '#2979ff',
        color: '#fff',
    },
    body: {
        fontSize: 14,
        color: '#fff'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));


const AdminDataPannel = () => {
    const [agents, setAgents] = useState([]);
    const [agentsId, setAgentsId] = useState("");
    const TotalFood =  agents.filter(item => item.businessType.title === "Indian Food").length;
    const TotalGrocery =  agents.filter(item => item.businessType.title === "Indian Grocery Stores").length;
    const TotalDecorations =  agents.filter(item => item.businessType.title === "Decorations and Jeweleries").length;
    const TotalRealtors =  agents.filter(item => item.businessType.title === "Real Estate").length;
    const TotalPhotographers =  agents.filter(item => item.businessType.title === "Photography").length;
    const TotalEvents =  agents.filter(item => item.businessType.title === "Events").length;

    useEffect(() => {
        getAgents();
    }, []);

    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent();
        console.log("agents", data.docs);
        setAgents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const deleteHandler = async (id) => {
        if (window.confirm('Are you sure')) {
            await AgentDataService.deleteAgent(id);
            getAgents();
        }

    };

    const getAgentsIdHandler = async (id) => {
        setAgentsId(id);
    };

    const getPost = async () => {
        const data = await AgentDataService.getSortedAgent();
        const newData = data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        setAgents(newData);
    };
    


    return (
        <>
            <div style={{ marginTop: 10, background: 'beige', marginBottom: 20 }}>

                <Paper sx={{
                    flexGrow: 1,
                    height: 'auto',
                    margin: 'auto',
                    maxWidth: 1100,
                    paddingBottom: 5,
                }}>
                    <AgentPannel id={agentsId} setAgentId={setAgentsId} />
                </Paper>
                <Container maxWidth="lg" sx={{ m: '1em 10em', p: '1em '}}>

                    <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', p: '1em' }}>
                        <Typography variant='h4'>Summary</Typography>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }} >
                                    <Box component="span" sx={{ p: 3, m: '1em', background: "#2a5298" }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 10px', }}>
                                        <Typography variant='h7'>Business Category - Indian Food</Typography>
                                        <Typography variant='h7'>Total Number - {TotalFood}</Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }} >
                                    <Box component="span" sx={{ p: 3, m: '1em', background: "#de6161" }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 10px', }}>
                                        <Typography variant='h7'>Business Category - Grocery Stories</Typography>
                                        <Typography variant='h7'>Total Number - {TotalGrocery}</Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }} >
                                    <Box component="span" sx={{ p: 3, m: '1em', background: "#F3904F" }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 10px', }}>
                                        <Typography variant='h7'>Business Category - Decoraters</Typography>
                                        <Typography variant='h7'>Total Number - {TotalDecorations}</Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }} >
                                    <Box component="span" sx={{ p: 3, m: '1em', background: "#00c3ff" }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 10px', }}>
                                        <Typography variant='h7'>Business Category - Realtors</Typography>
                                        <Typography variant='h7'>Total Number - {TotalRealtors}</Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }} >
                                    <Box component="span" sx={{ p: 3, m: '1em', background: "#ffff1c" }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 10px', }}>
                                        <Typography variant='h7'>Business Category - Photographers</Typography>
                                        <Typography variant='h7'>Total Number - {TotalPhotographers}</Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }} >
                                    <Box component="span" sx={{ p: 3, m: '1em', background: "#ffff1c" }} />
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 10px', }}>
                                        <Typography variant='h7'>Business Category - Events</Typography>
                                        <Typography variant='h7'>Total Number - {TotalEvents}</Typography>
                                    </div>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
                <Container maxWidth="xl" style={{ marginTop: '1em' }}>

                    <TableContainer component={Paper} style={{ margin: 'auto', maxWidth: 1300, }}>
                        <Table style={{}}>
                            <TableHead sx={{ background: '#2c7744', color: '#fff' }}>
                                <TableRow>
                                    <StyledTableCell>S.no</StyledTableCell>
                                    <StyledTableCell>Business Name</StyledTableCell>
                                    <StyledTableCell>Categories</StyledTableCell>
                                    <StyledTableCell> City</StyledTableCell>
                                    <StyledTableCell>Address</StyledTableCell>
                                    <StyledTableCell>PhoneNumber</StyledTableCell>
                                    <StyledTableCell>Main Image</StyledTableCell>
                                    <StyledTableCell align="center" sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={getAgents}
                                            startIcon={<Refresh />}
                                            size="medium"
                                        ></Button>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            onClick={getPost}
                                            startIcon={<SortRounded />}
                                            size="medium"
                                            sx={{ ml: '10px' }}
                                        >
                                        </Button>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {agents.map((doc, index) => {
                                    return (
                                        <StyledTableRow key={doc.id}
                                            sx={{
                                                background: doc.businessType.title === "Indian Food" ? "#2a5298" : doc.businessType.title === "Indian Grocery Stores" ? "#de6161" : doc.businessType.title === "Decorations and Jeweleries" ? "#F3904F" : doc.businessType.title === "Real Estate" ? "#00c3ff" : doc.businessType.title === "Photography" ? "#ffff1c" : "#e0e0e0",
                                                color: doc.businessType.title === "Indian Food" ? "white" : "",
                                            }}
                                        >
                                            <StyledTableCell >{index + 1}</StyledTableCell>
                                            <StyledTableCell align='left'>{doc.businessName}</StyledTableCell>
                                            <StyledTableCell align='left'>{doc.businessType.title}</StyledTableCell>
                                            <StyledTableCell align='left'>{doc.city.title ? doc.city.title : "None"}</StyledTableCell>
                                            <StyledTableCell align='left'>{doc.address}</StyledTableCell>
                                            <StyledTableCell align='left'>{doc.phoneNumber}</StyledTableCell>
                                            <StyledTableCell align="left"><img src={doc.mainImage} alt="image_png" width="100" height="80" /></StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    onClick={(e) => deleteHandler(doc.id)}
                                                    startIcon={<DeleteForever />}
                                                >

                                                </Button>
                                                <p></p>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    sx={{ mt: '1em' }}
                                                    onClick={() => {
                                                        getAgentsIdHandler(doc.id)
                                                        console.log("id", (doc.id)); window.scrollTo({ top: 0, behavior: 'smooth' })
                                                    }}
                                                    startIcon={<Edit />
                                                    }></Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )

                                })}
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Container>
            </div>

        </>
    )
}

export default AdminDataPannel
//how to sort by name when onClickis triggered in reactjs?
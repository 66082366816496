import React,{Component} from 'react';
import { auth } from "../../firebase/firebase"
import AdminLogin from './AdminLogin';
import AdminTab from './AdminTab';


class Adminindex extends Component{
    constructor(props){
        super(props);

        this.state ={
            user: null
        }
    }
    componentDidMount(){
        this.authListener()
    }
   async authListener(){
        await auth.onAuthStateChanged((user) => {
            if(user){
                this.setState({user});
            }
            else{
                this.setState({user : null});
            }
        });
    }
   
    render(){
        return (
            <div>
                {this.state.user ? (<AdminTab/>) : (<AdminLogin/>)}
            </div>
        )
    }
}
export default Adminindex;
import React from 'react'
import { Avatar, Box, Button, Card, CardActionArea, CardContent, CardMedia, Chip, Container, Divider, Grid, Link, Paper, Skeleton, styled, Tabs, tabsClasses, Typography, useMediaQuery } from '@mui/material'
import AgentDataService from '../../../../context/AgentDatabase';
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Stack } from '@mui/system'
import { LocationOnRounded, StorefrontRounded } from '@mui/icons-material'
import coverImg from '../../../../images/BgImages/Decoration.jpg'
import ReactLoading from 'react-loading';

const CustomCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 700,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',

}))

const CustomCard1 = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',
}))

function Decoration() {
    const [agentData, setAgentData] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [value, setValue] = React.useState(0);
    const history = useNavigate();
    const title = agentData.filter(item => item.categorySlug === 'decorations-and-jeweleries');
    // const match = window.location.pathname.split('/')[1] === 'real-estate';
    // const heading= title[0].businessType.title;
    const subHeading = title.map(item => item.title);
    const subHeading1 = [...new Set(subHeading)]
    const isMatch = useMediaQuery('(max-width:480px)')
    //    console.log('slug', slugify());
    //    console.log('slug1', agentData
    const bgImage = title.sort((a, b) => (a.releaseDate > b.releaseDate) ? 1 : -1).map(item => item.bgImage);
    // console.log(str.replace(/[^a-zA-Z ]/g, " "));
    // const subTitle = 
    const match = window.location.pathname.split('/')[1] === 'indian-grocery-stores' ? true : false;
    const match1 = window.location.pathname.split('/')[1] === 'decorations-and-jeweleries' ? true : false;


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }


    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent();
        // console.log("agents", data.docs);
        setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const filteredClient = React.useMemo(() => {
        if (searchTerm.length > 0) {
            return title.filter(item => item.city.title === searchTerm)
        }
        return title
    }, [searchTerm, title])

    React.useEffect(() => {
        getAgents();
    }, []);

    if (title.length === 0) {
        return (
            <>
                {isMatch ?
                    <div style={{ padding: '16em 0em 16em 12em' }}>
                        <ReactLoading type="spokes" color="crimson" height={30} width={30} />
                    </div>

                    :
                    <div style={{ paddingTop: '25em', height: '1000px', paddingLeft: '48em', }}>
                        <ReactLoading type="spokes" color="crimson" height={50} width={50} />
                    </div>}
            </>
        )
    }


    const CustomPaper = styled(Paper)(({ theme }) => ({
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${coverImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'norepeat',
        backgroundPosition: 'center center',
        overflow: 'hidden',
        padding: 40,
        width: '100%',
        alignItems: 'start',
        justifyContent: 'start',
        marginTop: '4em',
    }))

    const CustomPaper1 = styled(Paper)(({ theme }) => ({
        background: ` linear-gradient(
            rgb(197 192 192 / 7%), 
            rgb(0 0 0 / 28%)
          ),url(${coverImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'norepeat',
        backgroundPosition: 'center center',
        overflow: 'hidden',
        alignItems: 'start',
        justifyContent: 'start',
        marginTop: '4em',
        // marginLeft: '-1em',
        padding: '14px'
    }))

    return (

        <Box component="main" sx={{ flexGrow: 1, p: isMatch ? 1 : 2 }}>
            {
                isMatch ?
                    <CustomPaper1>
                        <Typography gutterBottom
                            sx={{
                                pt: '20px',
                                pb: '20px',
                                color: '#fff',
                                fontSize: '2.5em',
                                textAlign: 'center',

                            }}>
                            Decoration &   Jeweleries
                        </Typography>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="visible arrows tabs example"
                            textColor="inherit"
                            indicatorColor='none'
                            sx={{
                                width: 352,
                                backgroundColor: 'rgba(0,0,0,.5) !important',
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                    color: '#fff'
                                },
                            }}
                        >
                            <Chip
                                sx={{
                                    fontSize: '15px',
                                    color: '#fff',
                                    mt: '7px',
                                    '&:hover': {
                                        background: 'linear-gradient(to right, #134e5e, #71b280)',
                                        color: '#fff',
                                        cursor: 'pointer'
                                    }
                                }}
                                label="All"
                                variant="outlined"
                                onClick={() => setSearchTerm("")}
                            />
                            {
                                [
                                    'Redmond',
                                    'Bothell',
                                    'Sammamish',
                                    'Bellevue',
                                    'Lynnwood',
                                    'Kirkland',
                                    'Lake Stevens',
                                    'Issaquah',
                                    'Everett',
                                    // 'Covington'
                                ].map((item) => {
                                    return (
                                        <>
                                            {
                                                searchTerm === item ?
                                                    <Chip
                                                        sx={{
                                                            fontSize: '13px',
                                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                            color: '#fff',
                                                            mt: '7px',
                                                            ml: '10px'
                                                        }}
                                                        label={item}
                                                        variant="filled"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                                    :
                                                    <Chip
                                                        sx={{
                                                            fontSize: '13px',
                                                            color: '#fff',
                                                            mt: '7px',
                                                            ml: '10px',
                                                            '&:hover': {
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        label={item}
                                                        variant="outlined"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                            }
                                        </>

                                    )
                                })
                            }
                        </Tabs>
                    </CustomPaper1>
                    :
                    <CustomPaper >
                        <Typography gutterBottom
                            sx={{
                                pt: '1em',
                                pb: '1em',
                                color: '#fff',
                                fontSize: '4em',
                                textAlign: 'center',

                            }}
                        >
                            Decoration & Jeweleries
                        </Typography>
                        <Paper sx={{ backgroundColor: 'rgba(0,0,0,.5) !important' }}>

                            <Stack
                                direction="row"
                                spacing={2}
                                alignContent="center"
                                sx={{ p: '2em', ml: '2em' }}
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                            background: 'white',
                                            m: '2px',
                                            ml: '16px'
                                        }} />
                                }>
                                <Chip
                                    sx={{
                                        fontSize: '1em',
                                        color: '#fff',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                            color: '#fff',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    label="All"
                                    variant="outlined"
                                    onClick={() => setSearchTerm("")}
                                />
                                {
                                    [
                                        'Redmond',
                                        'Bothell',
                                        'Sammamish',
                                        'Bellevue',
                                        'Lynnwood',
                                        'Kirkland',
                                        'Lake Stevens',
                                        'Issaquah',
                                        'Everett',
                                        // 'Covington'
                                    ].map((item) => {
                                        return (
                                            <>
                                                {
                                                    searchTerm === item ?
                                                        <Chip
                                                            sx={{
                                                                fontSize: '1em',
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                            }}
                                                            label={item}
                                                            variant="filled"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                        :
                                                        <Chip
                                                            sx={{
                                                                fontSize: '1em',
                                                                color: '#fff',
                                                                '&:hover': {
                                                                    background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                    color: '#fff',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                            label={item}
                                                            variant="outlined"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                }
                                            </>

                                        )
                                    })
                                }

                            </Stack>
                        </Paper>
                    </CustomPaper>
            }
            <Container maxWidth={isMatch ? "md" : "xl"}>
                <Grid container spacing={isMatch ? 1 : 2}
                    sx={{
                        mt: isMatch ? '1em' : '3em',
                        borderRadius: '5px',
                        backgroundColor: 'none',
                        mb: '1em'
                    }}
                >
                    <Grid xs={12}>
                        {
                            searchTerm ?
                                <>
                                    {
                                        isMatch ?
                                            <CustomCard1>
                                                <Stack direction="row" >
                                                    <StorefrontRounded sx={{ color: '#fff', fontSize: '1.8em', mr: '10px' }} />
                                                    <Typography
                                                        variant='h5'
                                                        sx={{ color: '#fff', fontSize: '1.5em', textTransform: 'capitalize', }}
                                                    >
                                                        Decoration & Jeweleries in {searchTerm}
                                                    </Typography>
                                                </Stack>
                                            </CustomCard1>
                                            :
                                            <CustomCard>
                                                <Stack direction="row" >
                                                    <StorefrontRounded sx={{ color: '#fff', fontSize: '2.3em', mt: '1px', mr: '10px' }} />
                                                    <Typography
                                                        variant='h5'
                                                        sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                                    >
                                                        Decoration & Jeweleries in {searchTerm}
                                                    </Typography>
                                                </Stack>
                                            </CustomCard>
                                    }
                                </>
                                :
                                null
                        }

                    </Grid>

                    {
                        title && filteredClient.sort((a, b) => (b.releaseDate > a.releaseDate) ? -1 : 1).map((agent) => {
                            return (
                                <>
                                    <Grid item xs={12} sm={6} md={4} key={agent.title}>
                                        <Card sx={{ mt: '2em', mb: '1em', borderRadius: '10px', maxWidth: isMatch ? 400 : null }}>
                                            <CardMedia
                                                component="img"
                                                alt={agent.businessName}
                                                width={isMatch ? '250' : "auto"}
                                                height={isMatch ? '300' : "400"}
                                                sx={{
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'norepeat',
                                                    backgroundPosition: 'center center',
                                                }}
                                                image={agent.mainImage}
                                                src="decoration and jeweleries"
                                                onClick={() => history(`/${agent.categorySlug}/${agent.agentSlug}`)}
                                                onClick1={handleClick}
                                            />
                                            <Grid item xs={12} sx={{ background: 'lightgrey', m: isMatch ? '15px' : '2em 1em', borderRadius: '5px', p: '5px', overflow: 'hidden' }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: isMatch ? '1.3rem' : '1.7rem',
                                                        color: '#02367d',
                                                        pl: isMatch ? '1.2em' : '1em'
                                                    }}
                                                >
                                                    {agent.businessName}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        ml: isMatch ? '3px' : '5px',
                                                        fontWeight: 400,
                                                        fontSize: isMatch ? '1rem' : '17px',
                                                        lineHeight: '1.575',

                                                        textDecoration: 'none',
                                                        pl: '1.5em'
                                                    }}

                                                >
                                                    {(agent.phoneNumber) ? `Phone : ${agent.phoneNumber}` : null}
                                                </Typography>
                                                {(agent.emailId) ?
                                                    <Typography
                                                        sx={{
                                                            ml: isMatch ? '3px' : '5px',
                                                            fontWeight: 400,
                                                            fontSize: isMatch ? '1rem' : '17px',
                                                            lineHeight: '1.575',
                                                            textDecoration: 'none',
                                                            pl: '1.5em'
                                                        }}

                                                    >
                                                        Email :
                                                        <Link underline='none' href={`mailto:${agent.emailId}`} sx={{ ml: '10px' }}>
                                                            {agent.emailId}
                                                        </Link>
                                                    </Typography>
                                                    :
                                                    null}
                                                {/* <Typography
                                                    sx={{
                                                        ml: '2px',
                                                        fontWeight: 400,
                                                        fontSize: isMatch ? '1rem' : '17px',
                                                        lineHeight: '1.575',

                                                        textDecoration: 'none',
                                                        pl: '1.5em'
                                                    }}

                                                >
                                                    Website:
                                                    <Link underline='none' href={agent.website} sx={{ ml: '10px' }} target="_blank">
                                                        {agent.website}
                                                    </Link>
                                                </Typography> */}
                                                <Stack direction="row" spacing={0} sx={{
                                                    pl: '1.5em',
                                                    pt: '5px',
                                                    pb: '1em'
                                                }}>
                                                    <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                    <Typography
                                                        sx={{

                                                            fontWeight: 400,
                                                            fontSize: isMatch ? '1rem' : '17px',
                                                            lineHeight: '1.575',

                                                            textDecoration: 'none',
                                                        }}
                                                        color='text.secondary'
                                                    >
                                                        {agent.address}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Box sx={{ p: isMatch ? '1px 9px' : null, ml: '1em', mr: '0.3em' }}>
                                                <Typography
                                                    sx={{
                                                        ml: '2px',
                                                        fontWeight: 400,
                                                        fontSize: isMatch ? '15px' : '17px',
                                                        lineHeight: '1.575',

                                                        textDecoration: 'none',
                                                    }}
                                                    color='text.secondary'
                                                >
                                                    {agent.shortdescription}
                                                </Typography>
                                                <Divider sx={{ m: isMatch ? '1em 0em' : '1em' }} />
                                                <Box sx={{ m: '10px', display: 'flex', flexDirection: 'end', justifyContent: 'flex-end' }}>
                                                    <Button variant='contained' onClick={() => history(`${agent.agentSlug}`)} onClick1={handleClick}>Know More</Button>
                                                </Box>

                                            </Box>
                                        </Card>
                                    </Grid>

                                </>


                            )
                        })
                    }
                </Grid>
            </Container>
        </Box>
    )
}

export default Decoration
//how to open link in a new tab in















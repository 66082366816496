import React from 'react'
import { AccessTime, Add, FmdGood, Remove } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, CardMedia, Collapse, Divider, Grid, ListItem, ListItemText, Paper, Stack, styled, Typography } from '@mui/material';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';


const CustomRemove = styled(Remove)(({ theme, isMatch }) => ({
  background: "rgb(255 141 0)",
  borderRadius: "1000px",
  color: "white",
  height: "20px",
  width: "20px"
}));

const CustomAdd = styled(Add)(({ theme, isMatch }) => ({
  background: "rgb(255 141 0)",
  borderRadius: "1000px",
  color: "white",
  height: "20px",
  width: "20px"
}));

const MobEventDP = (props) => {
  const [open1, setOpen1] = React.useState(true);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(true);

  const { Event, Address, Options } = props;

  const handleClickTicInfo = () => {
    setOpen1(!open1);
  };
  const handleClickEventDet = () => {
    setOpen2(!open2);
  };

  const handleClickVenue = () => {
    setOpen3(!open3);
  };

  const handleClickEventPer = () => {
    setOpen4(!open4);
  };

  const handleClickArtistInfo = () => {
    setOpen5(!open5);
  };

  return (
    <Grid container spacing={0}>
      {
        Event.map((item) => {
          return (
            <>
              <Grid xs={12}>
                <ListItem button onClick={handleClickTicInfo} sx={{ background: 'whitesmoke' }}>
                  <ListItemText sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 17,
                      fontWeight: 500
                    }
                  }} primary="Ticket Information" />
                  {open1 ? <CustomRemove /> : <CustomAdd />}
                </ListItem>
                <Collapse in={open1} timeout="auto" unmountOnExit  >
                  {(item.fields).map((item, index) => {
                    return (
                      <Grid xs={12} spacing={2} display="flex" key={index}
                        sx={{ bgcolor: '#f2f2f2', m: '0.7em', borderRadius: '10px', p: '5px' }}>
                        <Grid item xs={4}>
                          <Stack spacing={0} direction="column">
                            <Typography
                              sx={{
                                color: '#000',
                                fontWeight: 500,
                                fontSize: '15px'
                              }}>
                              {item.Tickets} Level
                            </Typography>
                            <Typography
                              variant='h7'
                              sx={{
                                color: '#f50000bd',
                                fontWeight: 500,
                                fontSize: '11px'
                              }}>
                              Age Restriction 16+
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack spacing={0} direction="column" display="flex" justifycontents="center" alignItems="center">
                            <Typography
                              variant='h7'
                              sx={{
                                color: '#08a182',
                                fontWeight: 500,
                                fontSize: '15px'
                              }}>
                              {item.Tickets}.00
                            </Typography>
                            {(item.availability === "Selling Fast") ?
                              <Typography
                                variant='caption'
                                sx={{
                                  color: '#5eaa1e',
                                  fontWeight: 500,
                                  fontSize: '11px'
                                }}>
                                {item.availability}
                              </Typography>
                              :
                              <Typography
                                variant='caption'
                                color="primary"
                                sx={{
                                  fontSize: '11px',
                                  fontWeight: 500,
                                }}>
                                {item.availability}
                              </Typography>}
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack spacing={0} direction="column" justifycontents="center" alignItems="center">
                            <Typography
                              variant='caption'
                              sx={{
                                color: '#000',
                                fontWeight: 500,
                                fontSize: '11px'
                              }}>
                              Ticket close on
                            </Typography>
                            <Typography
                              variant='11px'
                              sx={{
                                color: '#000',
                                fontWeight: 500,
                                fontSize: '15px'
                              }}>
                              {item.ticketDate}
                            </Typography>

                          </Stack>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Collapse>
              </Grid>
              <Grid xs={12}>
                <ListItem button onClick={handleClickEventDet} sx={{ background: 'whitesmoke' }}>
                  <ListItemText sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 17,
                      fontWeight: 500
                    }
                  }} primary="Event Details" />
                  {open2 ? <CustomRemove /> : <CustomAdd />}
                </ListItem>
                <Collapse in={open2} timeout="auto" unmountOnExit sx={{ p: '1em' }}>
                  <Card sx={{ mt: '1em' }}>
                    <SimpleReactLightbox>
                      <SRLWrapper options={Options}>
                        <CardMedia
                          component="img"
                          sx={{ width: '100%', }}
                          image={item.gallery}
                          alt="Imagee"
                        />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  </Card>
                </Collapse>
              </Grid>
              <Grid xs={12}>
                <ListItem button onClick={handleClickVenue} sx={{ background: 'whitesmoke' }}>
                  <ListItemText sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 17,
                      fontWeight: 500
                    }
                  }} primary="Venue Details" />
                  {open3 ? <CustomRemove /> : <CustomAdd />}
                </ListItem>
                <Collapse in={open3} timeout="auto" unmountOnExit sx={{ p: '1em' }}>
                  <Card sx={{ mt: '1em' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Stack direction="row" spacing={0.5}>
                        <FmdGood sx={{ color: '#f50000', fontSize: '15px' }} />
                        <Typography sx={{ fontSize: '11px', fontWeight: 500 }} gutterBottom>
                          {item.address}
                        </Typography>
                      </Stack>
                    </CardContent>
                    <SimpleReactLightbox>
                      <SRLWrapper options={Options}>
                        <CardMedia
                          component="img"
                          sx={{ width: '100%', }}
                          image={item.bgImage}
                          alt="Imagee"
                        />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  </Card>
                </Collapse>
              </Grid>
              <Grid xs={12}>
                <ListItem button onClick={handleClickEventPer} sx={{ background: 'whitesmoke' }}>
                  <ListItemText sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 17,
                      fontWeight: 500
                    }
                  }} primary="Event Performers" />
                  {open4 ? <CustomRemove /> : <CustomAdd />}
                </ListItem>
                <Collapse in={open4} timeout="auto" unmountOnExit sx={{ p: '1em' }}>
                  <Card>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography sx={{ fontSize: '15px' }}>
                        Artists
                      </Typography>
                    </CardContent>
                    <Divider />
                    <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                      <Avatar
                        alt="Artist Image"
                        src={item.agentProfile}
                        sx={{ width: 100, height: 100 }}
                      />
                    </Stack>
                    <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                      <Typography sx={{ fontSize: '15px' }} color="primary">
                        {item.agentName}
                      </Typography>
                    </Stack>
                  </Card>
                </Collapse>
              </Grid>
              <Grid xs={12}>
                <ListItem button onClick={handleClickArtistInfo} sx={{ background: 'whitesmoke' }}>
                  <ListItemText sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: 17,
                      fontWeight: 500
                    }
                  }} primary="Artist Info" />
                  {open5 ? <CustomRemove /> : <CustomAdd />}
                </ListItem>
                <Collapse in={open5} timeout="auto" unmountOnExit sx={{ p: '1em' }}>
                  <Card >
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" sx={{ fontSize: '15px' }}>
                        Artist details
                      </Typography>
                    </CardContent>
                    <Divider />
                    <Stack direction="row" spacing={2}>
                      <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                        <Avatar
                          alt="Artist Image"
                          src={item.agentProfile}
                          sx={{ width: 100, height: 100 }}
                        />
                      </Stack>
                      <Stack direction="column" display="flex" justifyContent="center" alignItems="start" sx={{ ml: '10px' }}>
                        <Typography variant="subtitle2" color="success" gutterBottom>
                          {item.agentName}
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }} gutterBottom>
                          {item.shortdescription}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Card>
                  <Card sx={{mt: '15px'}}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography component="div" sx={{ fontSize: '15px' }}>
                        Organizer Details
                      </Typography>
                    </CardContent>
                    <Divider />
                    <Stack direction="row" spacing={2}>
                      <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                        <Avatar
                          alt="Artist Image"
                          src={item.orgProfile}
                          sx={{ width: 100, height: 100 }}
                        />
                      </Stack>
                      <Stack direction="column" display="flex" justifyContent="center" alignItems="start" sx={{ ml: '10px' }}>
                        <Typography variant="subtitle2" color="success" gutterBottom>
                          {item.title}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Card>
                  <Divider sx={{ m: '1em 0em' }} />
                  <Card >
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography sx={{ fontSize: '15px' }}>
                        {item.agentName} Concert Tour
                      </Typography>
                    </CardContent>
                    <Divider />
                    <Grid xs={12} spacing={3} sx={{ p: '10px' }} display="flex" flexDirection="column">
                      {
                        (item.concert).map((item) => {
                          return (
                            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'row', mt: '10px' }}>
                              <Grid item xs={2} sx={{ m: '1em' }}>
                                <Box sx={{ bgcolor: '#08a182', color: '#fff', width: '45px', borderRadius: '3px' }} p={1} display="flex" alignItems="center" justifyContent="center">
                                  <Typography variant="subtitle2">{item.concertDate}</Typography>
                                </Box>

                              </Grid>
                              <Grid item xs={10} display="flex" flexDirection="column" justifyContent="center" alignItems="start" sx={{ ml: '7px' }}>
                                <Typography variant="subtitle2" color="primary" gutterBottom>
                                  {item.concertCity}
                                </Typography>
                                <Stack direction="row" spacing={0.5}>
                                  <AccessTime sx={{ color: '#666', fontSize: '15px' }} />
                                  <Typography variant="caption" gutterBottom >
                                    {item.concertTimings}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" spacing={0.5}>
                                  <FmdGood sx={{ color: '#666', fontSize: '15px' }} />
                                  <Typography variant="caption" gutterBottom>
                                    {item.concertAddress}
                                  </Typography>
                                </Stack>
                                <Divider />
                              </Grid>
                            </Paper>
                          )
                        })
                      }
                    </Grid >
                  </Card>
                </Collapse>
              </Grid>
              {/* <Grid xs={12}>

              </Grid> */}
            </>
          )
        })
      }
    </Grid>
  )
}

export default MobEventDP
import React from 'react'
import { useParams } from 'react-router-dom';
import AgentDataService from '../../../context/AgentDatabase';
import ReactLoading from 'react-loading';
import { Avatar, Box, Breadcrumbs, Button, Card, CardContent, CardMedia, Chip, Divider, Grid, Link, Paper, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { AccessTime, Event, FmdGood, Grain, Home, NavigateNextOutlined } from '@mui/icons-material';
import EventNavbar from './EventNavbar';
import MobEventDP from './MobEventDP';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import defaultImage from '../../../images/NoImage_Available.png'

const CustomPaper = styled(Paper)(({ theme, image }) => ({
    position: 'relative',
    height: '300px',
    textAlign: 'center',
    background: ` linear-gradient(to right, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 70%),url(${image})`,
    overflow: 'hidden',
    marginTop: '4em',
}))

const CustomDiv1 = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left'
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '1.375',
    textDecoration: 'none',
}))

const StyledMobTypography = styled(Typography)(({ theme }) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '1.375',
    textDecoration: 'none',
}))

const CustomCard1 = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: '#f50000bd',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 50,
    },
    alignItems: "center",
    // cursor: "pointer",
}))


const EventDetailPage = () => {
    const [agentData, setAgentData] = React.useState([]);
    const { categorySlug, agentSlug } = useParams();
    const Heading = agentData.filter(item => item.categorySlug === 'events');
    const title = Heading.filter(item => item.agentSlug === agentSlug);
    const isMatch = useMediaQuery('(max-width:480px)');

    const OverviewScrolldiv = React.useRef(null)
    const DescriptionScrolldiv = React.useRef(null)
    const FloorScrolldiv = React.useRef(null)
    const PhotoGalleryScrolldiv = React.useRef(null)
    const VedioGalleryScrolldiv = React.useRef(null)
    const SitemapScrolldiv = React.useRef(null)

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    };

    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent()
        console.log("agents", data.docs)
        setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    React.useEffect(() => {
        getAgents()
    }, [])

    let tickets = title.map((item) => item.fields)
    let tick = tickets.id
    console.log("event", title)

    if (Heading.length === 0) {
        return (
            <div style={{ paddingTop: '25em', height: '1000px', paddingLeft: '48em', }}>
                <ReactLoading type="spokes" color="crimson" height={50} width={50} />
            </div>
        )
    }

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" href="/" sx={{ fontSize: isMatch ? '12px' : '17px', }}>
            Home
        </Link>,
        <Link
            underline="none"
            key="2"
            color="inherit"
            sx={{ fontSize: isMatch ? '12px' : '17px', }}
            href="/events"

        >
            Events
        </Link>,
        <Typography key="3" color="text.primary" sx={{ fontSize: isMatch ? '12px' : '17px', fontWeight: 500, textTransform: 'capitalize' }}>
            {title.map(item => item.businessName)}
        </Typography>, ,
    ]


    const OverviewScroll = () => OverviewScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const DescriptionScroll = () => DescriptionScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const FloorScroll = () => FloorScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const PhotoGalleryScroll = () => PhotoGalleryScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const VedioGalleryScroll = () => VedioGalleryScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const SitemapScroll = () => SitemapScrolldiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })



    return (
        <Box component="main" sx={{ flexGrow: 1, p: isMatch ? 1 : 2 }}>

            {
                title.map((item) => {
                    return (
                        <Paper sx={{ mt: '4em', p: isMatch ? '1em' : '2em 4em', borderRadius: '5px' }} elevation={3}>
                            {
                                isMatch ?
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Stack direction="row" spacing={1}>
                                                <Stack direction="column" spacing={1} sx={{ mb: '5px' }}>
                                                    <Typography
                                                        sx={{
                                                            color: '#02367d',
                                                            fontWeight: 500,
                                                            fontSize: '17px'
                                                        }}>
                                                        {item.businessName}
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <AccessTime sx={{ fontSize: '16px', color: '#666' }} />
                                                        <CustomDiv1>
                                                            <StyledMobTypography variant='body2' gutterBottom> {item.date}</StyledMobTypography>
                                                        </CustomDiv1>
                                                    </Stack >
                                                </Stack>
                                                <Stack direction="column" spacing={1} sx={{ mb: '5px' }}>
                                                    <Button variant='contained' sx={{
                                                        bgcolor: '#f50000e8',
                                                        color: 'white',
                                                        fontSize: '12px',
                                                        width: '15vh',
                                                        height: '4vh',
                                                        textTransform: 'capitalize',
                                                        '&:hover': {
                                                            bgcolor: '#f50000e8',
                                                            color: 'white',
                                                        }
                                                    }}
                                                        href={item.website}
                                                    >Buy Tickets</Button>
                                                    <Stack justifyContent="center" alignItems="center">
                                                        <Typography
                                                            variant="caption"
                                                            color="GrayText"
                                                            isplay="block"
                                                            gutterBottom
                                                            sx={{ fontSize: '9px', }}
                                                        >
                                                            Tickets Range {item.ticketRange}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <Stack direction="column" spacing={0}>
                                                <Stack direction="row" spacing={1} >
                                                    <FmdGood sx={{ fontSize: '16px', color: '#f50057' }} />
                                                    <CustomDiv1>
                                                        <StyledMobTypography variant='caption' gutterBottom> {item.address}</StyledMobTypography>
                                                    </CustomDiv1>
                                                </Stack>

                                            </Stack>
                                            <Breadcrumbs
                                                sx={{ ml: '5px', mb: '5px' }}
                                                separator={<NavigateNextOutlined sx={{ fontSize: '15px' }} />}
                                                aria-label="breadcrumb"
                                            >
                                                {breadcrumbs}
                                            </Breadcrumbs>
                                        </Grid>
                                        <Card sx={{ display: 'flex', flexDirection: 'row', mt: '10px' }}>
                                            <Paper
                                                sx={{
                                                    background: ` linear-gradient(
                                rgb(197 192 192 / 7%), 
                                rgb(0 0 0 / 28%)
                              ),url(${item.mainImage})`,
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'norepeat',
                                                    backgroundPosition: 'top',
                                                    overflow: 'hidden',
                                                    p: 23,
                                                    width: '95%',
                                                    alignItems: 'start',
                                                    justifyContent: 'start',
                                                    borderRadius: 'unset'
                                                }}>
                                            </Paper>
                                        </Card>
                                        <Grid xs={12} sx={{ mt: '10px' }}>
                                            <Stack direction="column" spacing={1} flexWrap="wrap" >
                                                <Stack direction="row" spacing={0} sx={{ mt: '10px' }} flexWrap="wrap">
                                                    {
                                                        (item.selectedGenres).map((item) => {
                                                            return (
                                                                <Stack  flexWrap="wrap" sx={{p: '5px 3px'}}>
                                                                    <Chip label={item} color="info" size="small"  />
                                                                </Stack>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        (item.selectedLanguages).map((item) => {
                                                            return (
                                                                <Stack flexWrap="wrap" sx={{p: '5px 3px'}}>
                                                                    <Chip label={item} color="success" size="small"  />
                                                                </Stack>
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </Stack>
                                        </Grid>

                                    </Grid>
                                    :
                                    <Grid container spacing={1}>
                                        <Grid item xs={10}>
                                            <Stack direction="row" spacing={1} sx={{ mb: '10px' }}>
                                                {
                                                    (item.selectedGenres).map((item) => {
                                                        return (
                                                            <Chip label={item} color="info" size="small" />
                                                        )
                                                    })
                                                }
                                                {
                                                    (item.selectedLanguages).map((item) => {
                                                        return (
                                                            <Chip label={item} color="success" size="small" />
                                                        )
                                                    })
                                                }
                                            </Stack>
                                            <Typography variant='h4'
                                                sx={{
                                                    color: '#02367d',
                                                    fontWeight: 500,
                                                    // ml: isMatch ? "11px" : '22px'
                                                }}>
                                                {item.businessName}
                                            </Typography>
                                            <Breadcrumbs
                                                sx={{ mt: '10px', ml: '2px' }}
                                                separator={<NavigateNextOutlined fontSize="small" />}
                                                aria-label="breadcrumb"
                                            >
                                                {breadcrumbs}
                                            </Breadcrumbs>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', mt: isMatch ? "10px " : '10px' }}>
                                                <AccessTime sx={{ color: '#666' }} />
                                                <CustomDiv1>
                                                    <StyledTypography variant='body2' gutterBottom> {item.date}</StyledTypography>
                                                </CustomDiv1>
                                                <FmdGood sx={{ color: '#f50057' }} />
                                                <CustomDiv1>
                                                    {/* <StyledTypography variant='body2' gutterBottom>Address :</StyledTypography> */}
                                                    <StyledTypography variant='body2' gutterBottom> {item.address}</StyledTypography>
                                                </CustomDiv1>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2} >
                                            <Stack direction="column" spacing={1} sx={{ mb: '10px' }}>
                                                <Button variant='contained' sx={{
                                                    bgcolor: '#f50000e8',
                                                    color: 'white',
                                                    fontSize: '1em',
                                                    height: '7vh',
                                                    textTransform: 'capitalize',
                                                    '&:hover': {
                                                        bgcolor: '#f50000e8',
                                                        color: 'white',
                                                    }

                                                }}
                                                    href={item.website}
                                                >Buy Tickets</Button>
                                                <Stack justifyContent="center" alignItems="center">
                                                    <Typography
                                                        variant="caption"
                                                        color="GrayText"
                                                        isplay="block"
                                                        gutterBottom
                                                        sx={{}}
                                                    >
                                                        Tickets Range {item.ticketRange}
                                                    </Typography>
                                                </Stack>

                                            </Stack>

                                        </Grid>
                                    </Grid>
                            }

                            {isMatch ? null : <Card sx={{ display: 'flex', flexDirection: 'row', mt: '1em' }}>
                                <Paper
                                    sx={{
                                        background: ` linear-gradient(
                                rgb(197 192 192 / 7%), 
                                rgb(0 0 0 / 28%)
                              ),url(${item.mainImage})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'norepeat',
                                        backgroundPosition: 'top',
                                        overflow: 'hidden',
                                        p: 23,
                                        width: '95%',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        borderRadius: 'unset'
                                    }}>
                                </Paper>
                                <Paper
                                    sx={{
                                        background: ` linear-gradient(
                                rgb(197 192 192 / 7%), 
                                rgb(0 0 0 / 28%)
                              ),url(${item.mainImage})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'norepeat',
                                        backgroundPosition: 'center',
                                        overflow: 'hidden',
                                        width: '65%',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        borderRadius: 'unset',
                                    }}></Paper>
                            </Card>}
                        </Paper>
                    )
                })
            }
            <Paper sx={{ mt: isMatch ? '1em' : '4em', p: isMatch ? null : '2em 4em', borderRadius: '5px' }} elevation={3}>
                {
                    isMatch ?
                        <>
                            <MobEventDP Event={title} Address={title.map((item) => item.address)} Options={options} />
                        </>
                        :
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <EventNavbar
                                    OverviewScrolldiv={OverviewScroll}
                                    DescriptionScrolldiv={DescriptionScroll}
                                    FloorScrolldiv={FloorScroll}
                                    PhotoGalleryScrolldiv={PhotoGalleryScroll}
                                    VedioGalleryScrolldiv={VedioGalleryScroll}
                                    SitemapScrolldiv={SitemapScroll}
                                />
                                {title.map((item) => {
                                    return (
                                        <>
                                            <Grid container spacing={2} display="flex" justifyContent="start" alignItems="center" ref={OverviewScrolldiv}>
                                                <Grid item xs={8}>
                                                    <Typography variant={isMatch ? 'h5' : 'h5'}
                                                        sx={{
                                                            color: '#000',
                                                            fontWeight: 500,
                                                            // ml: isMatch ? "11px" : '22px'
                                                        }}>
                                                        Ticket Information
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item xs={4}>
                                            <CustomCard1>
                                                <Typography
                                                    variant={isMatch ? 'h5' : 'h6'}
                                                    sx={{
                                                        color: '#fff',
                                                        fontWeight: 500,
                                                        // ml: isMatch ? "11px" : '22px'
                                                    }}>
                                                    I Want To Select My Seats
                                                </Typography>
                                            </CustomCard1>
                                        </Grid> */}
                                            </Grid>
                                            <Grid container spacing={2} display="flex" justifyContent="start" alignItems="center" sx={{ mt: '1em' }}>
                                                {(item.fields).map((item, index) => {
                                                    return (
                                                        <Grid xs={12} spacing={2} display="flex" key={index}
                                                            sx={{ bgcolor: '#f2f2f2', m: '0.7em', borderRadius: '10px', p: '1em' }}>
                                                            <Grid item xs={4}>
                                                                <Stack spacing={0} direction="column">
                                                                    <Typography
                                                                        variant={isMatch ? 'h5' : '15px'}
                                                                        sx={{
                                                                            color: '#000',
                                                                            fontWeight: 500,
                                                                            // ml: isMatch ? "11px" : '22px'
                                                                        }}>
                                                                        {item.Tickets} Level
                                                                    </Typography>
                                                                    <Typography
                                                                        variant={isMatch ? 'h5' : 'caption'}
                                                                        sx={{
                                                                            color: '#f50000bd',
                                                                            fontWeight: 500,
                                                                            // ml: isMatch ? "11px" : '22px'
                                                                        }}>
                                                                        Age Restriction 16+
                                                                    </Typography>
                                                                </Stack>

                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Stack spacing={0} direction="column">
                                                                    <Typography
                                                                        variant={isMatch ? 'h5' : '15px'}
                                                                        sx={{
                                                                            color: '#08a182',
                                                                            fontWeight: 500,
                                                                            // ml: isMatch ? "11px" : '22px'
                                                                        }}>
                                                                        {item.Tickets}.00
                                                                    </Typography>
                                                                    {(item.availability === "Selling Fast") ?
                                                                        <Typography
                                                                            variant={isMatch ? 'h5' : 'caption'}
                                                                            sx={{
                                                                                color: '#5eaa1e',
                                                                                fontWeight: 500,
                                                                                // ml: isMatch ? "11px" : '22px'
                                                                            }}>
                                                                            {item.availability}
                                                                        </Typography>
                                                                        :
                                                                        <Typography
                                                                            variant={isMatch ? 'h5' : 'caption'}
                                                                            color="primary"
                                                                            sx={{

                                                                                fontWeight: 500,
                                                                            }}>
                                                                            {item.availability}
                                                                        </Typography>}
                                                                </Stack>

                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Stack spacing={0} direction="column">
                                                                    <Typography
                                                                        variant={isMatch ? 'h5' : 'caption'}
                                                                        sx={{
                                                                            color: '#000',
                                                                            fontWeight: 500,
                                                                            // ml: isMatch ? "11px" : '22px'
                                                                        }}>
                                                                        Ticket close on
                                                                    </Typography>
                                                                    <Typography
                                                                        variant={isMatch ? 'h5' : '15px'}
                                                                        sx={{
                                                                            color: '#000',
                                                                            fontWeight: 500,
                                                                            // ml: isMatch ? "11px" : '22px'
                                                                        }}>
                                                                        {item.ticketDate}
                                                                    </Typography>

                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            <Card sx={{ mt: '1em' }} ref={DescriptionScrolldiv}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h5">
                                                        Event Details
                                                    </Typography>
                                                </CardContent>
                                                <Divider />
                                                <SimpleReactLightbox>
                                                    <SRLWrapper options={options}>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: '100%', p: '2em 16em' }}
                                                            image={item.gallery}
                                                            alt="Imagee"
                                                        />
                                                    </SRLWrapper>
                                                </SimpleReactLightbox>

                                            </Card>
                                            <Card sx={{ mt: '1em' }} ref={FloorScrolldiv}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h5">
                                                        Venue
                                                    </Typography>

                                                </CardContent>
                                                <Divider />
                                                <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center" sx={{ mt: '1em' }}>
                                                    <FmdGood sx={{ color: '#f50000', fontSize: '15px' }} />
                                                    <Typography sx={{ fontSize: '15px', fontWeight: 500 }} gutterBottom>
                                                        {item.address}
                                                    </Typography>
                                                </Stack>
                                                <SimpleReactLightbox>
                                                    <SRLWrapper options={options}>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: '100%', p: '2em 16em' }}
                                                            image={(item.bgImage) === true ? item.bgImage : defaultImage}
                                                            alt="Imagee"
                                                        />
                                                    </SRLWrapper>
                                                </SimpleReactLightbox>

                                            </Card>
                                            <Card sx={{ mt: '1em' }} ref={PhotoGalleryScrolldiv}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h5">
                                                        Event Performers
                                                    </Typography>
                                                </CardContent>
                                                <Divider />
                                                <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                                                    <Typography component="div" variant="h6">
                                                        Artists
                                                    </Typography>
                                                </Stack>

                                                <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                                                    <Avatar
                                                        alt="Artist Image"
                                                        src={item.agentProfile}
                                                        sx={{ width: 250, height: 250 }}
                                                    />
                                                </Stack>
                                                <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                                                    <Typography variant="h6" color="primary">
                                                        {item.agentName}
                                                    </Typography>
                                                </Stack>
                                            </Card>

                                        </>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={3} >
                                {title.map((item) => {
                                    return (
                                        <Stack spacing={5.5} direction="column">
                                            <Card >
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h6">
                                                        Artist details
                                                    </Typography>
                                                </CardContent>
                                                <Divider />
                                                <Stack direction="row" spacing={2}>
                                                    <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                                                        <Avatar
                                                            alt="Artist Image"
                                                            src={item.agentProfile}
                                                            sx={{ width: 100, height: 100 }}
                                                        />
                                                    </Stack>
                                                    <Stack direction="column" display="flex" justifyContent="center" alignItems="start" sx={{ ml: '10px' }}>
                                                        <Typography variant="subtitle2" color="success" gutterBottom>
                                                            {item.agentName}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '12px' }} gutterBottom>
                                                            {item.shortdescription}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>

                                            </Card>
                                            <Card >
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h6">
                                                        Organizer Details
                                                    </Typography>
                                                </CardContent>
                                                <Divider />
                                                <Stack direction="row" spacing={2}>
                                                    <Stack display="flex" justifyContent="center" alignItems="center" sx={{ m: '1em' }}>
                                                        <Avatar
                                                            alt="Artist Image"
                                                            src={item.orgProfile}
                                                            sx={{ width: 100, height: 100 }}
                                                        />
                                                    </Stack>
                                                    <Stack direction="column" display="flex" justifyContent="center" alignItems="start" sx={{ ml: '10px' }}>
                                                        <Typography variant="subtitle2" color="success" gutterBottom>
                                                            {item.title}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>

                                            </Card>
                                            <Card >
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h6">
                                                        {item.agentName} Concert Tour
                                                    </Typography>
                                                </CardContent>
                                                <Divider />
                                                <Grid xs={12} spacing={3} sx={{ p: '10px' }} display="flex" flexDirection="column">
                                                    {
                                                        (item.concert).map((item) => {
                                                            return (
                                                                <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'row', mt: '10px' }}>
                                                                    <Grid item xs={2} sx={{ m: '1em' }}>
                                                                        <Box sx={{ bgcolor: '#08a182', color: '#fff', width: '45px', borderRadius: '3px' }} p={1} display="flex" alignItems="center" justifyContent="center">
                                                                            <Typography variant="subtitle2">{item.concertDate}</Typography>
                                                                        </Box>

                                                                    </Grid>
                                                                    <Grid item xs={10} display="flex" flexDirection="column" justifyContent="center" alignItems="start" sx={{ ml: '7px' }}>
                                                                        <Typography variant="subtitle2" color="primary" gutterBottom>
                                                                            {item.concertCity}
                                                                        </Typography>
                                                                        <Stack direction="row" spacing={0.5}>
                                                                            <AccessTime sx={{ color: '#666', fontSize: '15px' }} />
                                                                            <Typography variant="caption" gutterBottom >
                                                                                {item.concertTimings}
                                                                            </Typography>
                                                                        </Stack>
                                                                        <Stack direction="row" spacing={0.5}>
                                                                            <FmdGood sx={{ color: '#666', fontSize: '15px' }} />
                                                                            <Typography variant="caption" gutterBottom>
                                                                                {item.concertAddress}
                                                                            </Typography>
                                                                        </Stack>
                                                                        <Divider />
                                                                    </Grid>
                                                                </Paper>
                                                            )
                                                        })
                                                    }
                                                </Grid >
                                            </Card>
                                        </Stack>
                                    )
                                })}

                            </Grid>
                        </Grid>
                }
            </Paper>
        </Box >
    )
}

export default EventDetailPage
import React from 'react'
import { Box, MobileStepper, useMediaQuery, styled, useTheme, Grid, Paper, Card, CardMedia, CardContent, Typography, Divider, CardActions, Button, Fab, Container, CardHeader } from '@mui/material';
import image1 from '../../images/BgImages/food5.jpg'
import image2 from '../../images/BgImages/Grocery.jpg'
import image3 from '../../images/BgImages/Dec4.jpg'
import image4 from '../../images/BgImages/Img18.jpg'
import image5 from '../../images/BgImages/food1.jpg'
import image6 from '../../images/BgImages/food4.jpg'
import image7 from '../../images/BgImages/Dec1.jpg'
import image8 from '../../images/BgImages/Dec5.jpg'
import image9 from '../../images/BgImages/Dec3.jpg'
import image10 from '../../images/BgImages/Dec6.jpg'
import image11 from '../../images/BgImages/Dec8.jpg'
import image12 from '../../images/BgImages/Jewel1.jpg'
import image13 from '../../images/BgImages/Jewel2.jpg'
import image14 from '../../images/BgImages/Jewel3.jpg'
import image15 from '../../images/BgImages/Jewel4.jpg'
import image16 from '../../images/BgImages/gro7.jpg'
import image17 from '../../images/BgImages/gro6.jpg'
import image18 from '../../images/BgImages/gro5.jpg'
import image19 from '../../images/BgImages/gro8.jpg'
import image20 from '../../images/BgImages/gro3.png'
import image21 from '../../images/BgImages/pic3.jpg'
import image22 from '../../images/BgImages/pic1.jpg'
import image23 from '../../images/BgImages/pic2.jpg'
import image24 from '../../images/BgImages/pic5.jpg'
import image25 from '../../images/BgImages/pic11.jpg'
import image26 from '../../images/BgImages/pic7.jpg'
import image27 from '../../images/BgImages/pic10.jpg'
import image28 from '../../images/BgImages/pic4.jpg'
import image29 from '../../images/BgImages/pic8.jpg'
import image30 from '../../images/BgImages/pic6.jpg'
import image31 from '../../images/BgImages/pic12.jpg'
import ReactPlayer from 'react-player';
import vedio from '../../Vedios/EventVedio.mp4'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from '../../client'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { matchPath, useNavigate } from 'react-router-dom';
import { FastfoodRounded } from '@mui/icons-material';
import foodImg from '../../images/home/food.jpg'
import GroImg from '../../images/home/gro.jpg'
import DecImg from '../../images/home/Dec.jpg'
import RealImg from '../../images/home/Real.jpg'

const CustomDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  top: '143%'
}))

const CustomDiv2 = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '16px',
  top: '143.2%'
}))

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const builder = imageUrlBuilder(sanityClient);


function urlFor(source) {
  return builder.image(source);
}

const Home = () => {
  const [photo, setPhoto] = React.useState(1);
  const [posts, setPosts] = React.useState(null);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch2 = useMediaQuery('(max-width:410px)');
  const isMatch1 = useMediaQuery('(min-width:1440px)');
  const history = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleClick = () => {
    window.scroll({ behavior: 'smooth', marginTop: 0 })
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      change();
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [photo]);

  const change = () => {
    if (photo === 5) {
      setPhoto(1);
      return;
    }

    setPhoto((prev) => prev + 1);
  };

  React.useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
            asset->{
                _id,
                url
            },               
        },
        description,
        date,
        releaseDate,
        city,
        authorname,
        authorimage,
    }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);
  console.log('post', posts);


  const returnPhotoURL = () => {
    switch (photo) {
      case 1:
        return image1;
      case 2:
        return image2;
      case 3:
        return image3;
      case 4:
        return image4;
      case 5:
        return image5;
      default:
        return image5;
    }
  };

  return (
    <div>
      {isMatch ?
        <>
          <SimpleReactLightbox>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {
                images.map((step, index) => (
                  <div key={step.image}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Container maxWidth="sm">
                        <Paper
                          sx={{
                            background: ` linear-gradient(
                              rgb(197 192 192 / 7%), 
                              rgb(0 0 0 / 28%)
                            ),url(${step.image})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'norepeat',
                            backgroundPosition: 'center center',
                            overflow: 'hidden',
                            alignItems: 'start',
                            justifyContent: 'start',
                            marginTop: '5em',
                            // marginLeft: '-1em',
                            padding: '14px',
                            height: 250
                          }}
                        />
                      </Container>
                    ) : null}
                  </div>
                ))
              }
            </AutoPlaySwipeableViews>
          </SimpleReactLightbox>
          {/* <ReactPlayer url={vedio} playing={true} loop={true} muted={true} width='100%' height={'58vh'} /> */}
          <Grid container spacing={0} sx={{ mt: '-5px', p: '0em 2em', backgroundColor: '#f9fafc' }}>
            <Grid xs={12} sx={{ mt: '2em' }} >
              <Card
                sx={{
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }}
                onClick={() => history('/indian-food')}
                
              >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={foodImg} alt="foodImg" width="110px" height="110px"  />
                </Box>
                <CardContent sx={{ p: 0.5 }}>
                  <Typography component="div" sx={{ pt: '2px', fontSize: isMatch2 ? '1.1rem' :'1.3rem' }}>
                    Indian Food
                  </Typography>
                  <Typography component="div" variant="body2" sx={{ pt: '2px', fontSize: isMatch2 ? '12px' :'15px' }}>
                    Indian food is one of the most popular food in the united states. Due to its Flavour, taste, and variety Indian food become most popular in the USA.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} sx={{ mt: '2em' }} >
              <Card
                sx={{
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }} onClick={() => history('/indian-grocery-stores')}  >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={GroImg} alt="foodImg" width="110px" height="110px"   />
                </Box>
                <CardContent sx={{ p: 0.5 }}>
                  <Typography component="div" sx={{ pt: '2px', fontSize: isMatch2 ? '1.1rem' :'1.3rem' }}>
                    Indian Grocery Stores
                  </Typography>
                  <Typography component="div" variant="body2" sx={{ pt: '2px', fontSize: isMatch2 ? '12px' :'15px' }}>
                    Indian immigrants in the USA are often on the lookout for Indian grocery stores. Indian spices and vegetables are missed by the Indian palate.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} sx={{ mt: '2em' }} >
              <Card
                sx={{
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }} onClick={() => history('/decorations-and-jeweleries')}  >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={DecImg} alt="foodImg" width="110px" height="110px"   />
                </Box>
                <CardContent sx={{ p: 0.8 }}>
                  <Typography component="div" sx={{ pt: '2px', fontSize: isMatch2 ? '1.1rem' :'1.3rem'}}>
                    Decoration & Jewellary
                  </Typography>
                  <Typography component="div" variant="body2" sx={{ pt: '2px', fontSize: isMatch2 ? '12px' :'15px' }}>
                    Browse an extensive collection of traditional Indian Home Decor, House Decorations.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} sx={{ mt: '2em', mb: '2em' }} >
              <Card
                sx={{
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }} onClick={() => history('/classified')}  >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={RealImg} alt="foodImg" width="110px" height="110px"  />
                </Box>
                <CardContent sx={{ p: 0.5 }}>
                  <Typography component="div" sx={{ pt: '2px', fontSize: isMatch2 ? '1.1rem' :'1.3rem' }}>
                    Classified
                  </Typography>
                  <Typography component="div" variant="body2" sx={{ pt: '2px', fontSize: isMatch2 ? '12px' :'15px' }}>
                  Browse classified for various Indian businesses like Real Estate, Photographers in the Seattle area including Redmond, Bothell, Sammamish.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>

        :
        <>
          <SimpleReactLightbox>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {
                images.map((step, index) => (
                  <div key={step.image}>
                    {Math.abs(activeStep - index) <= 2 ? (
                        <Paper elevation={2}
                          sx={{
                            background: ` linear-gradient(
                          rgb(197 192 192 / 7%), 
                          rgb(0 0 0 / 28%)
                        ),url(${step.image})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'norepeat',
                            backgroundPosition: 'center center',
                            overflow: 'hidden',
                            padding: 30,
                            width: '98%',
                            height: 150,
                            alignItems: 'start',
                            justifyContent: 'start',
                            margin: '6em 1em 1em 1em'
                          }} />

                    ) : null}
                  </div>
                ))
              }
            </AutoPlaySwipeableViews>
          </SimpleReactLightbox>
          <Grid container spacing={0} sx={{ p: '0em 12em', }}>
            <Grid xs={6} sx={{ mt: '1em' }} >
              <Card
                sx={{
                  width: '91%',
                  height: '180px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }} onClick={() => history('/indian-food')} >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={foodImg} alt="foodImg" width="140px" height="140px"  />
                </Box>
                <CardContent>
                  <Typography component="div" sx={{ pt: '5px', fontSize: '1.6rem' }}>
                    Indian Food
                  </Typography>
                  <Typography component="div" variant="h7" sx={{ pt: '5px', }}>
                    Indian food is one of the most popular food in the united states. Due to its Flavour, taste, and variety Indian food become most popular in the USA.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={6} sx={{ mt: '1em' }} >
              <Card
                sx={{
                  width: '91%',
                  height: '180px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }} onClick={() => history('/indian-grocery-stores')}  >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={GroImg} alt="foodImg" width="140px" height="140px" />
                </Box>
                <CardContent>
                  <Typography component="div" sx={{ pt: '5px', fontSize: '1.6rem' }}>
                    Indian Grocery Stores
                  </Typography>
                  <Typography component="div" variant="h7" sx={{ pt: '5px' }}>
                    Indian immigrants in the USA are often on the lookout for Indian grocery stores. Indian spices and vegetables are missed by the Indian palate.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={6} sx={{ m: '2em 0em' }} >
              <Card
                sx={{
                  width: '91%',
                  height: '180px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }} onClick={() => history('/decorations-and-jeweleries')} >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={DecImg} alt="foodImg" width="140px" height="140px" />
                </Box>
                <CardContent>
                  <Typography component="div" sx={{ pt: '5px', fontSize: '1.6rem' }}>
                    Decoration & Jewellary
                  </Typography>
                  <Typography component="div" variant="h7" sx={{ pt: '5px' }}>
                    Browse an extensive collection of traditional Indian Home Decor, House Decorations.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={6} sx={{ m: '2em 0em', }} >
              <Card
                sx={{
                  width: '91%',
                  height: '180px',
                  display: 'flex',
                  '&:hover': {
                    background: '#f2f6fc',
                    cursor: 'pointer'
                  }
                }} onClick={() => history('/classified')}  >
                <Box component="span" sx={{ p: 2 }}>
                  <img src={RealImg} alt="foodImg" width="140px" height="140px"   />
                </Box>
                <CardContent>
                  <Typography component="div" sx={{ pt: '5px', fontSize: '1.6rem' }}>
                    Classified
                  </Typography>
                  <Typography component="div" variant="h7" sx={{ pt: '5px' }}>
                  Browse classified for various Indian businesses like Real Estate, Photographers in the Seattle area including Redmond, Bothell, Sammamish.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      }
    </div>

  )
}

export default Home

const images = [
  // { image: `${image1}` },
  // { image: `${image5}` },
  // { image: `${image6}` },
  { image: `${image22}` },
  { image: `${image2}` },
  // { image: `${image16}` },
  // { image: `${image17}` },
  // { image: `${image18}` },
  // { image: `${image19}` },
  // { image: `${image20}` },
  // { image: `${image8}` },
  { image: `${image3}` },
  // { image: `${image9}` },
  // { image: `${image7}` },
  { image: `${image11}` },
  { image: `${image10}` },
  { image: `${image12}` },
  { image: `${image13}` },
  { image: `${image14}` },
  { image: `${image15}` },
  // { image: `${image4}` },
  { image: `${image21}` },
  
  { image: `${image23}` },
  { image: `${image31}` },
  { image: `${image24}` },
  { image: `${image26}` },
  { image: `${image27}` },
  { image: `${image28}` },
  { image: `${image29}` },
  { image: `${image25}` },
  { image: `${image30}` },
 
]

//how to scroll to top onClick ?




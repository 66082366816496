import { LockOutlined } from '@mui/icons-material';
import { Avatar, Button, CircularProgress, Container, CssBaseline, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useUserAuth } from "../../context/UserAuthContext";
import firebase from '../../firebase/firebase';
import {  useNavigate } from "react-router-dom";

// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {'Copyright © '}
//             <Link color="inherit" href="https://www.homewastate.com/">
//                 homeWAState.com
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }


const Paper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
}));

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4)
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
}));


export default function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = React.useState(false);
    const { logIn, googleSignIn } = useUserAuth();
    const navigate = useNavigate();


    const [error, setError] = useState("")

    async function handleSubmit(event){
      event.preventDefault();
      //log the user
      if(email === 'dguptahomes.web@gmail.com')
     await logIn(email, password).then((user) => {})
     .then(setLoading(true))
      .catch((error) =>{
        console.log(error);
     
      });
      setLoading(false)
    }
  


    return (
        <Container component="main" maxWidth="xs" style={{marginTop: '10em', marginBottom: '10em'}}>
            <CssBaseline />
            <Paper >
                <StyledAvatar >
                    <LockOutlined />
                </StyledAvatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <StyledForm noValidate onClick={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <StyledButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                    >
                        Sign In
                    </StyledButton>
                    {loading && <CircularProgress align="center" />}
                    {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
                </StyledForm>
            </Paper>
            {/* <Box mt={8}>
        <Copyright />
      </Box> */}
        </Container>
    );
}
import React from 'react'
import { Box, Breadcrumbs, Card, CardContent, CardHeader, Chip, Container, Divider, Grid, Link, Paper, Stack, styled, Table, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom'
import Drawes from '../Sidebar/Drawer'
import BusinessDataService from '../../context/ClassifiedsDatabase';
import Slider from "react-slick";
import noImg from '../../images/NoImage_Available.png'
import { AddLink, Call, Email, LineAxisOutlined, LinkOff, LinkOutlined, LocationCityOutlined, LocationDisabledOutlined, LocationOffOutlined, NavigateNextOutlined, Person, WebSharp } from '@mui/icons-material'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import ImageContainer from './ImageContainer';
import ImageScroll from './ImageScroll';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const StyledTypography = styled(Typography)(({ theme }) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '1.375',

    textDecoration: 'none',
}))
const StyledTypography2 = styled(Typography)(({ theme }) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '1.375',

    textDecoration: 'none',
}))

const CustomDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flexStart',
    lineHeight: '1.85714',
    fontSize: '0.875rem',

    fontWeight: 400,
    marginLeft: '10px'
}))

const CustomBox = styled('Box')(({ theme }) => ({
    // width: '24px',
    // height: '24px',
}))

const CustomDivv = styled('div')(({ theme }) => ({
    margin: '0px 0px 0px 3px',
    display: 'flex',
    flexDirection: 'row',
}))

const CustomDiv1 = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left'
}))

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    // width: '100%',
    padding: theme.spacing(1),
    textAlign: 'end',
    color: theme.palette.text.secondary,
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
    marginTop: '1em',
    fontSize: '1.5em',
    fontWeight: '500',
}));

const MobCustomTypography = styled(Typography)(({ theme }) => ({
    marginTop: '1em',
    fontSize: '22px',
    fontWeight: '500',
    fontFamily: 'ITC Clearface,serif'
}));

const CustomTypography1 = styled(Typography)(({ theme }) => ({
    margin: '5px',
    fontSize: '19px',
    fontWeight: '500',

}));

const MobCustomTypography1 = styled(Typography)(({ theme }) => ({
    margin: '5px',
    fontSize: '16px',
    fontWeight: '500',

}));

const CustomTypography2 = styled('li')(({ theme }) => ({
    fontSize: '19px',
    fontWeight: '500',

}));

const MobCustomTypography2 = styled('li')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '500',

}));

const CustomChip = styled(Paper)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'ITC Clearface,serif'
}))
const CustomChip1 = styled(Paper)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: '500',
    fontFamily: 'ITC Clearface,serif'
}))

const CustomCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)',
    borderRadius: 'none',
    padding: 10,
    maxWidth: 800,
    [theme.breakpoints.up("md")]: {
        height: 65,
    },
    alignItems: "center",
    cursor: "pointer",
    marginTop: '3em'
}))

const ImageCarsouel = (props) => {
    const { images, Matches } = props;
    const carouselSettings = {
        dots: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,

    };

    return (
        // <Container>

        //         {images.map((img) => (
        //             <Box key={img} sx={{ px: 1 }}>
        //                 <img alt={img} src={img} ratio="1/1" sx={{ borderRadius: 2 }} />
        //             </Box>
        //         ))}

        // </Container>
        <Grid container spacing={2} sx={{ mb: '1em' }}>
            {
                images.map((img) => {
                    return (
                        <Grid item sx={5} align="center">
                            {
                                Matches ?
                                    <div key={img}>
                                        <img src={img} alt='' width='100%' height='100%' />
                                    </div>

                                    :
                                    <Box key={img} sx={{
                                        width: 320,
                                        height: 300,
                                        ml: '10px'
                                    }}>
                                        <img src={img} alt='' width='100%' height='100%' />
                                    </Box>}
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

function DetailedClassified() {
    const theme = useTheme();
    const { businessName } = useParams();
    const isMatch = useMediaQuery('(max-width:480px)');
    const [businessData, setBusinessData] = React.useState([]);
    // const [loading, setLoading] = React.useState(false);
    const Title = businessData.filter(item => item.businessSlug === businessName)
    console.log("images", businessData);

    React.useEffect(() => {
        getBusiness();
    }, []);

    const getBusiness = async () => {
        const data = await BusinessDataService.getAllBusiness();
        console.log("business", data.docs);
        setBusinessData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" href="/" sx={{ fontSize: isMatch ? '15px' : '17px', }}>
            Home
        </Link>,
        <Link
            underline="none"
            key="2"
            color="inherit"
            href="/classified"
            sx={{ fontSize: isMatch ? '15px' : '17px', }}
        >
            Classified
        </Link>,
        <Typography key="3" color="text.primary" sx={{ fontSize: isMatch ? '15px' : '17px', fontWeight: 500, textTransform: 'capitalize' }}>
            {Title.map(item => item.businessType.title)}
        </Typography>,
    ];

    return (
        <>
            {
                Title.map((card) => {
                    return (
                        <>
                            <Paper key={card.agentName} sx={{ mt: '4em', p: isMatch ? '1em' : '2em 4em', borderRadius: '5px' }} elevation={0}>
                                <Typography variant={isMatch ? 'h5' : 'h4'}
                                    sx={{
                                        color: '#02367d',
                                        fontWeight: 500,
                                        ml:  isMatch ? "11px" : '22px'
                                    }}>
                                    {card.businessName}
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', m:  isMatch ? "10px 11px" : '10px 22px' }}>
                                    <FmdGoodIcon sx={{ color: '#f50057' }} />
                                    <CustomDiv1>
                                        {/* <StyledTypography variant='body2' gutterBottom>Address :</StyledTypography> */}
                                        <StyledTypography variant='body2' gutterBottom> {card.address}</StyledTypography>
                                    </CustomDiv1>
                                </Box>
                                <Breadcrumbs
                                    sx={{ m:  isMatch ? "10px 15px" : '10px 25px' }}
                                    separator={<NavigateNextOutlined fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                                <Container maxWidth="xl">
                                    <Grid container spacing={2} sx={{ mt: '2em', mb: '2em' }}>
                                        <Grid item xs={isMatch ? 12 : 6}>
                                            <ImageScroll Images={card.images[0] ? card.images : noImg} Name={card.businessName} matches={isMatch} />
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <ImageContainer Images={card.images[0] ? card.images : noImg} Name={card.businessType.title} matches={isMatch} />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Divider sx={{ borderStyle: 'dashed', p: '1em' }} />
                                <Container maxWidth="xl" sx={{ p: isMatch ? null : '2em 0em', mt: isMatch ? '3em' : '2em', ml: isMatch ? null : '0em' }}>
                                    <TableContainer sx={{ width: isMatch ? 360 : 800 }} aria-label="simple table">
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="start" colSpan={2} sx={{ background: 'linear-gradient(to right, #00416a, #799f0c, #ffe000)' }}>
                                                        <Typography
                                                            color="success"
                                                            sx={{
                                                                fontSize: isMatch ? "19px" : '1.7em',
                                                                color: '#fff',
                                                                fontWeight: 500
                                                            }}>
                                                            Details
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableRow>

                                                <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText" }}>Phone</TableCell>
                                                <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }} >{card.phoneNumber ? card.phoneNumber : "-"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText", }}>Address</TableCell>
                                                <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }}>{card.address ? card.address : "-"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText", }}>City</TableCell>
                                                <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }}>{card.city.title ? card.city.title : "-"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText", }}>Email</TableCell>
                                                <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }}>
                                                    {(card.email) ?
                                                        <Link underline="none" href={`mailto:${card.email}`}>
                                                            <Typography sx={{ fontSize: isMatch ? "15px" : "1.1rem" }} gutterBottom> {card.email}</Typography>
                                                        </Link>
                                                        :
                                                        <Typography sx={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText" }} gutterBottom> -</Typography>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText", }}>Website</TableCell>
                                                <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }}>
                                                    {(card.website) ?
                                                        <Link underline="none" href={card.website} target="_blank">
                                                            <Typography sx={{ fontSize: isMatch ? "15px" : "1.1rem" }} gutterBottom> {card.website}</Typography>
                                                        </Link>
                                                        :
                                                        <Typography sx={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText" }} gutterBottom> -</Typography>
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        </Table>


                                    </TableContainer>
                                </Container>
                                <Divider sx={{ borderStyle: 'dashed', p: '1em' }} />
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{ ml: isMatch ? "11px" : '22px' }}>
                                        <CustomCard>
                                            <Typography color="success"
                                                sx={{
                                                    fontSize: '1.5em',
                                                    fontWeight: 500,
                                                    color: '#fff'
                                                }}
                                            >
                                                Description
                                            </Typography>
                                        </CustomCard>
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 10} sx={{ ml: isMatch ? "11px" : '22px' }} align="start">
                                        <Typography
                                            sx={{
                                                mt: '1em',
                                                fontWeight: 400,
                                                fontSize: isMatch ? "16px" : '17px',
                                                lineHeight: '1.375',
                                                textDecoration: 'none',
                                                // ml: '-25px'
                                            }}>
                                            {card.description}
                                        </Typography>
                                        <Typography color="success" sx={{ pt: '1em', fontSize: '1.2em' }}>{card.heading}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems1}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems2}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems3}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems4}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                        </>
                    )
                })
            }

        </>
    )
}

export default DetailedClassified




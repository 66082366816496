import React from 'react'
import { Box, Chip, Container, Divider, Card, CardMedia, Grid, Paper, Link, Stack, styled, Typography, useMediaQuery, useTheme, Button, Tabs, tabsClasses } from '@mui/material'
import Drawers from '../Sidebar/Drawer'
import BusinessDataService from '../../context/ClassifiedsDatabase';
import AgentDataService from '../../context/AgentDatabase';
import ClassifiedsList from './ClassifiedsList';
import AgentsCards from '../Agents/AgentsCards';
import coverImg from '../../images/BgImages/Img18.jpg'
import coverImg1 from '../../images/BgImages/Bg4.jpg'
import Classified from './Classified';
import { useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';
import noImg from '../../images/NoImage_Available.png'
import IndianMovies from '../Pages/IndianMovies';
import { LocationOnRounded, PhotoCameraBackRounded, SelfImprovementRounded, TempleHinduRounded } from '@mui/icons-material';
import ReactLoading from 'react-loading';



const CustomPaper1 = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em',
    // marginLeft: '-1em',
    padding: '12px'
}))

const StyledDiv = styled('div')(({ theme }) => ({
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    minHeight: '65vh',
    width: '100%',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em'
}))

// const CustomPaper = styled(Paper)(({ theme }) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     background: '#606c88',
//     background: ' -webkit-linear-gradient(to right, #3f4c6b, #606c88)',
//     background: 'linear-gradient(to right, #3f4c6b, #606c88)',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'norepeat',
//     backgroundPosition: 'inherit inherit',
//     overflow: 'hidden',
//     minHeight: '35vh',
//     width: '100%',
//     alignItems: 'start',
//     justifyContent: 'start',
//     marginTop: '8em'
// }))
const CustomPaper = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    padding: 40,
    width: '100%',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '6em',
}))

const CustomCard1 = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: ' linear-gradient(to right, #348f50, #56b4d3)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',

}))
const CustomCard2 = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',

}))


const Item = styled(Typography)(({ theme }) => ({
    display: 'flex',
    borderRadius: 'none',
    padding: '20px 20px',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 30,
    },
    textAlign: "center",
    // backgroundColor: '#134e5e',
    alignItems: "center",
    fontSize: 19,
    color: 'white',
    marginTop: '1em'
    // marginBottom: '1em',
}));

const Item2 = styled(Typography)(({ theme }) => ({
    display: 'flex',
    borderRadius: 'none',
    paddingLeft: '20px',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 30,
    },
    textAlign: "center",
    // backgroundColor: '#ffc400',
    textAlign: 'center',
    fontSize: 19,
    color: 'white',
    margin: '5px',

}));

const CustomMobCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 300,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',
}))


function ClassifiedTemples(props) {
    const { BusinessData, SearchTerm, SearchTerm1, matches } = props;
    const history = useNavigate();
    const businesspages = BusinessData.map(item => item.businessType.title);
    const businessPages = [...new Set(businesspages)];
    const businesspages1 = BusinessData.map(item => item.city.title);
    const businessPages1 = [...new Set(businesspages1)];
    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }

    const CustomCard3 = styled(Card)(({ theme }) => ({
        display: 'flex',
        background: 'linear-gradient(to right, #b92b27, #1565c0)',
        borderRadius: 'none',
        padding: 10,
        paddingLeft: '2em',
        maxWidth: SearchTerm1 ? 700 : 500,
        [theme.breakpoints.up("md")]: {
            height: 70,
        },
        alignItems: "center",
        // cursor: "pointer",
        marginTop: '1em',

    }))
    // console.log("businessPages", businessPages);
    return (
        <Grid container spacing={matches ? 2 : 1}
            sx={{
                mt: matches ? '1em' : '5em',
                ml: matches ? '1px' : null,
                mr: matches ? '1px' : null,
                borderRadius: '5px',
                backgroundColor: 'rgb(255, 255, 255)',
                color: 'rgb(33, 43, 54)',
                transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundImage: 'none',
                overflow: 'hidden',
                boxShadow: 'rgb(145 158 171 / 16%) -16px 16px 56px -8px',
                borderRadius: '16px',
                position: 'relative',
                zIndex: 0,
            }}>
            <Grid xs={matches ? 8 : 12} sx={{ mt: '2em', mb: '2em' }}>
                {(businessPages[0] === 'Photography') ?
                    <>
                        {
                            matches ?
                                <CustomMobCard>
                                    <Stack direction="row" >
                                        <PhotoCameraBackRounded sx={{ color: '#fff', fontSize: '1.5em', mr: '10px' }} />
                                        {
                                            SearchTerm1 ?
                                                <Typography
                                                    variant='h5'
                                                    sx={{ color: '#fff', fontSize: '1.1em', textTransform: 'capitalize', textAlign: 'center' }}
                                                >
                                                    Photography in {SearchTerm1}
                                                </Typography>
                                                :
                                                <Typography
                                                    variant='h5'
                                                    sx={{ color: '#fff', fontSize: '1.1em', textTransform: 'capitalize', textAlign: 'center' }}
                                                >
                                                    Photography
                                                </Typography>
                                        }
                                    </Stack>
                                </CustomMobCard>
                                :
                                <CustomCard3>
                                    <Stack direction="row" >
                                        <PhotoCameraBackRounded sx={{ color: '#fff', fontSize: '2.3em', mt: '1px', mr: '10px' }} />
                                        {
                                            SearchTerm1 ?
                                                <Typography
                                                    variant='h5'
                                                    sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                                >
                                                    Photography in {SearchTerm1}
                                                </Typography>
                                                :
                                                <Typography
                                                    variant='h5'
                                                    sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                                >
                                                    Photography
                                                </Typography>
                                        }
                                    </Stack>
                                </CustomCard3>
                        }
                    </>
                    :
                    null

                }




            </Grid>
            {
                BusinessData.sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1).map((item) => {
                    return (
                        <Grid item xs={matches ? 12 : 6} key={item.title} align={matches ? "start" : "center"} >
                                <Card
                                    elevation={5}
                                    sx={{ width: matches ? 350 : 690, minHeight: 350, mb: '1em' }}
                                >
                                    <Grid container sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row' }}>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'norepeat',
                                                    backgroundPosition: 'center center', width: matches ? 'auto' : 200, height: matches ? 250 : 180, p: '1em', borderRadius: '20px'
                                                }}
                                                image={item.mainImage[0] ? item.mainImage : noImg}
                                                alt="Live from space album cover"
                                            />
                                        <Grid xs={12} sx={{ mt: '10px', ml: matches ? '18px' : '2em' }} align="start">
                                            <Typography
                                                sx={{
                                                    fontSize: matches ? '1.3rem' : '1.7rem',

                                                    color: '#02367d'
                                                }}
                                            >
                                                {item.businessName}
                                            </Typography>
                                            <div>
                                                {/* <Typography
                                                    sx={{
                                                        ml: '2px',
                                                        fontWeight: 400,
                                                        fontSize: matches ? '1rem' : '17px',
                                                        lineHeight: '1.575',

                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Contact : {item.agentName}
                                                </Typography> */}
                                                <Typography
                                                    sx={{
                                                        ml: '2px',
                                                        fontWeight: 400,
                                                        fontSize: matches ? '1rem' : '17px',
                                                        lineHeight: '1.575',

                                                        textDecoration: 'none',
                                                    }}

                                                >
                                                    {(item.phoneNumber) ? `Phone : ${item.phoneNumber}` : null}
                                                </Typography>
                                                {(item.emailId) ?
                                                    <Typography
                                                        sx={{
                                                            ml: '2px',
                                                            fontWeight: 400,
                                                            fontSize: matches ? '1rem' : '17px',
                                                            lineHeight: '1.575',
                                                            textDecoration: 'none',
                                                        }}

                                                    >
                                                        Email :
                                                        <Link underline='none' href={`mailto:${item.emailId}`} sx={{ ml: '10px' }}>
                                                            {item.emailId}
                                                        </Link>
                                                    </Typography>
                                                    :
                                                    null}
                                                {/* <Typography
                                                    sx={{
                                                        ml: '2px',
                                                        fontWeight: 400,
                                                        fontSize: matches ? '1rem' : '17px',
                                                        lineHeight: '1.575',

                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Website :
                                                    <Link underline='none' sx={{ ml: '10px' }}>
                                                        {item.website}
                                                    </Link>
                                                </Typography> */}

                                                <Stack direction="row" spacing={0} sx={{
                                                    // pl: '1.5em',
                                                    pt: '5px',
                                                    pb: '1em'
                                                }}>
                                                    <LocationOnRounded sx={{ color: '#ff0055' }} />
                                                    <Typography
                                                        sx={{

                                                            fontWeight: 400,
                                                            fontSize: matches ? '15px' : '17px',
                                                            lineHeight: '1.575',

                                                            textDecoration: 'none',
                                                        }}
                                                        color='text.secondary'
                                                    >
                                                        {item.address}
                                                    </Typography>
                                                </Stack>

                                            </div>
                                        </Grid>

                                    </Grid>
                                    <Divider sx={{ m: '1em' }} />
                                    <Box sx={{ m: '10px 20px', display: 'flex', flexDirection: 'end', justifyContent: 'flex-end' }}>
                                        <Button variant='contained' onClick={() => history(`/classified/${slugify(businessPages)}/${item.agentSlug}`)} onClick1={handleClick}>Know More</Button>
                                    </Box>
                                </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}



const ClassifiedsContainer = () => {
    const theme = useTheme();
    const [businessData, setBusinessData] = React.useState([]);
    const [agentData, setAgentData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTerm1, setSearchTerm1] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [value1, setValue1] = React.useState(0);
    const isMatch = useMediaQuery('(max-width:480px)');
    const isMatch1 = useMediaQuery('(max-width:400px)');

    //card filter
    const RealEstate = agentData.filter(item => item.businessType.title === 'Real Estate');
    // const Roomates = businessData.filter(item => item.businessType.title === 'Roommates');
    // const Daycare = businessData.filter(item => item.businessType.title === 'Daycare Service (Provided)')
    // const Doctors = agentData.filter(item => item.businessType.title === 'Doctor')
    // const Cpa = agentData.filter(item => item.businessType.title === 'CPA and Tax Advisors')
    // const Temples = agentData.filter(item => item.businessType.title === 'Temples')
    const Photograpy = agentData.filter(item => item.businessType.title === 'Photography')
    // const Gurudwara = agentData.filter(item => item.businessType.title === 'Gurudwara')
    // const IndianMovie = agentData.filter(item => item.businessType.title === 'Indian Movies')
    const Decoration = agentData.filter(item => item.businessType.title === 'Decorations and Jeweleries').sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)


    //city filter
    // const cityTemple = Temples.map(item => item.city.title)
    // const cityTemple1 = [...new Set(cityTemple)]

    const cityPhoto = Photograpy.map(item => item.city.title)
    const cityPhoto1 = [...new Set(cityPhoto)]

    const cityRealEstate = RealEstate.map(item => item.city.title)
    const cityRealEstate1 = [...new Set(cityRealEstate)]


    const cityDecoration = Decoration.map(item => item.city.title)
    const cityDecoration1 = [...new Set(cityDecoration)]

    console.log('cities', RealEstate.map(item => item.city.title))


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
        setValue1(newValue);
    };

    const getBusiness = async () => {
        const data = await BusinessDataService.getAllBusiness();
        // console.log("business", data.docs);
        setBusinessData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent();
        console.log("agents", data.docs);
        setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const filteredClient = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            return RealEstate
        }
        return RealEstate
    },
    )

    const filteredClient1 = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            return Decoration.filter(item => item.city.title === searchTerm1)
        }
        return agentData
    },
    )

    // const filteredClient2 = React.useMemo(() => {
    //     if (searchTerm1.length > 0) {
    //         return Temples.filter(item => item.city.title === searchTerm1)
    //     }
    //     return agentData
    // },
    // )

    // const filteredClient3 = React.useMemo(() => {
    //     if (searchTerm1.length > 0) {
    //         return Gurudwara.filter(item => item.city.title === searchTerm1)
    //     }
    //     return agentData
    // },
    // )

    const filteredClient4 = React.useMemo(() => {
        if (searchTerm1.length > 0) {
            return Photograpy.filter(item => item.city.title === searchTerm1)
        }
        return agentData
    },
    )
    React.useEffect(() => {
        getBusiness();
    }, []);

    React.useEffect(() => {
        getAgents();
    }, []);

    if (agentData.length === 0) {
        return (
            <>
                {isMatch ?
                    <div style={{ padding: '16em 0em 16em 12em' }}>
                        <ReactLoading type="spokes" color="crimson" height={30} width={30} />
                    </div>

                    :
                    <div style={{ paddingTop: '25em', height: '1000px', paddingLeft: '48em', }}>
                        <ReactLoading type="spokes" color="crimson" height={50} width={50} />
                    </div>}
            </>
        )
    }



    return (
        <Box component="main" sx={{ flexGrow: 1, p: isMatch ? 1 : 3 }}>
            {
                isMatch ?
                    <CustomPaper1>
                        <Typography gutterBottom
                            sx={{
                                pt: '18px',
                                pb: '18px',
                                color: '#fff',
                                fontSize: '2.5em',
                                textAlign: 'center',

                            }}>
                            Indian Classified
                        </Typography>
                        <Container component={Paper} maxWidth="sm" sx={{ backgroundColor: 'rgba(0,0,0,.5) !important', }}>
                            <Item sx={{}}>
                                Search by Category
                            </Item>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="visible arrows tabs example"
                                textColor="inherit"
                                indicatorColor='none'
                                sx={{
                                    width: isMatch1 ? 300 : 352,
                                    backgroundColor: 'rgba(0,0,0,.5) !important',
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                        color: '#fff'
                                    },
                                }}
                            >

                                <Chip
                                    sx={{
                                        fontSize: '15px',
                                        color: '#fff',
                                        mt: '7px',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                            color: '#fff',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    label="All"
                                    variant="outlined"
                                    onClick={() => setSearchTerm("")}
                                />
                                {
                                    [
                                        'Real Estate',
                                        'Decorations and Jeweleries',
                                        'Photography',
                                    ].map((item) => {
                                        return (
                                            <>
                                                {
                                                    searchTerm === item ?
                                                        <Chip
                                                            sx={{
                                                                fontSize: '13px',
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                                mt: '7px',
                                                                ml: '10px'
                                                            }}
                                                            label={item}
                                                            variant="filled"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                        :
                                                        <Chip
                                                            sx={{
                                                                fontSize: '13px',
                                                                color: '#fff',
                                                                mt: '7px',
                                                                ml: '10px',
                                                                '&:hover': {
                                                                    background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                    color: '#fff',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                            label={item}
                                                            variant="outlined"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                }
                                            </>

                                        )
                                    })
                                }
                            </Tabs>
                            <Item2>
                                Search by City
                            </Item2>
                            <Tabs
                                value={value1}
                                onChange={handleChange1}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="visible arrows tabs example"
                                textColor="inherit"
                                indicatorColor='none'
                                sx={{
                                    width: isMatch1 ? 300 : 352,
                                    backgroundColor: 'rgba(0,0,0,.5) !important',
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                        color: '#fff'
                                    },
                                }}
                            >
                                <Chip
                                    sx={{
                                        fontSize: '15px',
                                        color: '#fff',
                                        mt: '7px',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                            color: '#fff',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    label="All"
                                    variant="outlined"
                                    onClick={() => setSearchTerm1("")}
                                />
                                {
                                    searchTerm ?
                                        null :
                                        [
                                            'Redmond',
                                            'Bothell',
                                            'Sammamish',
                                            'Bellevue',
                                            'Lynnwood',
                                            'Kirkland',
                                            'Lake Stevens',
                                        ].map((item) => {
                                            return (
                                                <>
                                                    {
                                                        searchTerm1 === item ?
                                                            <Chip
                                                                sx={{
                                                                    fontSize: '13px',
                                                                    color: '#fff',
                                                                    mt: '7px',
                                                                    ml: '10px',
                                                                    background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                    color: '#fff',
                                                                }}
                                                                label={item}
                                                                variant="filled"
                                                            />
                                                            :
                                                            <Chip
                                                                sx={{
                                                                    fontSize: '13px',
                                                                    color: '#fff',
                                                                    mt: '7px',
                                                                    ml: '10px',
                                                                    '&:hover': {
                                                                        background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                        color: '#fff',
                                                                        cursor: 'pointer'
                                                                    }
                                                                }}
                                                                label={item}
                                                                variant="outlined"
                                                                onClick={() => setSearchTerm1(item)}
                                                            />
                                                    }
                                                </>
                                            )
                                        })
                                }
                                {
                                    searchTerm === 'Real Estate'
                                        ?
                                        <>
                                            {
                                                cityRealEstate1.map((item) => {
                                                    return (
                                                        <>
                                                            {
                                                                searchTerm1 === item ?
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '13px',
                                                                            color: '#fff',
                                                                            mt: '7px',
                                                                            ml: '10px',
                                                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                            color: '#fff',
                                                                        }}
                                                                        label={item}
                                                                        variant="filled"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                                    :
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '13px',
                                                                            color: '#fff',
                                                                            mt: '7px',
                                                                            ml: '10px',
                                                                            '&:hover': {
                                                                                background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                                color: '#fff',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                        label={item}
                                                                        variant="outlined"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                            }
                                                        </>

                                                    )



                                                })
                                            }
                                        </>

                                        :
                                        null
                                }

                                {
                                    searchTerm === 'Decorations and Jeweleries'
                                        ?
                                        <>
                                            {
                                                cityDecoration1.map((item) => {
                                                    return (
                                                        <>
                                                            {
                                                                searchTerm1 === item ?
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '13px',
                                                                            mt: '7px',
                                                                            ml: '10px',
                                                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                            color: '#fff',
                                                                        }}
                                                                        label={item}
                                                                        variant="filled"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                                    :
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '13px',
                                                                            color: '#fff',
                                                                            mt: '7px',
                                                                            ml: '10px',
                                                                            '&:hover': {
                                                                                background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                                color: '#fff',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                        label={item}
                                                                        variant="outlined"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                            }
                                                        </>

                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        null
                                }
                                {
                                    searchTerm === 'Photopraphy'
                                        ?
                                        <>
                                            {
                                                cityPhoto1.map((item) => {
                                                    return (
                                                        <>
                                                            {
                                                                searchTerm1 === item ?
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '13px',
                                                                            color: '#fff',
                                                                            mt: '7px',
                                                                            ml: '10px',
                                                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                        }}
                                                                        label={item}
                                                                        variant="filled"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                                    :
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '13px',
                                                                            color: '#fff',
                                                                            mt: '7px',
                                                                            ml: '10px',
                                                                            '&:hover': {
                                                                                background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                                color: '#fff',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                        label={item}
                                                                        variant="outlined"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                            }
                                                        </>

                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        null
                                }

                            </Tabs>
                        </Container>

                    </CustomPaper1>
                    :
                    <CustomPaper>
                        <Typography gutterBottom
                            sx={{
                                pt: '20px',
                                pb: '20px',
                                textAlign: 'center',
                                // margin: '0px 0px 0.35em',
                                color: '#fff',
                                // fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                                fontSize: '4em',
                                lineHeight: 1.167,
                                letterSpacing: '0em',
                            }}
                        >
                            Indian Classified
                        </Typography>

                        <Paper sx={{ backgroundColor: 'rgba(0,0,0,.5) !important', }}>
                            {/* <Grid xs={6}></Grid> */}
                            <Item sx={{}}>
                                Search by Category
                            </Item>

                            <Stack
                                direction="row"
                                spacing={2}
                                alignContent="center"
                                sx={{ p: '5px', ml: '2em', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', }}
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                            background: 'white',
                                            m: '2px',
                                            ml: '16px'
                                        }} />
                                }>

                                <Chip
                                    sx={{
                                        fontSize: '1em',
                                        color: '#fff',

                                        '&:hover': {
                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                            color: '#fff',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    label="All"
                                    variant="outlined"
                                    onClick={() => setSearchTerm("")}
                                />
                                {
                                    [
                                        'Real Estate',
                                        'Decorations and Jeweleries',
                                        'Photography',
                                    ].map((item) => {
                                        return <>
                                            {
                                                searchTerm === item ?
                                                    <Chip
                                                        sx={{
                                                            fontSize: '1em',
                                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                            color: '#fff',
                                                        }}
                                                        label={item}
                                                        variant="filled"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                                    :
                                                    <Chip
                                                        sx={{
                                                            fontSize: '1em',
                                                            color: '#fff',
                                                            '&:hover': {
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        label={item}
                                                        variant="outlined"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                            }</>
                                    })
                                }

                            </Stack>
                            <Item2>
                                Search by City
                            </Item2>

                            <Stack
                                direction="row"
                                spacing={2}
                                alignContent="center"
                                sx={{ p: '5px', ml: '2em' }}
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                            background: 'white',
                                            m: '2px',
                                            ml: '16px'
                                        }} />
                                }>
                                <Chip
                                    sx={{
                                        fontSize: '1em',
                                        color: '#fff',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                            color: '#fff',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    label="All"
                                    variant="outlined"
                                    onClick={() => setSearchTerm1("")}
                                />
                                {
                                    searchTerm ?
                                        null :
                                        [
                                            'Redmond',
                                            'Bothell',
                                            'Sammamish',
                                            'Bellevue',
                                            'Lynnwood',
                                            'Kirkland',
                                            'Lake Stevens',
                                        ].map((item) => {
                                            return (
                                                <>
                                                    {
                                                        searchTerm1 === item ?
                                                            <Chip
                                                                sx={{
                                                                    fontSize: '1em',
                                                                    background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                    color: '#fff',
                                                                }}
                                                                label={item}
                                                                variant="filled"
                                                            />
                                                            :
                                                            <Chip
                                                                sx={{
                                                                    fontSize: '1em',
                                                                    color: '#fff',
                                                                    '&:hover': {
                                                                        background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                        color: '#fff',
                                                                        cursor: 'pointer'
                                                                    }
                                                                }}
                                                                label={item}
                                                                variant="outlined"
                                                                onClick={() => setSearchTerm1(item)}
                                                            />
                                                    }
                                                </>
                                            )
                                        })
                                }
                                {
                                    searchTerm === 'Real Estate'
                                        ?
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignContent="center"
                                            sx={{ ml: '2em' }}
                                            divider={
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    sx={{
                                                        background: 'white',
                                                        m: '2px',
                                                        ml: '16px'
                                                    }} />
                                            }>
                                            {
                                                cityRealEstate1.map((item) => {
                                                    return (
                                                        <>
                                                            {
                                                                searchTerm1 === item ?
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '1em',
                                                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                            color: '#fff',
                                                                        }}
                                                                        label={item}
                                                                        variant="filled"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                                    :
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '1em',
                                                                            color: '#fff',
                                                                            '&:hover': {
                                                                                background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                                color: '#fff',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                        label={item}
                                                                        variant="outlined"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                            }
                                                        </>

                                                    )



                                                })
                                            }
                                        </Stack>

                                        :
                                        null
                                }

                                {
                                    searchTerm === 'Decorations and Jeweleries'
                                        ?
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignContent="center"
                                            sx={{ ml: '2em' }}
                                            divider={
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    sx={{
                                                        background: 'white',
                                                        m: '2px',
                                                        ml: '16px'
                                                    }} />
                                            }>
                                            {
                                                cityDecoration1.map((item) => {
                                                    return (
                                                        <>
                                                            {
                                                                searchTerm1 === item ?
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '1em',
                                                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                            color: '#fff',
                                                                        }}
                                                                        label={item}
                                                                        variant="filled"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                                    :
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '1em',
                                                                            color: '#fff',
                                                                            '&:hover': {
                                                                                background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                                color: '#fff',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                        label={item}
                                                                        variant="outlined"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                            }
                                                        </>

                                                    )
                                                })
                                            }
                                        </Stack>
                                        :
                                        null
                                }
                                {
                                    searchTerm === 'Photopraphy'
                                        ?
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignContent="center"
                                            sx={{ ml: '2em' }}
                                            divider={
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    sx={{
                                                        background: 'white',
                                                        m: '2px',
                                                        ml: '16px'
                                                    }} />
                                            }>
                                            {
                                                cityPhoto1.map((item) => {
                                                    return (
                                                        <>
                                                            {
                                                                searchTerm1 === item ?
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '1em',
                                                                            background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                            color: '#fff',
                                                                        }}
                                                                        label={item}
                                                                        variant="filled"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                                    :
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: '1em',
                                                                            color: '#fff',
                                                                            '&:hover': {
                                                                                background: 'linear-gradient(to right, #de6161, #2657eb)',
                                                                                color: '#fff',
                                                                                cursor: 'pointer'
                                                                            }
                                                                        }}
                                                                        label={item}
                                                                        variant="outlined"
                                                                        onClick={() => setSearchTerm1(item)}
                                                                    />
                                                            }
                                                        </>

                                                    )
                                                })
                                            }
                                        </Stack>
                                        :
                                        null
                                }

                            </Stack>
                            <br />
                        </Paper>
                    </CustomPaper>
            }

            <>
                {/* {(() => {
                        if (searchTerm == '') {
                            if (searchTerm == 'Real Estate') {
                                return <Classified BusinessData={filteredClient} SearchTerm={searchTerm} SearchTerm1={searchTerm1} matches={isMatch} />
                            }
                            else if (searchTerm == 'Temples') {
                                return <ClassifiedTemples BusinessData={Temples} matches={isMatch} />
                            }
                            else if (searchTerm == 'Photography') {
                                return <ClassifiedTemples BusinessData={Photograpy} matches={isMatch} />
                            }
                            else {
                                return (
                                    <>
                                        <Classified BusinessData={filteredClient} SearchTerm={searchTerm} SearchTerm1={searchTerm1} matches={isMatch} />
                                        <ClassifiedTemples BusinessData={Temples} matches={isMatch} />
                                        <ClassifiedTemples BusinessData={Photograpy} matches={isMatch} />
                                    </>
                                )

                            }
                        }
                        else {
                            return (
                                <>
                                    <Classified BusinessData={filteredClient} SearchTerm={searchTerm} SearchTerm1={searchTerm1} matches={isMatch} />
                                    <ClassifiedTemples BusinessData={Temples} matches={isMatch} />
                                    <ClassifiedTemples BusinessData={Photograpy} matches={isMatch} />
                                </>
                            )

                        }

                    })} */}

                {
                    !searchTerm ?
                        <>
                            <></><Classified BusinessData={searchTerm1 ? filteredClient : RealEstate} SearchTerm={filteredClient} SearchTerm1={searchTerm1} matches={isMatch} />
                            {/* <ClassifiedTemples BusinessData={searchTerm1 ? filteredClient2 : Temples} SearchTerm={searchTerm} SearchTerm1={searchTerm1} matches={isMatch} /> */}
                            {/* <ClassifiedTemples BusinessData={searchTerm1  ? filteredClient2 : Temples} SearchTerm={searchTerm} SearchTerm1={searchTerm1}  matches={isMatch} />
                                <ClassifiedTemples BusinessData={searchTerm1  ? filteredClient3 : Gurudwara} SearchTerm={filteredClient} SearchTerm1={searchTerm1}  matches={isMatch} />
                                <ClassifiedTemples BusinessData={searchTerm1  ? filteredClient4 : Photograpy} SearchTerm={filteredClient} SearchTerm1={searchTerm1}  matches={isMatch} /> */}
                            <IndianMovies BusinessData={searchTerm1 ? filteredClient1 : Decoration} SearchTerm={filteredClient} SearchTerm1={searchTerm1} matches={isMatch} />
                            <ClassifiedTemples BusinessData={searchTerm1 ? filteredClient4 : Photograpy} SearchTerm={filteredClient} SearchTerm1={searchTerm1} matches={isMatch} />
                            {/* <Classified BusinessData={Doctors} matches={isMatch} />
                                <Classified BusinessData={Cpa} matches={isMatch} /> */}
                        </>
                        :
                        null
                }
                {
                    searchTerm === 'Real Estate' ?
                        <Classified BusinessData={searchTerm1 ? filteredClient : RealEstate} SearchTerm={searchTerm} SearchTerm1={searchTerm1} matches={isMatch} />
                        :
                        null
                }
                {
                    searchTerm === 'Decorations and Jeweleries' ?
                        <IndianMovies BusinessData={searchTerm1 ? filteredClient1 : Decoration} SearchTerm={searchTerm} SearchTerm1={searchTerm1} matches={isMatch} />
                        :
                        null
                }
                {
                    searchTerm === 'Photography' ?
                        <ClassifiedTemples BusinessData={searchTerm1 ? filteredClient4 : Photograpy} SearchTerm={filteredClient} SearchTerm1={searchTerm1} matches={isMatch} />
                        :
                        null
                }
                {/* {
                        searchTerm === 'Temples' ?
                            <ClassifiedTemples BusinessData={searchTerm1  ? filteredClient2 : Temples} SearchTerm={filteredClient} SearchTerm1={searchTerm1}  matches={isMatch} />
                            :
                            null
                    }
                    {
                        searchTerm === 'Gurudwara' ?
                            <ClassifiedTemples BusinessData={searchTerm1  ? filteredClient3 : Gurudwara} SearchTerm={filteredClient} SearchTerm1={searchTerm1}  matches={isMatch}  />
                            :
                            null
                    }
                    {
                        searchTerm === 'Photography' ?
                            <ClassifiedTemples BusinessData={searchTerm1  ? filteredClient4 : Photograpy} SearchTerm={filteredClient} SearchTerm1={searchTerm1}  matches={isMatch} />
                            :
                            null
                    }
                    {
                        searchTerm === 'Indian Movies' ?
                            <IndianMovies BusinessData={searchTerm1  ? filteredClient1 : IndianMovie} SearchTerm={filteredClient} SearchTerm1={searchTerm1}   matches={isMatch} />
                            :
                            null
                    }
                    {
                        searchTerm === 'Doctor' ?
                            <Classified BusinessData={Doctors} matches={isMatch} />
                            :
                            null
                    }
                    {
                        searchTerm === 'CPA and Tax Advisors' ?
                            <Classified BusinessData={Cpa} matches={isMatch} />
                            :
                            null
                    } */}

            </>

        </Box>

    )
}

export default ClassifiedsContainer;

//how to use if , elseif, else cases in reactjs?


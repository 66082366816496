import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AppBar, Paper, } from '@mui/material';
import { styled } from '@mui/material/styles';
import AdminDataPannel from './AdminDataPannel';
import ClassifiedsDataPannel from './Classfiieds/ClassifiedsDataPannel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const TabWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  marginTop: '0em',
  backgroundColor: 'white',
}));

const Tabb = styled(Tab)(({ theme }) => ({
  color: 'white',

}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginLeft: '1em',
  '& .Mui-selected': {
    color: 'white'
  },
  '& .MuiTabs-indicator':{
   innerHeight: 10,
   color: 'blue',
   backgroundColor: '#d32f2f',
    }
}))


export default function AdminTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper elevation={2} >
        <AppBar position="static"  style={{ marginTop: '4em', background: '#1D2671'}}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor='inherit'
            indicatorColor="string"
          >
            <Tabb label="Business Details" {...a11yProps(0)} />
            {/* <Tabb label="Classifieds" {...a11yProps(1)} /> */}
          </StyledTabs>
        </AppBar>
        <TabPanel value={value} index={0} style={{ background: 'beige', marginBottom: 20 }}>
          <AdminDataPannel />
        </TabPanel>
        {/* <TabPanel value={value} index={1} style={{ background: 'beige', marginBottom: 20 }}>
          <ClassifiedsDataPannel />
        </TabPanel> */}

      </Paper>

    </>
  );
}

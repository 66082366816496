
import { db } from '../firebase/firebase'
import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query,
    orderBy,
  } from "firebase/firestore";

  const agentCollectionRef = collection(db, "agents")

  class AgentDataService {

    addAgent = (newAgent) => {
      return addDoc(agentCollectionRef, newAgent);
    };
  
    updateAgent = (id, updatedAgent) => {
      const agentDoc = doc(db, "agents", id);
      return updateDoc(agentDoc, updatedAgent);
    };
  
    deleteAgent = (id) => {
      const agentDoc = doc(db, "agents", id);
      return deleteDoc(agentDoc);
    };
  
    getAllAgent = () => {
      return getDocs(agentCollectionRef);
    };

    getSortedAgent = () => {
      return  getDocs(query(agentCollectionRef, orderBy('releaseDate', 'desc')));;
    };
  
    getAgent = (id) => {
      const agentDoc = doc(db, "agents", id);
      return getDoc(agentDoc);
    };
  }
  
  export default new AgentDataService();
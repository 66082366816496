import React from 'react'
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Chip, Container, Divider, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Paper, Skeleton, Stack, styled, Tab, Tabs, tabsClasses, Typography, useMediaQuery } from '@mui/material'
import Drawers from '../../Sidebar/Drawer'
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from '../../../client'
import { useNavigate } from 'react-router-dom';
import coverImg from '../../../images/BgImages/Food2.jpg'
import AgentDataService from '../../../context/AgentDatabase';
import { padding } from '@mui/system';
import { DateRangeOutlined, DoubleArrowOutlined, EmojiFoodBeverageRounded, InfoOutlined, LocationCity, LocationOnOutlined } from '@mui/icons-material';
import slugify from 'react-slugify';
import ReactLoading from 'react-loading';
import Footer from '../../Footer';

const CustomPaper = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    padding: 40,
    width: '100%',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em'
}))


const CustomPaper1 = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em',
    // marginLeft: '-1em',
    padding: '14px'
}))

const CustomBox = styled(Box)(({ theme }) => ({
    width: '100%',
    lineHeight: '0',
    display: 'block',
    overflow: ' hidden',
    position: 'relative',

}))

const CustomDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 24px'
}))

const CustomTypography = styled(Typography)(({ theme }) => ({
    lineHeight: '1.85714',
    fontSize: '1rem',
    fontWeight: 400,
    color: 'rgb(99, 115, 129)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'WebkitBox',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    height: '52px',
    marginTop: '10px'
}))

const CustomCard1 = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: "white",
    fontSize: "1.2rem",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {

        fontSize: "0.99rem",
    },
}))

const CustomCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #1488cc, #2b32b2)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 600,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',

}))

const builder = imageUrlBuilder(sanityClient);


function urlFor(source) {
    return builder.image(source);
}

export default function Food() {
    const [posts, setPosts] = React.useState(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTerm1, setSearchTerm1] = React.useState("");
    const [agentData, setAgentData] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const [color, setColor] = React.useState('#fff');
    const [bgColor, setBgColor] = React.useState('none');
    const history = useNavigate();
    const isMatch = useMediaQuery('(max-width:480px)')
    const heading = agentData.filter(item => item.businessType.title === 'Indian Food')
    const businessPages = [...new Set(heading)];


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }

    const handleClick1 = (e) => {
        setColor('aqua')
        setBgColor('rgb(250, 84, 28)')
    }

    // const filteredClient = React.useMemo(() => {
    //     if (searchTerm.length > 0) {
    //         return posts.filter(item => item.city === searchTerm)
    //     }
    //     return posts
    // }, [searchTerm, posts])

    const filteredClient1 = React.useMemo(() => {
        if (searchTerm.length > 0) {
            return heading.filter(item => item.city.title === searchTerm)
        }
        return heading
    }, [searchTerm, heading])

    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent()
        console.log("agents", data.docs)
        setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    React.useEffect(() => {
        getAgents()
    }, [])



    // console.log('city', posts.map(item => item.city));
    if (heading.length === 0) {
        return (
            <>
                {isMatch ?
                    <div style={{ padding: '16em 0em 16em 12em' }}>
                        <ReactLoading type="spokes" color="crimson" height={30} width={30} />
                    </div>

                    :
                    <div style={{ paddingTop: '25em', height: '1000px', paddingLeft: '48em', }}>
                        <ReactLoading type="spokes" color="crimson" height={50} width={50} />
                    </div>}
            </>

        )
    }

    return (

        <Box component="main" sx={{ flexGrow: 1, p: isMatch ? 1 : 2 }}>
            {
                isMatch ?
                    <CustomPaper1>
                        <Typography gutterBottom
                            sx={{
                                pt: '20px',
                                pb: '20px',
                                color: '#fff',
                                fontSize: '2.5em',
                                textAlign: 'center',

                            }}>
                            Indian Food
                        </Typography>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="visible arrows tabs example"
                            textColor="inherit"
                            indicatorColor='none'
                            sx={{
                                width: 352,
                                backgroundColor: 'rgba(0,0,0,.5) !important',
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                    color: '#fff'
                                },
                            }}
                        >
                            <Chip
                                sx={{
                                    fontSize: '15px',
                                    color: '#fff',
                                    mt: '7px',
                                    '&:hover': {
                                        background: 'linear-gradient(to right, #134e5e, #71b280)',
                                        color: '#fff',
                                        cursor: 'pointer'
                                    }
                                }}
                                label="All"
                                variant="outlined"
                                onClick={() => setSearchTerm("")}
                            />
                            {
                                [
                                    'Redmond',
                                    'Bothell',
                                    'Sammamish',
                                    'Bellevue',
                                    'Lynnwood',
                                    'Kirkland',
                                    'Lake Stevens',
                                    'Issaquah',
                                    'Everett',
                                    // 'Covington'
                                ].map((item) => {
                                    return (
                                        <>
                                            {
                                                searchTerm === item ?
                                                    <Chip
                                                        sx={{
                                                            fontSize: '13px',
                                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                            color: '#fff',
                                                            mt: '7px',
                                                            ml: '10px'
                                                        }}
                                                        label={item}
                                                        variant="filled"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                                    :
                                                    <Chip
                                                        sx={{
                                                            fontSize: '13px',
                                                            color: '#fff',
                                                            mt: '7px',
                                                            ml: '10px',
                                                            '&:hover': {
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                                cursor: 'pointer'
                                                            }
                                                        }}
                                                        label={item}
                                                        variant="outlined"
                                                        onClick={() => setSearchTerm(item)}
                                                    />
                                            }
                                        </>

                                    )
                                })
                            }
                        </Tabs>
                    </CustomPaper1>
                    :
                    <CustomPaper >
                        <Typography gutterBottom
                            sx={{
                                pt: '1em',
                                pb: '1em',
                                color: '#fff',
                                fontSize: '4em',
                                textAlign: 'center',

                            }}
                        >
                            Indian Food
                        </Typography>
                        <Paper sx={{ backgroundColor: 'rgba(0,0,0,.5) !important' }}>

                            <Stack
                                direction="row"
                                spacing={2}
                                alignContent="center"
                                sx={{ p: '2em', ml: '2em' }}
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                            background: 'white',
                                            m: '2px',
                                            ml: '16px'
                                        }} />
                                }>
                                <Chip
                                    sx={{
                                        fontSize: '1em',
                                        color: '#fff',
                                        '&:hover': {
                                            background: 'linear-gradient(to right, #134e5e, #71b280)',
                                            color: '#fff',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    label="All"
                                    variant="outlined"
                                    onClick={() => setSearchTerm("")}
                                />
                                {
                                    [
                                        'Redmond',
                                        'Bothell',
                                        'Sammamish',
                                        'Bellevue',
                                        'Lynnwood',
                                        'Kirkland',
                                        'Lake Stevens',
                                        'Issaquah',
                                        'Everett',
                                        // 'Covington'
                                    ].map((item) => {
                                        return (
                                            <>
                                                {
                                                    searchTerm === item ?
                                                        <Chip
                                                            sx={{
                                                                fontSize: '1em',
                                                                background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                color: '#fff',
                                                            }}
                                                            label={item}
                                                            variant="filled"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                        :
                                                        <Chip
                                                            sx={{
                                                                fontSize: '1em',
                                                                color: '#fff',
                                                                '&:hover': {
                                                                    background: 'linear-gradient(to right, #134e5e, #71b280)',
                                                                    color: '#fff',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                            label={item}
                                                            variant="outlined"
                                                            onClick={() => setSearchTerm(item)}
                                                        />
                                                }
                                            </>

                                        )
                                    })
                                }

                            </Stack>
                        </Paper>
                    </CustomPaper>
            }
            <Container maxWidth={isMatch ? "md" : "xl"}>
                <Grid container spacing={isMatch ? 1 : 2}
                    sx={{
                        mt: isMatch ? '1em' : '3em',
                        mb: '1em',
                        borderRadius: '5px',
                        backgroundColor: 'none',
                    }}
                >
                    <Grid xs={12} >
                        {
                            searchTerm ?
                                <>
                                    {
                                        isMatch ?
                                            <CustomCard1>
                                                <Stack direction="row" >
                                                    <EmojiFoodBeverageRounded sx={{ color: '#fff', fontSize: '1.8em', mr: '10px' }} />
                                                    <Typography
                                                        variant='h5'
                                                        sx={{ color: '#fff', fontSize: '1.5em', textTransform: 'capitalize', textAlign: 'start' }}
                                                    >
                                                        Indian Food in {searchTerm}
                                                    </Typography>
                                                </Stack>
                                            </CustomCard1>
                                            :
                                            <CustomCard>
                                                <Stack direction="row" >
                                                    <EmojiFoodBeverageRounded sx={{ color: '#fff', fontSize: '2.3em', mt: '1px', mr: '10px' }} />
                                                    <Typography
                                                        variant='h5'
                                                        sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                                    >
                                                        Indian Food in {searchTerm}
                                                    </Typography>
                                                </Stack>
                                            </CustomCard>
                                    }</>

                                :
                                null
                        }
                    </Grid>
                    {
                        heading && filteredClient1.sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1).map((post) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} key={post.businessName}>
                                    <Card sx={{ mt: '15px', mb: '2em', borderRadius: '16px' }} >
                                        <CardActionArea >
                                            <CardMedia
                                                component="img"
                                                alt={post.businessType.title}
                                                width={isMatch ? '250' : "auto"}
                                                height={isMatch ? '300' : "350"}
                                                sx={{
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'norepeat',
                                                    backgroundPosition: 'center center',
                                                }}
                                                image={post.mainImage}
                                                src="blog posts"
                                                onClick={() => history(`${slugify(post.agentSlug)}`)}
                                                onClick1={handleClick}
                                            />
                                            <Grid container alignItems="center" style={{ marginTop: '1em' }} >
                                                {/* <Grid item sm >
                                                    <Typography variant="body1" color="text.secondary" display="block" gutterBottom style={{ marginLeft: '1.3em',   }}>
                                                        <DateRangeOutlined sx={{ fontSize: 22 }} /> {post.date}
                                                    </Typography>
                                                </Grid> */}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', ml: '2em' }}>
                                                    <DateRangeOutlined sx={{ fontSize: 22 }} />
                                                    <Typography color="text.secondary" display="block" gutterBottom sx={{ fontSize: '17px', }}>
                                                        {post.date}
                                                    </Typography>
                                                </Box>
                                                {/* <Grid item  >
                                                <Typography variant="body2" color="text.secondary" display="block" gutterBottom style={{  marginLeft: '0.2em'}}>
                                                    -
                                                </Typography>
                                            </Grid>
                                            <Grid item align="initial">
                                                <Avatar alt="authorimage" sx={{ width: 22, height: 22, marginLeft: '0.2em' }} src={urlFor(post.mainImage).url()} />
                                            </Grid>
                                            <Grid item align="initial">
                                                <Typography variant="body2" color="text.secondary" display="block" gutterBottom style={{  marginLeft: '0.2em' }}>
                                                    {post.authorname}
                                                </Typography>
                                            </Grid> */}

                                                <Divider variant="middle" style={{ width: '100%', marginTop: '0.5em' }} />
                                            </Grid>

                                            <CardContent>

                                                <Grid item spacing={4}>

                                                    <Grid item xs={12} style={{ background: 'lightgrey', padding: '1em', borderRadius: '10px' }}>
                                                        <Typography
                                                            variant="h6"
                                                            style={{ fontWeight: '500', }}
                                                        >
                                                            {post.businessName}
                                                        </Typography>
                                                    </Grid>
                                                    <br />
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            gutterBottom
                                                            style={{ fontWeight: '400', fontSize: '17px', }}
                                                        >
                                                            {post.shortdescription}...
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            style={{ margin: '0px -7px', fontSize: '14px', background: 'linear-gradient(45deg, #e91d1d, #f97f45)', borderRadius: '20px' }}
                                                            onClick={() => history(`${post.slug.current}`)}
                                                        >
                                                            Read More
                                                        </Button>
                                                    </Grid> */}
                                                    <Box sx={{ display: 'flex', flexDirection: 'end', justifyContent: 'flex-end' }}>
                                                        <Button
                                                            variant='contained'
                                                            size='medium'
                                                            sx={{
                                                                backgroundColor: 'rgb(250, 84, 28)',
                                                                '&:hover': {
                                                                    backgroundColor: 'rgb(250, 84, 28)'
                                                                }
                                                            }}
                                                            onClick={() => history(`${slugify(post.agentSlug)}`)}
                                                            onClick1={handleClick}
                                                        >
                                                            Read More
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })
                    }

                </Grid>
            </Container>
        </Box>

    )
}


// ho










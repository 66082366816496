
import { createTheme } from "@mui/material";

const Theme = createTheme({
   display: 'flex',
   palette: {
      primary: {
         main: "#2979ff" // This is an orange looking color
      },
      secondary: {
         main: "#f50057" //Another orange-ish color
      }
   },
   fontFamily: 'ITC Clearface,serif' // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
});
export default Theme



import React from 'react'
import { Box, Breadcrumbs, Card, CardContent, Chip, Container, Divider, Grid, Link, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'
import { useParams } from 'react-router-dom'
import AgentDataService from '../../../context/AgentDatabase'
import { AddLink, Call, Email, LineAxisOutlined, LinkOff, LinkOutlined, LocationCityOutlined, LocationDisabledOutlined, LocationOffOutlined, NavigateNextOutlined, Person, WebSharp } from '@mui/icons-material'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import { PhoneAndroidOutlined } from '@mui/icons-material'
import noImg from '../../../images/NoImage_Available.png'
import ImageScroll from '../../Classifieds/ImageScroll'
import ImageContainer from '../../Classifieds/ImageContainer'
import ReactLoading from 'react-loading';

const StyledTypography = styled(Typography)(({ theme }) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '1.375',
    textDecoration: 'none',
}))

const StyledMobTypography = styled(Typography)(({ theme }) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '1.375',
    textDecoration: 'none',
}))

const StyledTypography2 = styled(Typography)(({ theme }) => ({
    marginLeft: '2px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '1.375',
    textDecoration: 'none',
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        '&:nthDfDype(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))

const CustomDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flexStart',
    lineHeight: '1.85714',
    fontSize: '0.875rem',
    fontWeight: 400,
    marginLeft: '10px'
}))

const CustomBox = styled('Box')(({ theme }) => ({
    width: '24px',
    height: '24px',
}))

const CustomDivv = styled('div')(({ theme }) => ({
    margin: '0px 0px 0px 12px',
    display: 'flex',
    flexDirection: 'column',
}))

const CustomDiv1 = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left'
}))

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    // width: '100%',
    padding: theme.spacing(1),
    textAlign: 'end',
    color: theme.palette.text.secondary,
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
    marginTop: '1em',
    fontSize: '1.5em',
    fontWeight: '500',
}));

const MobCustomTypography = styled(Typography)(({ theme }) => ({
    marginTop: '1em',
    fontSize: '22px',
    fontWeight: '500',
    fontFamily: 'ITC Clearface,serif'
}));

const CustomTypography1 = styled(Typography)(({ theme }) => ({
    margin: '5px',
    fontSize: '19px',
    fontWeight: '500',
}));

const MobCustomTypography1 = styled(Typography)(({ theme }) => ({
    margin: '5px',
    fontSize: '16px',
    fontWeight: '500',
}));

const CustomTypography2 = styled('li')(({ theme }) => ({
    fontSize: '19px',
    fontWeight: '500',
}));

const MobCustomTypography2 = styled('li')(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '500',
}));




export const FoodDP = () => {
    const [agentData, setAgentData] = React.useState([]);
    const { categorySlug, agentSlug } = useParams();
    const Heading = agentData.filter(item => item.agentSlug === agentSlug);
    const isMatch = useMediaQuery('(max-width:480px)')
    const getAgents = async () => {
        const data = await AgentDataService.getAllAgent()
        console.log("agents", data.docs)
        setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    React.useEffect(() => {
        getAgents()
    }, [])

    if (Heading.length === 0) {
        return (
            <div style={{ paddingTop: '25em', height: '1000px', paddingLeft: '48em', }}>
                <ReactLoading type="spokes" color="crimson" height={50} width={50} />
            </div>
        )
    }

    // if(Heading.length === 0){
    //     return<ReactLoading type="bars" color="blue" height={667} width={375}/>
    // }
    // if (Heading.length === 0) {
    //     return (
    //         <div style={{ paddingTop: '25em', height: '1000px', paddingLeft: '48em', }}>
    //             <ReactLoading type="spokes" color="crimson" height={50} width={50} />
    //         </div>
    //     )
    // }

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" href="/" sx={{ fontSize: isMatch ? '15px' : '17px', }}>
            Home
        </Link>,
        <Link
            underline="none"
            key="2"
            color="inherit"
            sx={{ fontSize: isMatch ? '15px' : '17px', }}
            href="/indian-grocery-stores"

        >
            {Heading.map(item => item.businessType.title)}
        </Link>,
        <Typography key="3" color="text.primary" sx={{ fontSize: isMatch ? '15px' : '17px', fontWeight: 500, textTransform: 'capitalize' }}>
            {agentSlug.replace(/[^a-zA-Z ]/g, " ")}
        </Typography>, ,
    ]

    const CustomCard = styled(Card)(({ theme }) => ({
        display: 'flex',
        background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)',
        borderRadius: 'none',
        padding: 10,
        maxWidth: 800,
        [theme.breakpoints.up("md")]: {
            height: 65,
        },
        alignItems: "center",
        cursor: "pointer",
        marginTop: '3em'
    }))

    return (
        <>
            {
                Heading.map((card) => {
                    return (
                        <>
                            <Paper key={card.agentName} sx={{mt: '4em', p: isMatch ? '1em' : '2em 4em', borderRadius: '5px' }} elevation={0}>
                                <Typography variant={isMatch ? 'h5' : 'h4'}
                                    sx={{
                                        color: '#02367d',
                                        fontWeight: 500,
                                        ml: isMatch ? "11px" :'22px'
                                    }}>
                                    {card.businessName}
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', m: isMatch ? "10px 11px" :'10px 22px' }}>
                                    {
                                        isMatch ?
                                            <>
                                                <FmdGoodIcon sx={{ color: '#f50057' }} />
                                                <CustomDiv1>
                                                    {/* <StyledTypography variant='body2' gutterBottom>Address :</StyledTypography> */}
                                                    <StyledMobTypography variant='body2' gutterBottom> {card.address}</StyledMobTypography>
                                                </CustomDiv1>
                                            </>
                                            :
                                            <>
                                                <FmdGoodIcon sx={{ color: '#f50057' }} />
                                                <CustomDiv1>
                                                    {/* <StyledTypography variant='body2' gutterBottom>Address :</StyledTypography> */}
                                                    <StyledTypography variant='body2' gutterBottom> {card.address}</StyledTypography>
                                                </CustomDiv1>
                                            </>
                                    }

                                </Box>
                                <Breadcrumbs
                                    sx={{ m: isMatch ? "10px 15px" :'10px 25px' }}
                                    separator={<NavigateNextOutlined fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                                <Container maxWidth="xl">
                                    <Grid container spacing={2} sx={{ mt: isMatch ? '2em' : '2em', mb: isMatch ? '1em' : '2em' }}>
                                        <Grid item xs={isMatch ? 12 : 6}>
                                            <ImageScroll Images={card.agentProfile[0] ? card.agentProfile : card.mainImage} Name={card.businessName} matches={isMatch} />
                                        </Grid>

                                        <Grid item xs={isMatch ? 0 : 6}  >
                                            <ImageContainer Images={card.agentProfile[0] ? card.agentProfile : card.mainImage} Name={card.businessType.title} matches={isMatch} />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Divider sx={{ borderStyle: 'dashed', p: '1em' }} />
                                <Container maxWidth="xl" sx={{ p: isMatch ? null : '2em 0em', mt: isMatch ? '3em' : '2em',  }}>
                                        <TableContainer sx={{ width: isMatch ? 360 : 800 }} aria-label="simple table">
                                            <Table stickyHeader  aria-label="sticky table" >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="start" colSpan={2} sx={{ background: 'linear-gradient(to right, #00416a, #799f0c, #ffe000)' }}>
                                                            <Typography
                                                                color="success"
                                                                sx={{
                                                                    fontSize: isMatch ? "19px" : '1.7em',
                                                                    color: '#fff',
                                                                    fontWeight: 500
                                                                }}>
                                                                Details
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {
                                                card.phoneNumber ?
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText" }}>Phone</TableCell>
                                                        <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }} >{card.phoneNumber}</TableCell>
                                                    </TableRow>
                                                    :
                                                    null
                                            }
                                            {
                                                card.address ?
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText" }}>Address</TableCell>
                                                        <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }} >{card.address}</TableCell>
                                                    </TableRow>
                                                    :
                                                    null
                                            }
                                            {
                                                card.city.title ?
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText" }}>City</TableCell>
                                                        <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }} >{card.city.title}</TableCell>
                                                    </TableRow>
                                                    :
                                                    null
                                            }
                                            {(card.emailId) ?
                                                <TableRow>
                                                    <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText", }}>Email</TableCell>
                                                    <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }}>
                                                        <Link underline="none" href={`mailto:${card.emailId}`}>
                                                            <Typography sx={{ fontSize: isMatch ? "15px" : "1.1rem" }} gutterBottom> {card.emailId}</Typography>
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                null
                                            }

                                            {(card.website) ?
                                                <TableRow>
                                                    <TableCell style={{ fontSize: isMatch ? "15px" : "1.1rem", color: "GrayText", }}>Website</TableCell>
                                                    <TableCell align="right" style={{ fontSize: isMatch ? "15px" : "1.1rem", fontWeight: "400" }}>
                                                        <Link underline="none" href={card.website} target="_blank">
                                                            <Typography sx={{ fontSize: isMatch ? "15px" : "1.1rem" }} gutterBottom> {card.website}</Typography>
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                null
                                            }
                                            </Table>


                                        </TableContainer>
                                </Container>


                                {/* <Grid container spacing={1} maxWidth="xl"
                                    sx={{
                                        mt: isMatch ? '3em' : '5em',
                                        mb: isMatch ? '2em' : '4em',
                                        p: '1em',
                                        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Typography color="success" sx={{ pl: '7px', fontSize: '1.5em' }}>Details</Typography>
                                        <Divider sx={{ m: '10px' }} />
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 3}  >
                                        {
                                            isMatch ?
                                                <>
                                                    <Stack direction="row" spacing={1} sx={{ p: '10px' }}>
                                                        <Call />
                                                        <StyledMobTypography variant='body2' gutterBottom>Phone : </StyledMobTypography>
                                                        <StyledMobTypography sx={{ color: '#637381', }} variant='body2' gutterBottom> {card.phoneNumber}</StyledMobTypography>
                                                    </Stack>
                                                </>
                                                :
                                                <>
                                                    <Stack direction="row" spacing={1} sx={{ p: '1em' }}>
                                                        <Call />
                                                        <StyledTypography variant='body2' gutterBottom>Phone : </StyledTypography>
                                                        <StyledTypography sx={{ color: '#637381', }} variant='body2' gutterBottom> {card.phoneNumber}</StyledTypography>
                                                    </Stack>
                                                </>
                                        }


                                    </Grid>
                                    {isMatch ? null : <Divider orientation="vertical" flexItem sx={{ m: '10px' }} />}

                                    <Grid item xs={isMatch ? 12 : 6} sx={{ ml: isMatch ? null : '10px' }}>
                                        {isMatch ?
                                            <>
                                                <Stack direction="row" spacing={1} sx={{ p: '10px' }}>
                                                    <FmdGoodIcon sx={{ color: '#f50057' }} />
                                                    <StyledMobTypography variant='body2' gutterBottom>Address :</StyledMobTypography>
                                                    <StyledMobTypography sx={{ color: '#637381' }} variant='body2' gutterBottom> {card.address}</StyledMobTypography>
                                                </Stack>
                                            </>
                                            :
                                            <>
                                                <Stack direction="row" spacing={1} sx={{ p: '1em' }}>
                                                    <FmdGoodIcon sx={{ color: '#f50057' }} />
                                                    <StyledTypography variant='body2' gutterBottom>Address :</StyledTypography>
                                                    <StyledTypography sx={{ color: '#637381' }} variant='body2' gutterBottom> {card.address}</StyledTypography>
                                                </Stack>
                                            </>
                                        }


                                    </Grid>
                                    {isMatch ? null : <Divider orientation="vertical" flexItem sx={{ m: '10px' }} />}
                                    <Grid item xs={isMatch ? 12 : 2} sx={{}}>
                                        {isMatch ?
                                            <>
                                                <Stack direction="row" spacing={1} sx={{ p: '10px', }}>
                                                    <LocationCityOutlined />
                                                    <StyledMobTypography variant='body2' gutterBottom> City :</StyledMobTypography>
                                                    <StyledMobTypography sx={{ color: '#637381' }} variant='body2' gutterBottom> {card.city.title}</StyledMobTypography>
                                                </Stack>
                                            </>
                                            :
                                            <>
                                                <Stack direction="row" spacing={1} sx={{ p: isMatch ? '10px' : '1em' }}>
                                                    <LocationCityOutlined />
                                                    <StyledTypography variant='body2' gutterBottom> City :</StyledTypography>
                                                    <StyledTypography sx={{ color: '#637381' }} variant='body2' gutterBottom> {card.city.title}</StyledTypography>
                                                </Stack>
                                            </>
                                        }


                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 4} sx={{ mt: '10px' }} >
                                        {isMatch ?
                                            <>
                                                <Stack direction="row" spacing={1} sx={{ p: '10px' }}>
                                                    <Email />
                                                    <StyledMobTypography variant='body2' gutterBottom>Email :</StyledMobTypography>
                                                    <Link underline="none" href={`mailto:${card.emailId}`}>
                                                        <StyledTypography variant='body2' gutterBottom> {card.emailId}</StyledTypography>
                                                    </Link>
                                                </Stack>
                                            </>
                                            :
                                            <>
                                                <Stack direction="row" spacing={1} sx={{ p: '1em' }}>
                                                    <Email />
                                                    <StyledTypography variant='body2' gutterBottom>Email :</StyledTypography>
                                                    <Link underline="none" href={`mailto:${card.emailId}`}>
                                                        <StyledTypography variant='body2' gutterBottom> {card.emailId}</StyledTypography>
                                                    </Link>
                                                </Stack>
                                            </>
                                        }


                                    </Grid>
                                    {isMatch ? null : <Divider orientation="vertical" flexItem sx={{ m: '10px' }} />}
                                    <Grid item xs={isMatch ? 12 : 6} sx={{ mt: '10px', overflow: 'hidden' }} >
                                        {isMatch ?
                                            <>
                                                <Stack direction="row" spacing={1} sx={{ p: '10px', }}>
                                                    <AddLink />
                                                    <StyledMobTypography variant='body2' gutterBottom>Website :</StyledMobTypography>
                                                    <Link underline="none" href={card.website} target="_blank">
                                                        <StyledTypography variant='body2' gutterBottom> {card.website}</StyledTypography>
                                                    </Link>
                                                </Stack>
                                            </>
                                            :
                                            <>

                                                <Stack direction="row" spacing={1} sx={{ p: '1em' }}>
                                                    <AddLink />
                                                    <StyledTypography variant='body2' gutterBottom>Website :</StyledTypography>
                                                    <Link underline="none" href={card.website} target="_blank">
                                                        <StyledTypography variant='body2' gutterBottom> {card.website}</StyledTypography>
                                                    </Link>
                                                </Stack>

                                            </>
                                        }

                                    </Grid>
                                </Grid> */}

                                {/* <Divider sx={{ borderStyle: 'dashed', p: '1em' }} /> */}

                                <Divider sx={{ borderStyle: 'dashed', p: '1em' }} />
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{ ml: isMatch ? "11px" : '22px' }}>
                                        <CustomCard>
                                            <Typography color="success"
                                                sx={{
                                                    fontSize: '1.5em',
                                                    fontWeight: 500,
                                                    color: '#fff'
                                                }}
                                            >
                                                Description
                                            </Typography>
                                        </CustomCard>
                                    </Grid>
                                    <Grid item xs={isMatch ? 12 : 10} sx={{ ml: isMatch ? "11px" :'22px' }} align="start">
                                        <Typography
                                            sx={{
                                                mt: '1em',
                                                fontWeight: 400,
                                                fontSize: isMatch ? "16px" :'17px',
                                                lineHeight: '1.375',
                                                textDecoration: 'none',
                                            }}>
                                            {card.description}
                                        </Typography>
                                        <Typography color="success" sx={{ pt: '1em', fontSize: '1.2em' }}>{card.heading}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems1}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems2}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems3}</Typography>
                                        <Typography sx={{ mt: '1em' }}>{card.listItems4}</Typography>
                                    </Grid>
                                </Grid>



                            </Paper>

                        </>
                    )
                })
            }

        </>
    )
}
// how to place four mui box ?
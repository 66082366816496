import * as React from 'react';
import { Grid, Tab, Tabs, Typography, Box, Button, Autocomplete, TextField, Container, Paper, TextareaAutosize, IconButton, CircularProgress, LinearProgress, Select, FormControl, InputLabel, MenuItem } from '@mui/material'
import { styled, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import BusinessDataService from '../../../context/ClassifiedsDatabase';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { BrowseGalleryRounded, BurstModeRounded, DescriptionOutlined, DescriptionRounded, ImageAspectRatioOutlined, ImageRounded, Person, PersonOutlined, PersonRounded, PhotoCamera, } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import { storage } from "../../../firebase/firebase";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import slugify from 'react-slugify';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};


const StyledForm = styled('form')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));


const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: '1em'
}));


const StyledDiv = styled('div')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));

const TodayDate = new Date().toISOString().split('T')[0]

export default function ClassifiedsPannel({ id, setBusinessId }) {

    const [value, setValue] = React.useState(0);
    const [title, setTitle] = React.useState("");
    const [businessName, setBusinessName] = React.useState("");
    const [businessSlug, setBusinessSlug] = React.useState("");
    const [advertiserName, setAdvertiserName] = React.useState("");
    const [businessType, setBusinessType] = React.useState(null)
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [contactNumber, setContactNumber] = React.useState("");
    const [city, setCity] = React.useState(null);
    const [premium, setPremium] = React.useState(null);
    const [address, setAddress] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [shortDescription, setShortDescription] = React.useState("");
    const [expireDate, setExpireDate] = React.useState(new Date());
    const [releaseDate, setReleaseDate] = React.useState(new Date());
    const [agentProfile, setAgentProfile] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const [position, setPosition] = React.useState('')
    const [progress, setProgress] = React.useState(0);
    const [mainProgress, setMainProgress] = React.useState(0);

    const handleChange = () => {
        setBusinessSlug(slugify(businessName));
    };

    const handleChange1 = (event, newValue) => {
        setValue(newValue);
    };
    // console.log('slug', businessSlug);

    React.useEffect(() => {
        console.log("The id here is : ", id);
        if (id !== undefined && id !== "") {
            editHandler();
        }
    }, [id]);

    function convertToSlug(url) {
        return url
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            ;
    }

    const handleAgentProfile = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'Classified Images';
                const id = new Date().getTime();
                var storageRef = ref(storage, `${folderName}/${id + image.name}`)
                var uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setAgentProfile(oldState => ([...oldState, downloadURL]));
                        });
                    }
                )
            }
            task();
        }
    }

    const handleImages = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'Classified Gallery';
                const id = new Date().getTime();
                var storageRef = ref(storage, `${folderName}/${id + image.name}`)
                var uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on('state_changed', function (snapshot) {
                    const mainprogress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setMainProgress(mainprogress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setImages(oldState => ([...oldState, downloadURL]));
                        });
                    }
                )
            }
            task();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newBusiness = {
            businessName,
            businessSlug,
            advertiserName,
            businessType,
            phoneNumber,
            city,
            address,
            contactNumber,
            email,
            title,
            description,
            shortDescription,
            expireDate,
            website,
            agentProfile,
            premium,
            releaseDate,
            images,
        };
        console.log(newBusiness);

        try {
            if (window.confirm('Are you sure')) {
                if (id !== undefined && id !== "") {
                    await BusinessDataService.updateBusiness(id, newBusiness);
                    setBusinessId("");

                } else {
                    await BusinessDataService.addBusiness(newBusiness);

                }
            }

        } catch (err) {
            console.log("err", err)
        }
        setBusinessName("");
        setAdvertiserName("");
        setBusinessType("");
        setPhoneNumber("");
        setCity("");
        setPremium("");
        setBusinessSlug("")
        setAddress("");
        setContactNumber("");
        setEmail("");
        setTitle("");
        setWebsite("");
        setDescription("");
        setShortDescription("");
        setExpireDate("");
        setReleaseDate("");
        setProgress(0);
        setAgentProfile([]);
        setImages([]);
    };

    const editHandler = async () => {
        // setMessage("");
        try {
            const docSnap = await BusinessDataService.getBusiness(id);
            console.log("the record is :", docSnap.data());
            setBusinessName(docSnap.data().businessName);
            setBusinessSlug(docSnap.data().businessSlug);
            setAdvertiserName(docSnap.data().advertiserName);
            setBusinessType(docSnap.data().businessType);
            setPhoneNumber(docSnap.data().phoneNumber);
            setCity(docSnap.data().city);
            setPremium(docSnap.data().premium);
            setContactNumber(docSnap.data().contactNumber);
            setEmail(docSnap.data().email);
            setWebsite(docSnap.data().website);
            setTitle(docSnap.data().title);
            setAddress(docSnap.data().address);
            setDescription(docSnap.data().description);
            setShortDescription(docSnap.data().shortDescription);
            setExpireDate(docSnap.data().expireDate);
            setReleaseDate(docSnap.data().releaseDate);
            setAgentProfile(docSnap.data().agentProfile);
            setImages(docSnap.data().images);
        } catch (err) {
            console.log("err", err)
        }
    };


    const deleteImage = (ImageUrl, setter) => {
        let image = ref(storage, ImageUrl);
        let newUrl = agentProfile.filter(item => item !== ImageUrl);
        deleteObject(image).then(function () {
            console.log('image deleted');
            if (setter === setAgentProfile) {
                setAgentProfile(newUrl)
                setPosition('')
                BusinessDataService.updateBusiness(id, ({ agentProfile: newUrl }));
                setBusinessId("");
            }

        }).catch(function (error) {
            console.log('an error occurred');
            setPosition('')
        });
    }

    const deleteImages = (ImageUrl, setter) => {
        let image = ref(storage, ImageUrl);
        let newUrl = images.filter(item => item !== ImageUrl);
        deleteObject(image).then(function () {
            console.log('image deleted');
            if (setter === setImages) {
                setImages(newUrl)
                setPosition('')
                BusinessDataService.updateBusiness(id, ({ images: newUrl }));
                setBusinessId("");
            }

        }).catch(function (error) {
            console.log('an error occurred');
            setPosition('')
        });
    }



    return (
        <>
            <Grid xs={12}>
                <Typography variant="h5" align="center">Classifieds</Typography>
            </Grid>
            <StyledForm autoComplete="off" onSubmit={handleSubmit}>
                <Tabs orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange1}
                    aria-label="Vertical tabs example">
                    <Tab label="Details" icon={<PersonRounded />} {...a11yProps(0)} />
                    <Tab label="Images" icon={<ImageRounded />} {...a11yProps(1)} />
                    <Tab label="Gallery" icon={<BurstModeRounded />} {...a11yProps(1)} />
                    <Tab label="Description" icon={<DescriptionRounded />} {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0} style={{ maxWidth: '80%' }}>
                    <StyledDiv>
                        <StyledPaper>
                            <Container>
                                {/* <Typography variant="h5"> Testimonials</Typography> */}
                                <Grid container spacing={1}>
                                    <StyledGrid xs={6} align="start">
                                        <TextField
                                            label="Advertising Title"
                                            id="filled-size-small"
                                            variant="outlined"
                                            name="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6} align="start">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Is Premium Classified</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={premium}
                                                name='premium'
                                                label="Age"
                                                onChange={(e) => setPremium(e.target.value)}
                                            >
                                                <MenuItem value={'Both'}>Both</MenuItem>
                                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                                <MenuItem value={'No'}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </StyledGrid>
                                    <StyledGrid xs={6} align="start">
                                        <TextField
                                            label="Business Name"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="businessname"
                                            value={businessName}
                                            onChange={(e) => setBusinessName(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6} align="start">
                                        <TextField
                                            label="Contact Agent"
                                            id="filled-size-small"
                                            variant="outlined"
                                            name="advertiserName"
                                            value={advertiserName}
                                            onChange={(e) => setAdvertiserName(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <Autocomplete
                                            value={businessType}
                                            options={BusinesPages}
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, value) => setBusinessType(value)}
                                            id="auto-complete"
                                            autoComplete
                                            includeInputInList
                                            renderInput={(params) => (
                                                <TextField {...params} label="Category" variant="outlined" />
                                            )}
                                        />
                                    </StyledGrid>

                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Slug"
                                            id="filled-size-small"
                                            variant="outlined"
                                            name="businessSlug"
                                            value={businessSlug}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                        <Button onClick={handleChange}>Generate</Button>
                                    </StyledGrid>

                                    <StyledGrid xs={6}>
                                        <Autocomplete
                                            value={city}
                                            options={Cities}
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, value) => setCity(value)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="City" variant="outlined" fullWidth />
                                            )}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Phone Number"
                                            variant="outlined"
                                            id="filled-size-small"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            fullWidth
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Contact Number"
                                            variant="outlined"
                                            id="filled-size-small"
                                            name="contactNumber"
                                            value={contactNumber}
                                            fullWidth
                                            onChange={(e) => setContactNumber(e.target.value)}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Email Address"
                                            variant="outlined"
                                            id="filled-size-small"
                                            name="email"
                                            value={email}
                                            fullWidth
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Website Link"
                                            variant="outlined"
                                            id="filled-size-small"
                                            name="website"
                                            value={website}
                                            fullWidth
                                            onChange={(e) => setWebsite(e.target.value)}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            type='date'
                                            label="Release Date"
                                            defaultValue={TodayDate}
                                            id="filled-size-small"
                                            variant="outlined"
                                            name="releaseDate"
                                            value={releaseDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setReleaseDate(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                </Grid>
                            </Container>
                        </StyledPaper>
                    </StyledDiv>
                </TabPanel>
                <TabPanel value={value} index={1} style={{ width: '100%', height: '50%' }}>
                    <Grid container spacing={1}>
                        <StyledGrid xs={12}>
                            <Typography variant="h6"> Main Image</Typography>
                            <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                onChange={handleAgentProfile} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                            <div >
                                <Box width="50%" mr={3}>
                                    <LinearProgressWithLabel variant="determinate" value={progress}
                                    />
                                </Box>
                            </div>
                        </StyledGrid>
                        <StyledGrid xs={12}>
                            <div>
                                {(() => {
                                    if (agentProfile) {
                                        return (agentProfile.map((url, index) => (
                                            <div key={index}>
                                                <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                <Button variant="contained" onClick={() => { deleteImage(url, setAgentProfile); setPosition(index) }}>
                                                    Delete
                                                </Button>
                                                {position === index &&
                                                    <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                }
                                            </div>
                                        )))
                                    }
                                })()}
                            </div>
                        </StyledGrid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2} style={{ width: '100%', height: '50%' }}>
                    <Grid container spacing={1}>
                        <StyledGrid xs={12}>
                            <Typography variant="h6"> Gallery</Typography>
                            <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                onChange={handleImages} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                            <div >
                                <Box width="50%" mr={3}>
                                    <LinearProgressWithLabel variant="determinate" value={mainProgress}
                                    />
                                </Box>
                            </div>
                        </StyledGrid>
                        <StyledGrid xs={12}>
                            <div>
                                {(() => {
                                    if (images) {
                                        return (images.map((url, index) => (
                                            <div key={index}>
                                                <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                <Button variant="contained" onClick={() => { deleteImages(url, setImages); setMainProgress(index) }}>
                                                    Delete
                                                </Button>
                                                {position === index &&
                                                    <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                }
                                            </div>
                                        )))
                                    }
                                })()}
                            </div>
                        </StyledGrid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={3} style={{ width: '100%' }}>
                    <StyledGrid xs={12}>
                        <TextareaAutosize
                            style={{ width: '100%' }}
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Short Description"
                            value={shortDescription}
                            name="shortDescription"
                            onChange={(e) => setShortDescription(e.target.value)}
                            fullWidth
                        />
                    </StyledGrid>
                    <StyledGrid xs={12}>
                        <TextareaAutosize
                            style={{ width: '100%' }}
                            aria-label="minimum height"
                            minRows={10}
                            placeholder="Description"
                            value={description}
                            name="description"
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                        />
                    </StyledGrid>
                </TabPanel>
                <Button variant="contained" color="inherit" disableElevation type="submit" >
                    {id === '' ? "save" : "update"}
                </Button>
            </StyledForm>
        </>
    )
}





const BusinesPages = [
    { title: 'Alteration/Tailoring (Home based business)' },
    { title: 'Apartments/Condo for Rent (For Leasing Agents only)' },
    { title: 'Automobiles/Cars (Personal)' },
    { title: 'Beauty Salon/Henna Services (Home based Provided)' },
    { title: 'Books' },
    { title: 'Business for Sale' },
    { title: 'Cakes/Bakery (Home based business Provided)' },
    { title: 'Car Pool' },
    { title: 'Casting Call/Auditions' },
    { title: 'Catering/Food Delivery (Provided)' },
    { title: 'Clothing/Fashion (Home based business)' },
    { title: 'Cooking Service/Onsite Personal Chef (Provided)' },
    { title: 'Cooking Service/Personal Chef (Wanted)' },
    { title: 'Daycare Assistant Needed (Home based Daycares)' },
    { title: 'DayCare Service (Needed)' },
    { title: 'Daycare Service (Provided)' },
    { title: 'Elderly Care (Home based Provided)' },
    { title: 'Elderly Caregiver (Wanted)' },
    { title: 'Electronics & Computers' },
    { title: 'Enrichment Programs/Camps (Virtual)' },
    { title: 'Friends & Sports Partner' },
    { title: 'Furniture items' },
    { title: 'General Merchandise' },
    { title: 'Gift Cards/Tickets' },
    { title: 'Home and Garden' },
    { title: 'Home Decor Items (Home based business)' },
    { title: 'Home Made Food /Tiffin Services (Home based Provided)' },
    { title: 'Homemade Food / Tiffin Services(Wanted)' },
    { title: 'House for Sale by Realtor' },
    { title: 'House / Condo / Apartment for Rent by Owner' },
    { title: 'House / Condo / Apartment For Sale by Owner' },
    { title: 'Household help / Cooking / Cleaning(Provided)' },
    { title: 'Household help / Cooking / Cleaning(Wanted)' },
    { title: 'IT Jobs / IT Recruiters' },
    { title: 'IT Training / Placement' },
    { title: 'Jewelry / Accessories(Home based business)' },
    { title: 'Job Listings(corporations)' },
    { title: 'Kids Classes / Summer Camps(Home based Provided)' },
    { title: 'Lost and Found Pets' },
    { title: 'Music Classes(Provided)' },
    { title: 'Nanny / Babysitter(Wanted)' },
    { title: 'Nanny / Babysitter Service(Provided)' },
    { title: 'Paid Market Research' },
    { title: 'Preschool / After School Care(Provided)' },
    { title: 'Rakhi Sales' },
    { title: 'Rent / Sub Lease - Apartment / House' },
    { title: 'Retail / Office for Lease' },
    { title: 'Roommates' },
    { title: 'Services' },
    { title: 'Small Business Help / Job Listings' },
    { title: 'Toys / Games / Sports' },
    { title: 'Travel Companion' },
    { title: 'Tutoring / School(Home Based)' },
    { title: 'Tutoring / Test Prep / Camps(Provided)' },
    { title: 'CPA and Tax Advisors' },
    { title: 'Apartments' },
    { title: 'Attorneys' },
    { title: 'Dentist' },
    { title: 'Doctor' },
    { title: 'Indian Food' },
    { title: 'Decorations and Jeweleries' },
    { title: 'Photograpy' },
    { title: 'Indian Grocery Stores' },
    { title: 'Real Estate' },
    { title: 'Resturant' },
    { title: 'Consulate General' },
    { title: 'Temples' },
    { title: 'Gurudwara' },
    { title: 'Indian Movies' },
    { title: 'Immigration' },
    { title: 'Travel Companion' },
]

const Premium = [
    { title: 'None' },
    { title: 'Yes' },
    { title: 'No' },
]

const Cities = [
    { title: 'None' },
    { title: 'Seattle' },
    { title: 'Lake Stevens' },
    { title: 'Bellevue' },
    { title: 'Redmond' },
    { title: 'Kirkland' },
    { title: 'Bothell' },
    { title: 'Issaquah' },
    { title: 'Renton' },
    { title: 'Kent' },
    { title: 'Sammamish' },
    { title: 'Everett' },
    { title: 'Lynnwood' },
    { title: 'Tukwila' },
    { title: 'Federal Way' },
    { title: 'Seatac' },
    { title: 'Tacoma' },
    { title: 'Shoreline' },
    { title: 'Olympia' },
    { title: 'Burien' },
    { title: 'Mill Creek' },
    { title: 'Edmonds' },
    { title: 'Mercer Island' },
    { title: 'Snoqualmie' },
    { title: 'Auburn' },
    { title: 'Monroe' },
    { title: 'Mukilteo' },
]





import React from 'react'
import { useParams } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import { Avatar, Grid, Paper, Stack, Toolbar, Typography } from '@mui/material';
import banner22 from '../../images/bg1.jpg'
import { Box, Container } from '@mui/system';
import AgentDataService from '../../context/AgentDatabase';

const StyledPaper = styled(Box)(({ theme }) => ({
  borderRadius: 0,
  height: '200%',
  width: '97.4%',
  background: `linear-gradient( to bottom, '#fad7d7' 0, '#fad7d7' 32rem, '#fff' 32rem, '#fff' 100% )`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 25,
  paddingTop: 150,
  marginTop: '-1em',
  overflow: 'hidden',
}));

const StyledPaper1 = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  height: '100%',
  width: '89.9%',
  background: ` linear-gradient(
    rgb(197 192 192 / 7%), 
    rgb(0 0 0 / 28%)
  ),url(${banner22})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 75,
  paddingTop: 95,
  marginTop: '-1em',
  overflow: 'hidden',
}))

const StyledPaper2 = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  height: '100%',
  width: '97.4%',
  background: `#fad7d7`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 25,
  paddingTop: 95,
  marginTop: '-14em',
  overflow: 'hidden'
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  columnGap: '2.3rem',
  gridTemplateColumns: 'maxContent 1fr maxContent',
  textAlign: 'left',
  marginTop: '-9rem',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'ITC Clearface,serif',
  fontWeight: '600',
  lineBreak: 'strict'
}));

const StyledTypography1 = styled(Typography)(({ theme }) => ({
  fontFamily: 'ITC Clearface,serif',
  fontWeight: 'inherit',
  marginLeft: '5px',
  fontSize: '0.9rem'
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({

  width: '20.7rem',
  height: '20.7rem',
  marginTop: '1em',

  '	.MuiAvatar-img': {
    display: 'block',
    objectFit: 'cover',
    borderRadius: '50%',
    height: '60%',
    width: '60%',
  }

}));

export const AgentsDetailPage = () => {
  const [agentData, setAgentData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [age, setAge] = React.useState('');


  const handleChange = (event) => {
    setAge(event.target.value);
  };
  React.useEffect(() => {
    getAgents();
  }, []);

  const getAgents = async () => {
    const data = await AgentDataService.getAllAgent();
    console.log("agents", data.docs);
    setAgentData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  const { agentName } = useParams()
  return (
    <>
      <StyledPaper1 />
      <StyledContainer>
        {
          agentData.filter(item => item.agentName == agentName).map((item) => {
            return (
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <StyledAvatar alt="authorimage" src={item.agentProfile} />
                </Grid>
                <Grid item xs={6} sx={{ mt: '8em', ml: '5em' , p: '3px'}}>
                  <StyledTypography sx={{ fontSize: '1.8rem', color: 'rgba(12, 30, 53, 0.95)' }}>{item.agentName}</StyledTypography>
                  <StyledTypography sx={{ fontSize: '1.1rem', color: '#565656c9' }}>{item.businessName}</StyledTypography>
                  <Stack direction="row">
                    <Stack direction="row">
                      <StyledTypography sx={{ fontSize: '1rem', }}>Mobile :</StyledTypography>
                      <StyledTypography1 >{item.phoneNumber}</StyledTypography1>
                    </Stack>
                    <Stack direction="row" sx={{ ml: '2em' }}>
                      <StyledTypography sx={{ fontSize: '1rem', }}>Office :</StyledTypography>
                      <StyledTypography1 >{item.contactNumber}</StyledTypography1>
                    </Stack>
                  </Stack>
                  <Stack direction="row" sx={{ mb: '2em' }}>
                    <StyledTypography sx={{ fontSize: '1rem', }}>Email :</StyledTypography>
                    <StyledTypography1 >{item.emailId}</StyledTypography1>
                  </Stack>
                </Grid>

              </Grid>
            )
          })
        }
      </StyledContainer>
      <StyledPaper2 />
      <Box display={{ xs: 'none', md: 'block', }} sx={{ width: '100%', ml: '1em', background: '#fff' }} m={1}>
        <Grid container spacing={1} sx={{ p: '0px 10px 40px 30px' }}>
          {
            agentData.filter(item => item.agentName == agentName).map((item) => {
              return (
                <>
                  <Grid item xs={12} sx={{ m: '5em 0em 0em 5em' }} align="left">
                    <StyledTypography sx={{ fontSize: '1.8rem', color: '#012169' }}>About {item.agentName}</StyledTypography>
                  </Grid>
                  <Grid item xs={3} sx={{ mt: '2em', ml: '13em' }}>
                    <StyledTypography1 variant="button" display="block" gutterBottom sx={{ fontWeight: 'bold' }}>Bio</StyledTypography1>
                  </Grid>
                  <Grid item xs={8} sx={{ mt: '0em', ml: '13em' }}>
                    <StyledTypography1 variant="subtitle2" gutterBottom component="div" >{item.description}</StyledTypography1>
                  </Grid>
                </>
              )
            }
            )}

        </Grid>
      </Box>

    </>
  )
}

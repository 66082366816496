import React from 'react'
import ClassifiedsPannel from './ClassifiedsPannel'
import { Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled, alpha } from '@mui/material/styles';
import { getDatabase, onChildAdded, onValue, ref } from '@firebase/database';
import BusinessDataService from '../../../context/ClassifiedsDatabase';
import { DeleteForever, Edit, EditAttributes, Refresh } from '@mui/icons-material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#2979ff',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const ClassifiedsDataPannel = () => {
  const [business, setBusiness] = React.useState([]);
  const [businessId, setBusinessId] = React.useState("");

  React.useEffect(() => {
    getBusiness();
  }, []);

  const getBusiness = async () => {
    const data = await BusinessDataService.getAllBusiness();
    console.log("agents", data.docs);
    setBusiness(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      await BusinessDataService.deleteBusiness(id);
      getBusiness();
    }
  };

  const getBusinessIdHandler = async (id) => {
    setBusinessId(id);
  };
  return (
    <>
      <div style={{ marginTop: 10, background: 'beige', marginBottom: 20 }}>
        <Paper>
          <ClassifiedsPannel id={businessId} setBusinessId={setBusinessId} />
        </Paper>
        <Container maxWidth="xl" style={{ marginTop: '1em' }}>

          <TableContainer component={Paper} style={{ margin: 'auto', maxWidth: 1300, }}>
            <Table style={{ minWidth: 700, }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Advertiser Name</StyledTableCell>
                  <StyledTableCell>Business Name</StyledTableCell>
                  <StyledTableCell>Buisness Type</StyledTableCell>
                  <StyledTableCell> City</StyledTableCell>
                  <StyledTableCell> PhoneNumber</StyledTableCell>
                  <StyledTableCell> Profile</StyledTableCell>
                  <StyledTableCell align="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={getBusiness}
                      startIcon={<Refresh />}
                    >Refresh</Button>

                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {business.map((doc, index) => {
                  return (
                    <StyledTableRow key={doc.id}>
                      <StyledTableCell >{doc.advertiserName}</StyledTableCell>
                      <StyledTableCell align='left'>{doc.businessName}</StyledTableCell>
                      <StyledTableCell align='left'>{doc.businessType.title}</StyledTableCell>
                      <StyledTableCell align='left'>{doc.city.title}</StyledTableCell>
                      <StyledTableCell align='left'>{doc.phoneNumber}</StyledTableCell>
                      <StyledTableCell align='left'><img src={doc.agentProfile} alt="image_png" width="100" height="80" /></StyledTableCell>
                      {/* <StyledTableCell align="left"><img src={doc.agentProfile} alt="image_png" width="100" height="80" /></StyledTableCell> */}
                      <StyledTableCell align="right">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={(e) => deleteHandler(doc.id)}
                          startIcon={<DeleteForever />}
                        >

                        </Button>
                        <p></p>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ mt: '1em' }}
                          onClick={() => {
                            getBusinessIdHandler(doc.id)
                            console.log("id", (doc.id)); window.scrollTo({ top: 0, behavior: 'smooth' })
                          }}
                          startIcon={<Edit />
                          }></Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  )

                })}
              </TableBody>
            </Table>

          </TableContainer>
        </Container>
      </div>
    </>
  )
}

export default ClassifiedsDataPannel
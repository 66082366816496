import React from 'react'
import { AppBar, Box, Button, Divider, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Stack, SwipeableDrawer, Tab, Tabs, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import Logo from '../images/home/1.png'
import Logo1 from '../images/home/4.png'
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { auth } from "../firebase/firebase"
import Drawers from './Sidebar/Drawer'
import { AbcOutlined, ApartmentOutlined, CallMadeOutlined, ChevronLeftOutlined, ChevronRightOutlined, ClassOutlined, ConnectWithoutContactOutlined, ExpandLess, ExpandMore, FoodBankOutlined, Home, HomeMaxOutlined, HomeOutlined, LocalActivityOutlined, LocationCityOutlined, NoteAltOutlined } from '@mui/icons-material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import slugify from 'react-slugify'
import useScrollTrigger from '@mui/material/useScrollTrigger';

const drawerWidth = 240;
const navItems = ["Classified", "Food", "About", "City", "Contact US"]

const StyledTabs = styled(Tabs)(({ theme }) => ({
  background: 'inherit',

  '& .Mui-selected': {
    background: 'inherit',
    color: '#000'
  }
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  color: 'black',
  fontSize: '1.3em',
  fontFamily: 'ITC Clearface,serif',
  fontWeight: '500'
}))

const CustomButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  fontSize: '1.3em',
  fontFamily: 'ITC Clearface,serif',
  fontWeight: '500',
  textTransform: 'capitalize',
  margin: '0px 10px'
}))
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'orange',
          height: 10,
        },
      },
    },
  }
})




const Navbar = () => {
  // const [photo, setPhoto] = React.useState(1);
  const theme = useTheme();
  const Anchor = 'top';
  const [user, setUser] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState('0');
  const [value1, setValue1] = React.useState('0');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useNavigate();
  const match = window.location.pathname.split('/')[1] === '' ? true : false;
  const isMatch = useMediaQuery(theme.breakpoints.down("md"))



  const handleChange1 = (e, value) => {
    setValue1(value);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };;

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledTab = styled(Tab)(({ theme }) => ({
    color: 'black',
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 600,
    width: "auto",
    marginLeft: isMatch ? null : '1em'
  }))

  const StyledTab1 = styled(Tab)(({ theme }) => ({
    color: 'black',
    textTransform: "none",
    fontSize: "1.3rem",
    fontWeight: 500,
    width: "auto",
    textAlign: 'start',
    ml: '-20px',
    mt: '-14px'
  }))

  function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 4,
    });
  }

  React.useEffect(() => {

    var user = auth.currentUser;
    if (user !== null) {
      setUser(true);
    }
    auth.onAuthStateChanged(function (user) {
      if (user) {
        console.log('This is the user: ', user)
        setUser(true);
      }
    }
    )

    if (window.location.pathname === "/" && value !== 0) {
      setValue(0)
    } else
      if (window.location.pathname === "/indian-food" && value !== 1) {
        setValue(1)
      } else
        if (window.location.pathname === "/indian-grocery-stores" && value !== 2) {
          setValue(2)
        } else
          if (window.location.pathname === "/decorations-and-jeweleries" && value !== 3) {
            setValue(3)
          } else
            if (window.location.pathname === "/classified" && value !== 4) {
              setValue(4)
            } else
              if (window.location.pathname === "/events" && value !== 5) {
                setValue(5)
              }
              else
                if (window.location.pathname === "/contact-us" && value !== 6) {
                  setValue(6)
                }

  }, []);

  function Logout() {
    auth.signOut();
    setUser(false)
    history("/");
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Logo
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  return (
    <>
      {
        isMatch ?
          <AppBar sx={{ background: '#fff', }}>
            <Toolbar sx={{ pl: isMatch ? '0px' : null, justifyContent: 'unset' }}>
              {/* <Typography
                variant="button"
                component="div"
                sx={{ color: 'black', flexGrow: 1, display: { xs: 'none', sm: 'block' }, fontFamily: 'ITC Clearface,serif' }}
                onClick={() => history(`/`)}
              >
                Logo
              </Typography> */}
              <Box sx={{ width: '106%', bgcolor: 'background.paper' }}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  aria-label="scrollable auto tabs example"
                  textColor="inherit"
                  indicatorColor='none'
                  sx={{ ml: '0em', color: 'red' }}
                >
                  <StyledTab sx={{ minHeight: '55px' }} icon={<HomeOutlined />} iconPosition="start" disableRipple label="Home" onClick={() => history(`/`)} />
                  <StyledTab sx={{ minHeight: '55px' }} icon={<FoodBankOutlined />} iconPosition="start" disableRipple label="Indian Food" onClick={() => history(`/indian-food`)} />
                  <StyledTab sx={{ minHeight: '55px' }} icon={<ApartmentOutlined />} iconPosition="start" disableRipple label="Grocery Stores" onClick={() => history(`/indian-grocery-stores`)} />
                  <StyledTab sx={{ minHeight: '55px' }} icon={<LocationCityOutlined />} iconPosition="start" disableRipple label="Decoration and Jewelry" onClick={() => history(`/decorations-and-jeweleries`)} />
                  <StyledTab sx={{ minHeight: '55px' }} icon={<ClassOutlined />} iconPosition="start" disableRipple label="Classified" onClick={() => history(`/classified`)} />
                  <StyledTab sx={{ minHeight: '55px' }} icon={<LocalActivityOutlined />} iconPosition="start" disableRipple label="Events" onClick={() => history(`/events`)} />
                  <StyledTab sx={{ minHeight: '55px' }} icon={<ConnectWithoutContactOutlined />} iconPosition="start" disableRipple label="Contact us" onClick={() => history(`/contact-us`)} />

                </StyledTabs>
              </Box>
            </Toolbar>
          </AppBar>
          :
          <ElevationScroll >
            <AppBar sx={{ background: '#fff', }}>
              <Toolbar sx={{ pl: isMatch ? '0px' : null, justifyContent: 'center' }}>
                {/* <Link
                  href="/"
                  underline='none'
                  sx={{ color: 'black', flexDirection: 'column' }}
                >
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="none"
                    aria-label="secondary tabs example"
                    sx={{ color: 'white', ml: '3em' }}

                  >
                    <img style={{ width: '100px', height: '70px', marginTop: '1px', marginBottom: '-5px' }} src={Logo} alt="Apne Indians" />

                  </StyledTabs>

                </Link> */}
                <Box >
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="none"
                    aria-label="secondary tabs example"
                    sx={{ color: 'white', }}

                  >
                    <StyledTab label="Home" disableRipple onClick={() => history(`/`)} />
                    <StyledTab label="Indian Food" disableRipple onClick={() => history(`/indian-food`)} />
                    <StyledTab label="Grocery Stores" onClick={() => history(`/indian-grocery-stores`)} />
                    <StyledTab label="Decoration and Jewellery" disableRipple onClick={() => history(`/decorations-and-jeweleries`)} />
                    <StyledTab label="Classified" disableRipple onClick={() => history(`/classified`)} />
                    <StyledTab label="Events" disableRipple onClick={() => history(`/events`)} />
                    <StyledTab label="Contact us" disableRipple onClick={() => history(`/contact-us`)} />
                    {user ?
                      <Button variant="contained" color="success" onClick={Logout} sx={{ minWidth: 120, height: 50, fontSize: '1em', }}>
                        Log Out
                      </Button>
                      :
                      null}
                  </StyledTabs>
                </Box>
              </Toolbar>
            </AppBar>
          </ElevationScroll>
      }

    </>
  )
}

export default Navbar



// how to add google map in reactjs?

import { db } from '../firebase/firebase'
import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
  } from "firebase/firestore";
  

  const businessCollectionRef = collection(db, "Business")


  class BusinessDataService {

    addBusiness = (newBusiness) => {
      return addDoc(businessCollectionRef, newBusiness);
    };
  
    updateBusiness = (id, updatedBusiness) => {
      const businessDoc = doc(db, "Business", id);
      return updateDoc(businessDoc, updatedBusiness);
    };
  
    deleteBusiness = (id) => {
      const businessDoc = doc(db, "Business", id);
      return deleteDoc(businessDoc);
    };
  
    getAllBusiness = () => {
      return getDocs(businessCollectionRef);
    };
  
    getBusiness = (id) => {
      const businessDoc = doc(db, "Business", id);
      return getDoc(businessDoc);
    };
  }
  
  export default new BusinessDataService();
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Tab, Tabs, Typography, Box, TextField, Grid, Button, TextareaAutosize, IconButton, CircularProgress, Autocomplete, Container, Paper, Stack, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Switch, Checkbox, ListItemText, OutlinedInput, Divider } from '@mui/material';
import { AccountBox, Add, ArtTrack, BrowseGallery, BrowseGalleryOutlined, Collections, ConfirmationNumber, Description, MapOutlined, Person, PersonAddAlt, PhotoCamera } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { storage } from "../../firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { update, child, push, refFromURL } from "firebase/database";
import { database } from '../../firebase/firebase';
import ShortUniqueId from 'short-unique-id';
import AgentDataService from '../../context/AgentDatabase';
import slugify from 'react-slugify';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};
const StyledForm = styled('form')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));


const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: '1em'
}));


const StyledDiv = styled('div')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    maxWidth: 1000,
    paddingBottom: 20,
}));

const Item = styled(Paper)(({ theme }) => ({
    background: `linear-gradient(to right, #f3904f, #3b4371)`,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: 25,
    color: 'white',
    // fontFamily: 'initial',
}));

const AgentPannel = ({ id, setAgentId }) => {

    const initialValues = {
        businessname: '',
        agentname: '',
        phonenumber: '',
        contactnumber: '',
        description: '',
        address: '',
        agentProfile: '',
    }

    const [values, setValues] = React.useState(initialValues);
    const [title, setTitle] = React.useState("");
    const [businessName, setBusinessName] = React.useState("");
    const [businessType, setBusinessType] = React.useState(null)
    const [categorySlug, setCategorySlug] = React.useState("");
    const [agentSlug, setAgentSlug] = React.useState("");
    const [agentName, setAgentName] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [contactNumber, setContactNumber] = React.useState("");
    const [emailId, setEmailId] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [shortdescription, setShortDescription] = React.useState("");
    const [heading, setHeading] = React.useState("");
    const [date, setDate] = React.useState("");
    const [listItems1, setListItems1] = React.useState("");
    const [listItems2, setListItems2] = React.useState("");
    const [listItems3, setListItems3] = React.useState("");
    const [listItems4, setListItems4] = React.useState("");
    const [ticketRange, setTicketRange] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [city, setCity] = React.useState(null);
    const [releaseDate, setReleaseDate] = React.useState(new Date());
    const [agentProfile, setAgentProfile] = React.useState([]);
    const [mainImage, setMainImage] = React.useState([]);
    const [bgImage, setBgImage] = React.useState([]);
    const [orgProfile, setOrgProfile] = React.useState([]);
    const [gallery, setGallery] = React.useState([]);
    const [position, setPosition] = React.useState('')
    const [progress, setProgress] = React.useState(0);
    const [mainProgress, setMainProgress] = React.useState(0);
    const [bgProgress, setBgProgress] = React.useState(0);
    const [OrgProgress, setOrgProgress] = React.useState(0);
    const [galleryProgress, setGalleryProgress] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const [isChecked, setIsChecked] = React.useState(false);
    const [selectedLanguages, setSelectedLanguages] = React.useState([]);
    const [selectedGenres, setSelectedGenres] = React.useState([]);
    const initialField = { id: 1, Tickets: '', ticketDate: '', availability: '' };
    const initialField2 = { id: 1, concertDate: '', concertCity: '', concertAddress: '', concertTimings: '' };
    const [fields, setFields] = React.useState([initialField]);
    const [concert, setConcert] = React.useState([initialField]);
    const nextId = fields.length + 1;

    const nextId2 = concert.length + 1;

    const handleAddFields = () => {
        const newField = { ...initialField, id: nextId };
        setFields([...fields, newField]);
    };

    const handleFieldChange = (id, field, newValue) => {
        const updatedFields = fields.map(item =>
            item.id === id ? { ...item, [field]: newValue } : item
        );
        setFields(updatedFields);
    };

    const handleAddConcerts = () => {
        const newField = { ...initialField2, id: nextId2 };
        setConcert([...concert, newField]);
    };

    const handleConcertChange = (id, field, newValue) => {
        const updatedFields = concert.map(item =>
            item.id === id ? { ...item, [field]: newValue } : item
        );
        setConcert(updatedFields);
    };

    const handleSwitchChange = () => {
        setIsChecked((prevChecked) => !prevChecked);
    };

    const handleLanguageChange = (event) => {
        setSelectedLanguages(event.target.value);
    };

    const handleGenreChange = (event) => {
        setSelectedGenres(event.target.value);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange1 = () => {
        setAgentSlug(slugify(businessName));
    };

    const handleChange2 = () => {
        setCategorySlug(slugify(businessType.title));
    };



    const handleAgentProfile = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'AgentProfile';
                const id = new Date().getTime();
                var storageRef = ref(storage, `${folderName}/${id + image.name}`)
                var uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setAgentProfile(oldState => ([...oldState, downloadURL]));
                        });
                    }
                )
            }
            task();
        }
    }
    const handleMainImage = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'Main Image';
                const id = new Date().getTime();
                var storageRef = ref(storage, `${folderName}/${id + image.name}`)
                var uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setMainProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setMainImage(oldState => ([...oldState, downloadURL]));
                        });
                    }
                )
            }
            task();
        }
    }

    const handleBgImage = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'BackGround Images';
                const id = new Date().getTime();
                var storageRef = ref(storage, `${folderName}/${id + image.name}`)
                var uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setBgProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setBgImage(oldState => ([...oldState, downloadURL]));
                        });
                    }
                )
            }
            task();
        }
    }

    const handleOrgProfile = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'Organzation Profile';
                const id = new Date().getTime();
                var storageRef = ref(storage, `${folderName}/${id + image.name}`)
                var uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setOrgProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setOrgProfile(oldState => ([...oldState, downloadURL]));
                        });
                    }
                )
            }
            task();
        }
    }

    const handleGallery = async (e) => {
        if (e.target.files) {
            async function task() {
                const image = e.target.files[0];
                let folderName = 'Gallery';
                const id = new Date().getTime();
                var storageRef = ref(storage, `${folderName}/${id + image.name}`)
                var uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on('state_changed', function (snapshot) {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setGalleryProgress(progress);
                }, function (error) {
                    console.log(error);
                },
                    async filename => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setGallery(oldState => ([...oldState, downloadURL]));
                        });
                    }
                )
            }
            task();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newAgent = {
            businessName,
            businessType,
            agentName,
            phoneNumber,
            contactNumber,
            shortdescription,
            description,
            heading,
            listItems1,
            listItems2,
            listItems3,
            listItems4,
            address,
            agentProfile,
            mainImage,
            bgImage,
            orgProfile,
            gallery,
            selectedLanguages,
            selectedGenres,
            emailId,
            website,
            city,
            agentSlug,
            categorySlug,
            title,
            releaseDate,
            fields,
            concert,
            ticketRange,
            isChecked,
            date
        };
        console.log(newAgent);

        try {
            if (window.confirm('Are you sure')) {
                if (id !== undefined && id !== "") {
                    await AgentDataService.updateAgent(id, newAgent);
                    setAgentId("");

                } else {
                    await AgentDataService.addAgent(newAgent);

                }
            }

        } catch (err) {
            console.log("err", err)
        }
        setIsChecked("");
        setBusinessName("");
        setTitle("");
        setAgentName("");
        setBusinessType("");
        setContactNumber("");
        setPhoneNumber("");
        setDescription("");
        setHeading("");
        setListItems1("");
        setListItems2("");
        setListItems3("");
        setListItems4("");
        setShortDescription("");
        setAddress("");
        setEmailId("");
        setWebsite("");
        setCity("");
        setAgentSlug("");
        setReleaseDate("");
        setDate("");
        setCategorySlug("");
        setTicketRange("");
        setSelectedGenres([]);
        setSelectedLanguages([]);
        setFields([]);
        setConcert([]);
        setProgress(0);
        setAgentProfile([]);
        setMainProgress(0);
        setMainImage([]);
        setBgProgress(0);
        setBgImage([]);
        setOrgProgress(0);
        setOrgProfile([]);
        setGalleryProgress(0);
        setGallery([]);
    };

    const editHandler = async () => {
        // setMessage("");
        try {
            const docSnap = await AgentDataService.getAgent(id);
            console.log("the record is :", docSnap.data());
            setBusinessName(docSnap.data().businessName);
            setTitle(docSnap.data().title);
            setBusinessType(docSnap.data().businessType);
            setAgentSlug(docSnap.data().agentSlug);
            setCategorySlug(docSnap.data().categorySlug);
            setAgentName(docSnap.data().agentName);
            setContactNumber(docSnap.data().contactNumber);
            setPhoneNumber(docSnap.data().phoneNumber);
            setDescription(docSnap.data().description);
            setHeading(docSnap.data().heading);
            setListItems1(docSnap.data().listItems1);
            setListItems2(docSnap.data().listItems2);
            setListItems3(docSnap.data().listItems3);
            setListItems4(docSnap.data().listItems4);
            setShortDescription(docSnap.data().shortdescription);
            setIsChecked(docSnap.data().isChecked)
            setAddress(docSnap.data().address);
            setCity(docSnap.data().city);
            setTicketRange(docSnap.data().ticketRange);
            setSelectedGenres(docSnap.data().selectedGenres);
            setSelectedLanguages(docSnap.data().selectedLanguages);
            setFields(docSnap.data().fields);
            setConcert(docSnap.data().concert);
            setReleaseDate(docSnap.data().releaseDate);
            setDate(docSnap.data().date);
            setEmailId(docSnap.data().emailId);
            setWebsite(docSnap.data().website);
            setAgentProfile(docSnap.data().agentProfile);
            setMainImage(docSnap.data().mainImage);
            setBgImage(docSnap.data().bgImage);
            setOrgProfile(docSnap.data().orgProfile);
            setGallery(docSnap.data().gallery);
        } catch (err) {
            console.log("err", err)
        }
    };




    const deleteImage = (ImageUrl, setter) => {
        let image = ref(storage, ImageUrl);
        let newUrl = agentProfile.filter(item => item !== ImageUrl);
        deleteObject(image).then(function () {
            console.log('image deleted');
            if (setter === setAgentProfile) {
                setAgentProfile(newUrl)
                setPosition('')
                AgentDataService.updateAgent(id, ({ agentProfile: newUrl }));
                setAgentId("");
            }

        }).catch(function (error) {
            console.log('an error occurred');
            setPosition('')
        });
    }

    const deleteMainImage = (ImageUrl, setter) => {
        let image = ref(storage, ImageUrl);
        let newUrl = mainImage.filter(item => item !== ImageUrl);
        deleteObject(image).then(function () {
            console.log('image deleted');
            if (setter === setMainImage) {
                setMainImage(newUrl)
                setPosition('')
                AgentDataService.updateAgent(id, ({ mainImage: newUrl }));
                setAgentId("");
            }

        }).catch(function (error) {
            console.log('an error occurred');
            setPosition('')
        });
    }

    const deleteBgImage = (ImageUrl, setter) => {
        let image = ref(storage, ImageUrl);
        let newUrl = bgImage.filter(item => item !== ImageUrl);
        deleteObject(image).then(function () {
            console.log('image deleted');
            if (setter === setBgImage) {
                setBgImage(newUrl)
                setPosition('')
                AgentDataService.updateAgent(id, ({ bgImage: newUrl }));
                setAgentId("");
            }

        }).catch(function (error) {
            console.log('an error occurred');
            setPosition('')
        });
    }

    const deleteorgProfile = (ImageUrl, setter) => {
        let image = ref(storage, ImageUrl);
        let newUrl = orgProfile.filter(item => item !== ImageUrl);
        deleteObject(image).then(function () {
            console.log('image deleted');
            if (setter === setOrgProfile) {
                setOrgProfile(newUrl)
                setPosition('')
                AgentDataService.updateAgent(id, ({ bgImage: newUrl }));
                setAgentId("");
            }

        }).catch(function (error) {
            console.log('an error occurred');
            setPosition('')
        });
    }

    const deleteGallery = (ImageUrl, setter) => {
        let image = ref(storage, ImageUrl);
        let newUrl = gallery.filter(item => item !== ImageUrl);
        deleteObject(image).then(function () {
            console.log('image deleted');
            if (setter === setGallery) {
                setGallery(newUrl)
                setPosition('')
                AgentDataService.updateAgent(id, ({ bgImage: newUrl }));
                setAgentId("");
            }

        }).catch(function (error) {
            console.log('an error occurred');
            setPosition('')
        });
    }
    // function submit() {
    //     setAgentProfile('')
    // }
    React.useEffect(() => {
        console.log("The id here is : ", id);
        if (id !== undefined && id !== "") {
            editHandler();
        }
    }, [id]);

    return (
        <>
            <Stack sx={{ p: '3em 17em' }}>
                <Item> Business Details</Item>

            </Stack>
            <StyledForm autoComplete="off" onSubmit={handleSubmit}>
                {
                    isChecked === true ?
                        <Tabs orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example">
                            <Tab label="Basic Details" icon={<Person />} {...a11yProps(0)} />
                            <Tab label="Artist Info" icon={<ArtTrack />} {...a11yProps(1)} />
                            <Tab label="Tickets Details" icon={<ConfirmationNumber />} {...a11yProps(2)} />
                            <Tab label="Main Image" icon={<BrowseGalleryOutlined />} {...a11yProps(3)} />
                            <Tab label="Artist Profile" icon={<AccountBox />} {...a11yProps(4)} />
                            <Tab label="Gallery" icon={<Collections />} {...a11yProps(5)} />
                            <Tab label="Map" icon={<MapOutlined />} {...a11yProps(6)} />
                        </Tabs>
                        :
                        <Tabs orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example">
                            <Tab label="Basic Details" icon={<Person />} {...a11yProps(0)} />
                            <Tab label="Description" icon={<Description />} {...a11yProps(1)} />
                            <Tab label="Main Image" icon={<MapOutlined />} {...a11yProps(2)} />
                            <Tab label="Images" icon={<Collections />} {...a11yProps(3)} />
                            {/* <Tab label="Tickets Details(for Events only)" icon={<ConfirmationNumber />} {...a11yProps(4)} /> */}
                        </Tabs>
                }
                <TabPanel value={value} index={0} style={{ maxWidth: '80%' }}>
                    <FormControlLabel
                        required
                        control={<Switch checked={isChecked} onChange={handleSwitchChange} />}
                        label="Events"
                    />
                    <StyledDiv>
                        <StyledPaper>
                            <Container>
                                <Grid container spacing={1}>
                                    <StyledGrid xs={6} align="start">
                                        <TextField
                                            label={isChecked ? "Organizer Name" : "Advertising Title"}
                                            id="filled-size-small"
                                            variant="outlined"
                                            name="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label={isChecked ? "Advertising Title" : "Business Name"}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="businessname"
                                            value={businessName}
                                            onChange={(e) => setBusinessName(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="URL"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="agentSlug"
                                            value={agentSlug}
                                            onChange={handleChange1}
                                            fullWidth
                                        />
                                        <Button onClick={handleChange1}>Generate</Button>
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <Autocomplete
                                            value={businessType}
                                            options={BusinesPages}
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, value) => setBusinessType(value)}
                                            id="auto-complete"
                                            autoComplete
                                            includeInputInList
                                            renderInput={(params) => (
                                                <TextField {...params} label="Category" variant="outlined" />
                                            )}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="URL 1"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="categorySlugSlug"
                                            value={categorySlug}
                                            onChange={handleChange2}
                                            fullWidth
                                        />
                                        <Button onClick={handleChange2}>Generate</Button>
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label={isChecked ? "Artist Name" : "Agent Name"}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="agentname"
                                            value={agentName}
                                            onChange={(e) => setAgentName(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Contact Number"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="contactnumber"
                                            value={contactNumber}
                                            onChange={(e) => setContactNumber(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Phone Number"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="phonenumber"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <Autocomplete
                                            value={city}
                                            options={Cities}
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, value) => setCity(value)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="City" variant="outlined" fullWidth />
                                            )}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Email Address"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="emailId"
                                            value={emailId}
                                            onChange={(e) => setEmailId(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            label="Date"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            placeholder='mm-dd-yyyy'
                                            name="date"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={6}>
                                        <TextField
                                            type='date'
                                            label="Release Date"
                                            id="filled-size-small"
                                            variant="outlined"
                                            name="releaseDate"
                                            value={releaseDate}
                                            onChange={(e) => setReleaseDate(e.target.value)}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={12}>
                                        <TextField
                                            label={isChecked ? "Buy Tickets Link" : "Website"}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="website"
                                            value={website}
                                            onChange={(e) => setWebsite(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                    <StyledGrid xs={12}>
                                        <TextField
                                            label="Address"
                                            placeholder={isChecked ? 'mmm-yyyy + Address' : null}
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="address"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            fullWidth
                                        />
                                    </StyledGrid>
                                </Grid>
                            </Container>
                        </StyledPaper>
                    </StyledDiv>
                </TabPanel>
                {isChecked === true ?
                    <TabPanel value={value} index={1} style={{ width: '100%' }}>
                        <Grid container spacing={1} justifyContent="center" flexDirection="row">
                            <StyledGrid xs={12}>
                                <Typography variant="h6"> Organizer Profile</Typography>
                                <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                    onChange={handleOrgProfile} />
                                <label htmlFor="icon-button-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                                <div >
                                    <Box width="50%" mr={3}>
                                        <LinearProgressWithLabel variant="determinate" value={OrgProgress}
                                        />
                                    </Box>
                                </div>
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <div>
                                    {(() => {
                                        if (orgProfile) {
                                            return (orgProfile.map((url, index) => (
                                                <div key={index}>
                                                    <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                    <Button variant="contained" onClick={() => { deleteorgProfile(url, setOrgProfile); setPosition(index) }}>
                                                        Delete
                                                    </Button>
                                                    {position === index &&
                                                        <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                    }
                                                </div>
                                            )))
                                        }
                                    })()}
                                </div>
                                <Divider sx={{ mt: '2em' }} />
                            </StyledGrid>
                        </Grid>
                        <Grid container spacing={1} justifyContent="center" flexDirection="row">
                            <Grid item xs={12}><Typography variant="h6"> Concert info</Typography></Grid>
                            <Grid item xs={6} align="start">
                                <FormControl sx={{ width: '80%' }}>
                                    <InputLabel>Languages</InputLabel>
                                    <Select
                                        multiple
                                        input={<OutlinedInput label="Languages" />}
                                        value={selectedLanguages}
                                        onChange={handleLanguageChange}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {languages.map((language) => (
                                            <MenuItem key={language} value={language}>
                                                <Checkbox checked={selectedLanguages.includes(language)} />
                                                <ListItemText primary={language} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} align="start">
                                <FormControl sx={{ width: '80%' }}>
                                    <InputLabel>Genres</InputLabel>
                                    <Select
                                        multiple
                                        input={<OutlinedInput label="Genres" />}
                                        value={selectedGenres}
                                        onChange={handleGenreChange}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {genres.map((genre) => (
                                            <MenuItem key={genre} value={genre}>
                                                <Checkbox checked={selectedGenres.includes(genre)} />
                                                <ListItemText primary={genre} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {concert.map((field) => {
                                return (
                                    <React.Fragment key={field.id}>
                                        <Grid item xs={3}>
                                            <TextField
                                                label={`Concert Date ${field.id}`}
                                                placeholder='dd-mmm'
                                                value={field.concertDate}
                                                onChange={e => handleConcertChange(field.id, 'concertDate', e.target.value)}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Concert City"
                                                value={field.concertCity}
                                                onChange={e => handleConcertChange(field.id, 'concertCity', e.target.value)}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Concert Timings"
                                                value={field.concertTimings}
                                                onChange={e => handleConcertChange(field.id, 'concertTimings', e.target.value)}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Concert Address"
                                                value={field.concertAddress}
                                                onChange={e => handleConcertChange(field.id, 'concertAddress', e.target.value)}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            )}
                        </Grid>
                        <Grid item >
                            <Button onClick={handleAddConcerts} variant="contained" color="primary" sx={{ mt: '1.5em' }}>
                                Add Fields
                            </Button>
                        </Grid>
                    </TabPanel>
                    :
                    <TabPanel value={value} index={1} style={{ width: '100%' }}>
                        <StyledGrid xs={12}>
                            <TextareaAutosize
                                style={{ width: '100%' }}
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Short Description"
                                value={shortdescription}
                                name="shortdescription"
                                onChange={(e) => setShortDescription(e.target.value)}
                                fullWidth
                            />
                        </StyledGrid>
                        {/* <StyledGrid xs={12}>
                        <TextareaAutosize
                            style={{ width: '100%' }}
                            aria-label="minimum height"
                            minRows={10}
                            placeholder="Description"
                            value={description}
                            name="description"
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                        />
                    </StyledGrid> */}
                        <Paper elevation={1}>
                            <Typography variant='h5' sx={{ p: '1em' }}>Description</Typography>
                            <StyledGrid xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={5}
                                    placeholder="Description"
                                    value={description}
                                    name="description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={2}
                                    placeholder="Heading"
                                    value={heading}
                                    name="heading"
                                    onChange={(e) => setHeading(e.target.value)}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={2}
                                    placeholder="List 1"
                                    value={listItems1}
                                    name="listItems1"
                                    onChange={(e) => setListItems1(e.target.value)}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={2}
                                    placeholder="List 2"
                                    value={listItems2}
                                    name="listItems2"
                                    onChange={(e) => setListItems2(e.target.value)}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={2}
                                    placeholder="List 3"
                                    value={listItems3}
                                    name="listItems3"
                                    onChange={(e) => setListItems3(e.target.value)}
                                    fullWidth
                                />
                            </StyledGrid>
                            <StyledGrid xs={12}>
                                <TextareaAutosize
                                    style={{ width: '100%' }}
                                    aria-label="minimum height"
                                    minRows={2}
                                    placeholder="List 4"
                                    value={listItems4}
                                    name="listItems4"
                                    onChange={(e) => setListItems4(e.target.value)}
                                    fullWidth
                                />
                            </StyledGrid>

                        </Paper>
                    </TabPanel>}
                <TabPanel value={value} index={2} style={{ width: '100%', height: '50%' }}>
                    {
                        isChecked == true ?
                            <>
                                <Grid container spacing={1} justifyContent="center" flexDirection="row">
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Ticket Range"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            name="ticketRange"
                                            value={ticketRange}
                                            onChange={(e) => setTicketRange(e.target.value)}
                                        />
                                    </Grid>
                                    {fields.map((field) => {
                                        return (
                                            <React.Fragment key={field.id}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label={`Level ${field.id}`}
                                                        value={field.Tickets}
                                                        onChange={e => handleFieldChange(field.id, 'Tickets', e.target.value)}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="Event Date"
                                                        placeholder='mmm-dd'
                                                        value={field.ticketDate}
                                                        onChange={e => handleFieldChange(field.id, 'ticketDate', e.target.value)}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth sx={{ mt: '1em' }}>
                                                        <InputLabel id="demo-simple-select-label">Availability</InputLabel>
                                                        <Select
                                                            label="Availability"
                                                            value={field.availability}
                                                            onChange={e => handleFieldChange(field.id, 'availability', e.target.value)}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Selling Fast">Selling Fast</MenuItem>
                                                            <MenuItem value="Available">Available</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                    )}
                                </Grid>
                                <Grid item >
                                    <Button onClick={handleAddFields} variant="contained" color="primary" sx={{ mt: '1.5em' }}>
                                        Add Fields
                                    </Button>
                                </Grid>
                            </>
                            :
                            <Grid container spacing={1}>
                                <StyledGrid xs={12}>
                                    <Typography variant="h6">Main Image</Typography>
                                    <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                        onChange={handleMainImage} />
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <div >
                                        <Box width="50%" mr={3}>
                                            <LinearProgressWithLabel variant="determinate" value={mainProgress}
                                            />
                                        </Box>
                                    </div>
                                </StyledGrid>
                                <StyledGrid xs={12}>
                                    <div>
                                        {(() => {
                                            if (mainImage) {
                                                return (mainImage.map((url, index) => (
                                                    <div key={index}>
                                                        <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                        <Button variant="contained" onClick={() => { deleteMainImage(url, setMainImage); setPosition(index) }}>
                                                            Delete
                                                        </Button>
                                                        {position === index &&
                                                            <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                        }
                                                    </div>
                                                )))
                                            }
                                        })()}
                                    </div>
                                </StyledGrid>
                            </Grid>
                    }
                </TabPanel>
                <TabPanel value={value} index={3} style={{ width: '100%', height: '50%' }}>
                    {
                        isChecked == true ?
                            <Grid container spacing={1}>
                                <StyledGrid xs={12}>
                                    <Typography variant="h6">Main Image</Typography>
                                    <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                        onChange={handleMainImage} />
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <div >
                                        <Box width="50%" mr={3}>
                                            <LinearProgressWithLabel variant="determinate" value={mainProgress}
                                            />
                                        </Box>
                                    </div>
                                </StyledGrid>
                                <StyledGrid xs={12}>
                                    <div>
                                        {(() => {
                                            if (mainImage) {
                                                return (mainImage.map((url, index) => (
                                                    <div key={index}>
                                                        <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                        <Button variant="contained" onClick={() => { deleteMainImage(url, setMainImage); setPosition(index) }}>
                                                            Delete
                                                        </Button>
                                                        {position === index &&
                                                            <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                        }
                                                    </div>
                                                )))
                                            }
                                        })()}
                                    </div>
                                </StyledGrid>
                            </Grid>
                            :
                            <Grid container spacing={1}>
                                <StyledGrid xs={12}>
                                    <Typography variant="h6"> Images</Typography>
                                    <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                        onChange={handleAgentProfile} />
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <div >
                                        <Box width="50%" mr={3}>
                                            <LinearProgressWithLabel variant="determinate" value={progress}
                                            />
                                        </Box>
                                    </div>
                                </StyledGrid>
                                <StyledGrid xs={12}>
                                    <div>
                                        {(() => {
                                            if (agentProfile) {
                                                return (agentProfile.map((url, index) => (
                                                    <div key={index}>
                                                        <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                        <Button variant="contained" onClick={() => { deleteImage(url, setAgentProfile); setPosition(index) }}>
                                                            Delete
                                                        </Button>
                                                        {position === index &&
                                                            <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                        }
                                                    </div>
                                                )))
                                            }
                                        })()}
                                    </div>
                                </StyledGrid>
                            </Grid>
                    }
                </TabPanel>
                <TabPanel value={value} index={4} style={{ width: '100%', height: '50%' }}>
                    {
                        isChecked == true ?
                            <Grid container spacing={1}>
                                <StyledGrid xs={12}>
                                    <Typography variant="h6"> Artist Profile</Typography>
                                    <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                        onChange={handleAgentProfile} />
                                    <label htmlFor="icon-button-file">
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <div >
                                        <Box width="50%" mr={3}>
                                            <LinearProgressWithLabel variant="determinate" value={progress}
                                            />
                                        </Box>
                                    </div>
                                </StyledGrid>
                                <StyledGrid xs={12}>
                                    <div>
                                        {(() => {
                                            if (agentProfile) {
                                                return (agentProfile.map((url, index) => (
                                                    <div key={index}>
                                                        <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                        <Button variant="contained" onClick={() => { deleteImage(url, setAgentProfile); setPosition(index) }}>
                                                            Delete
                                                        </Button>
                                                        {position === index &&
                                                            <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                        }
                                                    </div>
                                                )))
                                            }
                                        })()}
                                    </div>
                                </StyledGrid>
                            </Grid>
                            :
                            null
                    }
                </TabPanel>
                <TabPanel value={value} index={5} style={{ width: '100%', height: '50%' }}>
                    <Grid container spacing={1}>
                        <StyledGrid xs={12}>
                            <Typography variant="h6"> Gallery</Typography>
                            <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                onChange={handleGallery} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                            <div >
                                <Box width="50%" mr={3}>
                                    <LinearProgressWithLabel variant="determinate" value={galleryProgress}
                                    />
                                </Box>
                            </div>
                        </StyledGrid>
                        <StyledGrid xs={12}>
                            <div>
                                {(() => {
                                    if (gallery) {
                                        return (gallery.map((url, index) => (
                                            <div key={index}>
                                                <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                <Button variant="contained" onClick={() => { deleteGallery(url, setGallery); setPosition(index) }}>
                                                    Delete
                                                </Button>
                                                {position === index &&
                                                    <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                }
                                            </div>
                                        )))
                                    }
                                })()}
                            </div>
                        </StyledGrid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={6} style={{ width: '100%', height: '50%' }}>
                    <Grid container spacing={1}>
                        <StyledGrid xs={12}>
                            <Typography variant="h6"> Map Images</Typography>
                            <input accept="image/*" style={{ display: 'none', width: '100%' }} id="icon-button-file" type="file"
                                onChange={handleBgImage} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                            <div >
                                <Box width="50%" mr={3}>
                                    <LinearProgressWithLabel variant="determinate" value={bgProgress}
                                    />
                                </Box>
                            </div>
                        </StyledGrid>
                        <StyledGrid xs={12}>
                            <div>
                                {(() => {
                                    if (bgImage) {
                                        return (bgImage.map((url, index) => (
                                            <div key={index}>
                                                <img key={url} src={url} alt="Site Map" width="200" height="130" style={{ padding: 10 }} />
                                                <Button variant="contained" onClick={() => { deleteBgImage(url, setBgImage); setPosition(index) }}>
                                                    Delete
                                                </Button>
                                                {position === index &&
                                                    <CircularProgress style={{ height: 20, width: 20, marginLeft: 10 }} />
                                                }
                                            </div>
                                        )))
                                    }
                                })()}
                            </div>
                        </StyledGrid>
                    </Grid>
                </TabPanel>
                <Button variant="contained" color="inherit" disableElevation type="submit" >
                    {id === '' ? "save" : "update"}
                </Button>
            </StyledForm >

        </>
    )
}

export default AgentPannel;

const BusinesPages = [
    { title: 'CPA and Tax Advisors' },
    { title: 'Apartments' },
    { title: 'Attorneys' },
    { title: 'Dentist' },
    { title: 'Doctor' },
    { title: 'Indian Food' },
    { title: 'Events' },
    { title: 'Decorations and Jeweleries' },
    { title: 'Photography' },
    { title: 'Indian Grocery Stores' },
    { title: 'Real Estate' },
    { title: 'Resturant' },
    { title: 'Consulate General' },
    { title: 'Temples' },
    { title: 'Gurudwara' },
    { title: 'Indian Movies' },
    { title: 'Immigration' },
    { title: 'Travel Companion' },
]

const Cities = [
    { title: 'None' },
    { title: 'Lake Stevens' },
    { title: 'Seattle' },
    { title: 'Bellevue' },
    { title: 'Redmond' },
    { title: 'Kirkland' },
    { title: 'Bothell' },
    { title: 'Issaquah' },
    { title: 'Renton' },
    { title: 'Kent' },
    { title: 'Sammamish' },
    { title: 'Everett' },
    { title: 'Lynnwood' },
    { title: 'Tukwila' },
    { title: 'Federal Way' },
    { title: 'Seatac' },
    { title: 'Tacoma' },
    { title: 'Shoreline' },
    { title: 'Olympia' },
    { title: 'Burien' },
    { title: 'Mill Creek' },
    { title: 'Edmonds' },
    { title: 'Mercer Island' },
    { title: 'Snoqualmie' },
    { title: 'Auburn' },
    { title: 'MONROE' },
]

const languages = [
    'English',
    'Hindi',
    'Malayalam',
    'Tamil',
    'Telugu',
    'Kannada',
    'Multilingual',
];

const genres = [
    'Singing',
    'Classical Music Concerts',
    'Comedy',
    'Music',
    'Upcoming Concerts',
    'Stand-up Comedy',
    'AGE 16+'
];
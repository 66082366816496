import React from 'react'
import { Button, Card, MobileStepper, styled, useTheme } from '@mui/material'
import noImg from '../../images/NoImage_Available.png'
import { CloseOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import SwipeableViews from 'react-swipeable-views'
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const CustomCard = styled(Card)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: 450
}))

const CustomImg = styled('img')(({ theme }) => ({
    height: 450,
    display: 'block',
    overflow: 'hidden',
    width: '100%'
}))
const CustomMobCard = styled(Card)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: 320
}))

const CustomMobImg = styled('img')(({ theme }) => ({
    height: 270,
    display: 'block',
    overflow: 'hidden',
    width: '100%'
}))

const CustomDiv = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '16px',
    top: '48%'
}))

const CustomDiv2 = styled('div')(({ theme }) => ({
    position: 'absolute',
    left: '16px',
    top: '48.2%'
}))

// const customStyles = {
//    marginTop: '3em'
//   };


function ImageScroll(props) {
    const { Images, Name, matches } = props;
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [toggle, setToggle] = React.useState(false);
    const [sIndex, setSIndex] = React.useState(0);
    const maxSteps = Images.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const lightBoxHandler = (state, sIndex) => {
        setToggle(state);
        setSIndex(sIndex);
    };

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        },
        thumbnails: {
            showThumbnails: true
        }
    }

    // console.log("images", Images.length);
    return (
        <>
            {
                matches ?
                    <>
                        <CustomMobCard elevation={5} >
                            <SimpleReactLightbox>
                                <SRLWrapper options={options}>
                                    <SwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents

                                    >


                                        {(() => {
                                            if (Images)
                                                return Images.map((item, index) => (
                                                    <div key={Images}>
                                                        {Math.abs(activeStep - index) <= 20 ? (
                                                            <CustomMobImg src={item} alt={Name}
                                                            />
                                                        ) : null}

                                                    </div>
                                                ))
                                            else {
                                                return
                                                <CustomMobImg src={noImg} alt="Image" />
                                            }

                                        })()}
                                    </SwipeableViews>
                                </SRLWrapper>
                            </SimpleReactLightbox>
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                    >
                                        Next
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Back
                                    </Button>
                                }
                            />
                        </CustomMobCard>
                    </>
                    :
                    <>
                        <CustomCard elevation={5} >
                            <SimpleReactLightbox>
                                <SRLWrapper options={options}>
                                    <SwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents

                                    >


                                        {(() => {
                                            if (Images)
                                                return Images.map((item, index) => (
                                                    <div key={Images}>
                                                        {Math.abs(activeStep - index) <= 20 ? (
                                                            <CustomImg src={item} alt={Name}
                                                            />
                                                        ) : null}

                                                    </div>
                                                ))
                                            else {
                                                return
                                                <CustomImg src={noImg} alt="Image" />
                                            }

                                        })()}
                                    </SwipeableViews>
                                </SRLWrapper>
                                <div >
                                    <CustomDiv>
                                        <Button size="small" onClick={handleNext} disabled={activeStep === (Images ? Images.length : 1) - 1} >
                                            {
                                                theme.direction === 'rtl' ?
                                                    <KeyboardArrowLeft sx={{
                                                        background: 'white',
                                                        borderRadius: '30px',
                                                        fontSize: '30px',
                                                        color: '#2979ff',
                                                    }} style={{ fontSize: "2rem" }} /> :
                                                    <KeyboardArrowRight
                                                        sx={{
                                                            background: 'white',
                                                            borderRadius: '30px',
                                                            fontSize: '30px',
                                                            color: '#2979ff',
                                                        }}
                                                        style={{ fontSize: "2rem" }} />
                                            }
                                        </Button>
                                    </CustomDiv>
                                    <CustomDiv2>
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0} >
                                            {theme.direction === 'rtl' ?
                                                <KeyboardArrowRight
                                                    sx={{
                                                        background: 'white',
                                                        borderRadius: '30px',
                                                        fontSize: '30px',
                                                        color: '#2979ff',
                                                    }} style={{ fontSize: "2rem" }} /> :
                                                <KeyboardArrowLeft
                                                    sx={{
                                                        background: 'white',
                                                        borderRadius: '30px',
                                                        fontSize: '30px',
                                                        color: '#2979ff',
                                                    }}
                                                    style={{ fontSize: "2rem" }} />
                                            }
                                        </Button>
                                    </CustomDiv2>
                                </div>
                            </SimpleReactLightbox>
                        </CustomCard>
                    </>

            }
        </>

    )
}

export default ImageScroll

// how to use react-portal lightbox?




import React from 'react'
import Navbar from './components/Navbar'
import Theme from './components/Theme'
import './App.css';
import { ThemeProvider } from 'styled-components';
import { Route, Routes, useLocation, } from 'react-router-dom';
import AgentsCards from './components/Agents/AgentsCards'
import AdminLogin from './components/Admin/AdminLogin';
import AdminIndex from './components/Admin/AdminIndex';
import { UserAuthContextProvider } from "./context/UserAuthContext";
import ClassifiedsContainer from './components/Classifieds/ClassifiedsContainer';
import ClassifiedsList from './components/Classifieds/ClassifiedsList';
import { AgentsDetailPage } from './components/Agents/AgentsDetailPage';
import Home from './components/Pages/Home';
import DrawerContent from './components/Sidebar/DrawerContent';
import DetailedClassified from './components/Classifieds/DetailedClassified';
import Food from './components/Pages/Food/Food';
import { DraweDp } from './components/Sidebar/DraweDp';
import DrawerLinks from './components/Sidebar/DrawerLinks';
import DrawerLinkdp from './components/Sidebar/DrawerLinkdp';
import Realestate from './components/Pages/RealEstate/Realestate';
import GroceryStories from './components/Pages/Grocery/GroceryStories';
import { GroceryDp } from './components/Pages/Grocery/GroceryDp';
import Decoration from './components/Pages/RealEstate/Decoration/Decoration';
import DecorationDp from './components/Pages/RealEstate/Decoration/DecorationDp';
import ContactSUs from './components/Pages/ContactSUs';
import { FoodDP } from './components/Pages/Food/FoodDP';
import Footer from './components/Footer';
import { Fab, styled, useMediaQuery } from '@mui/material';
import { NavigationRounded } from '@mui/icons-material';
import { useLayoutEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Event from './components/Pages/Events/Event';
import EventDetailPage from './components/Pages/Events/EventDetailPage';

const CustomFab = styled(Fab)(({ theme }) => ({
  margin: 0,
  top: 'auto',
  right: 30,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
}))

ReactGA.initialize('UA-228229656-1');

function App() {
  const [count, setCount] = React.useState([])
  const isMatch = useMediaQuery('(max-width:480px)')
  const location = useLocation();

  const handleClick = () => {
    window.scroll({ behavior: 'smooth', top: 0 })
  }


  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (count.includes(window.location.pathname + window.location.search) === false)
      setCount(prevState => ([...prevState, window.location.pathname + window.location.search]))
    // setCount(window.location.pathname + window.location.search)}
  }, [location]);

  return (

    <Wrapper>
      <HelmetProvider>
        <ThemeProvider theme={Theme}>
          <Navbar />
          <Helmet prioritizeSeoTags>
            <meta charSet="utf-8" />
            <title>Apne Indian community. Community for Indians in Redmond, Bellevue, Sammamish, Bothell, Millcreek, Lake Stevens etc.</title>
            {/* <meta name="keywords" content="New homes in Bothell, homes in Bothell, Best homes in Bothell, Homes for sale in Bothell, Buy Homes in Bothell" /> */}
            <meta name="description" content={`Apne Indian is community for indians and desi people in Pacific North West Washington (Redmond, Bellevue, Sammamish, Bothell, Millcreek, Lake Stevens etc.)`} />
            <link rel="ApneDesi" href="https://www.apneindian.com/" />
          </Helmet>
          <UserAuthContextProvider>
            <Routes >
              <Route path="/" element={<Home />} />
              <Route path="/classified" element={<ClassifiedsContainer />} />
              {/* <Route path="/classifieds/:businessType/:businessName" element={<DetailedClassified />} /> */}
              <Route path="/classified/:categorySlug/:agentSlug" element={<GroceryDp />} />
              <Route path="/indian-grocery-stores" element={<GroceryStories />} />
              <Route path="indian-grocery-stores/:agentSlug" element={<GroceryDp />} />
              <Route path="/decorations-and-jeweleries" element={<Decoration />} />
              <Route path="decorations-and-jeweleries/:agentSlug" element={<GroceryDp />} />
              <Route path="/indian-food" element={<Food />} />
              <Route path="/indian-food/:agentSlug" element={<GroceryDp />} />
              <Route path="/events" element={<Event />} />
              <Route path="events/:agentSlug" element={<EventDetailPage />} />
              <Route path="/contact-us" element={<ContactSUs />} />
              {/* <Route path="/:agentSlug" element={<DraweDp />} /> */}

              <Route path="/AdminPage" element={<AdminIndex />} />

            </Routes>
            <CustomFab color="warning" size={isMatch ? "medium" : "large"} onClick={handleClick}><NavigationRounded /></CustomFab>
            <Footer />
          </UserAuthContextProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Wrapper>

  );
}

export default App;

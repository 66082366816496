import React from 'react'
import { Box, Chip, Container, Divider, Card, CardMedia, Grid, Paper, Link, Stack, styled, Typography, useMediaQuery, useTheme, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';
import noImg from '../../images/NoImage_Available.png'
import { HomeRepairServiceRounded, LocationOnRounded, TheatersRounded } from '@mui/icons-material';


const CustomMobCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #aa076b, #61045f)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "startr",
    // cursor: "pointer",
    marginTop: '1em',
}))

function IndianMovies(props) {
    const { BusinessData, SearchTerm1, matches } = props;
    const history = useNavigate();
    const businesspages = BusinessData.map(item => item.businessType.title);
    const businessPages = [...new Set(businesspages)];
    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }


    const CustomCard1 = styled(Card)(({ theme }) => ({
        display: 'flex',
        background: 'linear-gradient(to right, #aa076b, #61045f)',
        borderRadius: 'none',
        padding: 10,
        maxWidth: SearchTerm1 ? 700 : 500,
        [theme.breakpoints.up("md")]: {
            height: 70,
        },
        alignItems: "center",
        // cursor: "pointer",
        marginTop: '1em',
        paddingLeft: '2em',
    }))

    const CustomMobCard = styled(Card)(({ theme }) => ({
        display: 'flex',
        background: 'linear-gradient(to right, #aa076b, #61045f)',
        borderRadius: 'none',
        padding: 10,
        paddingLeft: '2em',
        maxWidth: 300,
        [theme.breakpoints.up("md")]: {
            height: 70,
        },
        alignItems: "start",
        // cursor: "pointer",
        marginTop: '1em',
    }))
    return (
        <Grid container spacing={1}
            sx={{
                mt: matches ? '1em' : '5em',
                ml: matches ? '1px' : null,
                mr: matches ? '1px' : null,
                borderRadius: '5px',
                backgroundColor: 'rgb(255, 255, 255)',
                color: 'rgb(33, 43, 54)',
                transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundImage: 'none',
                overflow: 'hidden',
                boxShadow: 'rgb(145 158 171 / 16%) -17px 17px 56px -8px',
                borderRadius: '17px',
                position: 'relative',
                zIndex: 0,
            }}
        >
            <Grid xs={12} >

                <Grid item xs={matches ? 12 : 6} sx={{ mt: '2em', mb: '2em' }}>
                    {
                        matches ?
                            <CustomMobCard>
                                <Stack direction="row" >
                                    <HomeRepairServiceRounded sx={{ color: '#fff', fontSize: '1.5em', mr: '1em'  }} />
                                    {
                                        SearchTerm1 ?
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '1.1em', textTransform: 'capitalize', }}
                                            >
                                                Decoration and Jeweleries in {SearchTerm1}
                                            </Typography>
                                            :
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '1.1em', textTransform: 'capitalize', textAlign: 'start', }}
                                            >
                                                Decoration and Jeweleries
                                            </Typography>
                                    }
                                </Stack>
                            </CustomMobCard>
                            :
                            <CustomCard1>
                                <Stack direction="row" >
                                    <HomeRepairServiceRounded sx={{ color: '#fff', fontSize: '2.3em', mt: '1px', mr: '10px' }} />
                                    {
                                        SearchTerm1 ?
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'start' }}
                                            >
                                                Decoration and Jeweleries in {SearchTerm1}
                                            </Typography>
                                            :
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                            >
                                                Decoration and Jeweleries
                                            </Typography>
                                    }

                                </Stack>

                            </CustomCard1>
                    }

                </Grid>
            </Grid>

            {
                BusinessData.map((agent) => {
                    return (
                        <>
                            <Grid item xs={12} sm={6} md={4} key={agent.title} align={matches ? "start" : "start"}>
                                <Card sx={{ mt:matches ? '1em' : '2em', mb: '1em', borderRadius: '10px', maxWidth: matches ? 370 : null }} >
                                    <CardMedia
                                        component="img"
                                        alt={agent.businessName}
                                        width={matches ? '230' : "auto"}
                                        height={matches ? '300' : "400"}
                                        sx={{
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'norepeat',
                                            backgroundPosition: 'center center',
                                        }}
                                        image={agent.mainImage}
                                        src="indiangrocerystores"
                                        onClick={() => history(`/classified/${slugify(businessPages)}/${agent.agentSlug}`)}
                                        onClick1={handleClick}
                                    />
                                    <Grid item xs={12} sx={{ background: 'lightgrey', m: matches ? '10px' :'2em 1em', borderRadius: '5px', overflow: 'hidden' }}>
                                        <Typography
                                            sx={{
                                                fontSize: matches ? '1.3rem' :'1.7rem',
                                                color: '#02367d',
                                                pl: matches ? '1.2em' : '1em'
                                            }}
                                        >
                                            {agent.businessName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ml: '2px',
                                                fontWeight: 400,
                                                fontSize: matches ? '1rem' : '17px',
                                                lineHeight: '1.575',
                                                textDecoration: 'none',
                                                pl: '1.5em'
                                            }}

                                        >
                                             {(agent.phoneNumber) ? `Phone : ${agent.phoneNumber}` : null}
                                        </Typography>
                                        {(agent.emailId) ?
                                                    <Typography
                                                        sx={{
                                                            ml: matches ? '3px' : '2px',
                                                            fontWeight: 400,
                                                            fontSize: matches ? '1rem' : '17px',
                                                            lineHeight: '1.575',
                                                            textDecoration: 'none',
                                                            pl: '1.5em'
                                                        }}

                                                    >
                                                        Email :
                                                        <Link underline='none' href={`mailto:${agent.emailId}`} sx={{ ml: '10px' }}>
                                                            {agent.emailId}
                                                        </Link>
                                                    </Typography>
                                                    :
                                                    null}
                                        {/* <Typography
                                            sx={{
                                                ml: '2px',
                                                fontWeight: 400,
                                                fontSize: matches ? '1rem' : '17px',
                                                lineHeight: '1.575',

                                                textDecoration: 'none',
                                                pl: '1.5em'
                                            }}

                                        >
                                            Website:
                                            <Link underline='none' href={agent.website} sx={{ ml: '10px' }} target="_blank">
                                                {agent.website}
                                            </Link>
                                        </Typography> */}
                                        <Stack direction="row" spacing={0} sx={{
                                            pl: '1.5em',
                                            pb: '1em'
                                        }}>
                                            <LocationOnRounded sx={{ color: '#ff0055' }} />
                                            <Typography
                                                sx={{

                                                    fontWeight: 400,
                                                    fontSize: matches ? '1rem' : '17px',
                                                    lineHeight: '1.575',

                                                    textDecoration: 'none',
                                                }}
                                                color='text.secondary'
                                            >
                                                {agent.address}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Box sx={{ p: matches ? '1px 9px' : null, ml: '1em', mr: '0.3em' }}>
                                        <Typography
                                            sx={{
                                                ml:  matches ? '10px' : '1em',
                                                fontWeight: 400,
                                                fontSize: matches ? '15px' : '17px',
                                                lineHeight: '1.575',

                                                textDecoration: 'none',
                                            }}
                                            color='text.secondary'
                                        >
                                            {agent.shortdescription}
                                        </Typography>
                                        <Divider sx={{ m: matches ? '10px' : '1em' }} />
                                        <Box sx={{ m: '10px', display: 'flex', flexDirection: 'end', justifyContent: 'flex-end' }}>
                                            <Button variant='contained' onClick={() => history(`/classified/${slugify(businessPages)}/${agent.agentSlug}`)} onClick1={handleClick}>Know More</Button>
                                        </Box>

                                    </Box>
                                </Card>
                            </Grid>

                        </>


                    )
                })
            }
        </Grid>
    )
}

export default IndianMovies
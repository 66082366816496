import React from 'react'
import { Container, CssBaseline, Link, Typography, useMediaQuery } from '@mui/material'


function Copyright(props) {
    const { Ismatch } = props;
    return (
        <Typography variant={Ismatch ? "body2" : "body1"} color="white" align="center" sx={{ mt: '1em', mb: '1px' }}>
            {'Copyright © '}
            <Link color="inherit" underline='none' href="https://www.apneindian.com/">
                ApneDesi.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Footer = () => {
    const isMatch = useMediaQuery('(max-width:480px)')
    return (
        <div style={{ display: 'flex', flexDitection: 'colum' }}>
            <CssBaseline />
            <footer style={{
                background: '#002e57',
                color: '#a8a8a8',
                padding: '3.2rem 0px',
                width: '100%',
                textAlign: 'justify',
            }}>
                <Container maxWidth={isMatch ? "xs" : "lg"}>
                    <Typography variant={isMatch ? "body2" : 'h7'} >At <a style={{ color: '#fff' }} href="https://www.apneindian.com/">ApneDesi.com</a> We are updating our website every 24 hours with content. We are making our best effort to keep data accurate and up to date. Whilst every effort is taken to avoid errors, we cannot accept responsibility for the accuracy of any statement, extract or information contained within the pages of this website. The contents of this site are intended for convenience only. It is your responsibility to independently verify the information on the site.</Typography>
                    <Copyright Ismatch={isMatch} />
                </Container>
            </footer>
        </div>
    )
}

export default Footer


import React from 'react'
import { Avatar, Box, Button, ButtonBase, Card, CardActionArea, CardContent, CardMedia, Chip, Divider, Grid, IconButton, ImageListItem, ImageListItemBar, Link, Paper, Rating, Stack, styled, Typography, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import agentImg from '../../images/agent1.jpg'
import slugify from 'react-slugify';
import { Email, Facebook, HomeMaxOutlined, HomeRounded, HouseRounded, LinkOutlined, Person, Phone, Twitter, YouTube } from '@mui/icons-material';
import Sold1 from '../../images/Sold/Sold1.jpg'
import Sold2 from '../../images/Sold/Sold2.jpg'
import Sold3 from '../../images/Sold/Sold3.jpg'
import Sold4 from '../../images/Sold/Sold4.jpg'
import Sold5 from '../../images/Sold/Sold5.jpg'
import Sold6 from '../../images/Sold/Sold6.jpg'
import S1 from '../../images/Sold/s1.png'
import S2 from '../../images/Sold/s2.jpg'
import S3 from '../../images/Sold/s3.jpg'
import S4 from '../../images/Sold/s4.jpg'
import S5 from '../../images/Sold/s5.jpg'
import S6 from '../../images/Sold/s6.jpg'
import S7 from '../../images/Sold/s7.jpg'
import S8 from '../../images/Sold/s8.jpg'
import S9 from '../../images/Sold/s9.jpg'
import S10 from '../../images/Sold/s10.jpg'
import S11 from '../../images/Sold/s11.jpg'
import S12 from '../../images/Sold/s12.jpg'
import { deepOrange } from '@mui/material/colors';
import ReactReadMoreReadLess from "react-read-more-read-less"
import ReactPlayer from 'react-player';


const ImagePaper = styled(Paper)(({ theme, isMatch }) => ({
    height: isMatch ? '130px' : '150px',
    width: isMatch ? '150px' : '230px',
    margin: isMatch ? '1em' : null,
    borderRadius: '15px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}));

const CustomReactReadMoreReadLess = styled(ReactReadMoreReadLess)(({ theme }) => ({
    marginTop: '1em',
    flex: 1,
    textAlign: 'justify',
    fontSize: '15px',
    fontWeight: '400',
}))

const CustomCard1 = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #ffc500, #c21500)',
    borderRadius: 'none',
    padding: 10,
    maxWidth: 500,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',
    paddingLeft: '2em',
}))
const CustomMobCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    background: 'linear-gradient(to right, #ffc500, #c21500)',
    borderRadius: 'none',
    padding: 10,
    paddingLeft: '2em',
    maxWidth: 400,
    [theme.breakpoints.up("md")]: {
        height: 70,
    },
    alignItems: "center",
    // cursor: "pointer",
    marginTop: '1em',
}))

const CustomAvatar = styled(Avatar)(({ theme }) => ({
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
}))

function Classified(props) {
    const { BusinessData, SearchTerm, SearchTerm1, matches } = props;
    const [openImage, setOpenImage] = React.useState(false);
    const history = useNavigate();
    const businesspages = BusinessData.map(item => item.businessType.title);
    const businessPages = [...new Set(businesspages)];
    const businesspages1 = BusinessData.map(item => item.city.title);
    const businessPages1 = [...new Set(businesspages1)];
    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }
    // console.log('data', SearchTerm1);



    return (
        <Grid container spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{
                mt: matches ? '1em' : '5em',
                ml: matches ? '1px' : null,
                mr: matches ? '1px' : null,
                borderRadius: '5px',
                backgroundColor: 'rgb(255, 255, 255)',
                color: 'rgb(33, 43, 54)',
                transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundImage: 'none',
                overflow: 'hidden',
                boxShadow: 'rgb(145 158 171 / 16%) -17px 17px 56px -8px',
                borderRadius: '17px',
                position: 'relative',
                zIndex: 0,
            }}>
            <Grid xs={12} >
                {/* {
                    SearchTerm ?
                        <Grid item xs={6}>
                            <CustomCard>
                                <Typography
                                    variant='h5'
                                    sx={{ color: '#fff', fontSize: '2em', fontFamily: 'ITC Clearface,serif', textTransform: 'capitalize' }}
                                >
                                    Category: {SearchTerm ? businessPages : null}
                                </Typography>
                            </CustomCard>
                        </Grid>
                        :
                        null
                }
                {
                    SearchTerm1 ?
                        <Grid item xs={6} >
                            <CustomCard1>
                            <Typography
                                    variant='h5'
                                    sx={{ color: '#fff', fontSize: '2em', fontFamily: 'ITC Clearface,serif', textTransform: 'capitalize' }}
                                >
                                    Classified in {SearchTerm1 ? businessPages1 : null}
                                </Typography>
                            </CustomCard1>
                        </Grid>
                        :
                        null
                } */}
                <Grid item xs={matches ? 8 : 6} sx={{ mt: '2em', mb: '2em' }}>
                    {
                        matches ?
                            <CustomMobCard>
                                <Stack direction="row" >
                                    <HomeRounded sx={{ color: '#fff', fontSize: '1.5em', mr: '10px' }} />
                                    {
                                        SearchTerm1 ?
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '1.1em', textTransform: 'capitalize', textAlign: 'center' }}
                                            >
                                                Real Estate in {SearchTerm1}
                                            </Typography>
                                            :
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '1.1em', textTransform: 'capitalize', textAlign: 'center' }}
                                            >
                                                Real Estate
                                            </Typography>
                                    }
                                </Stack>
                            </CustomMobCard>
                            :
                            <CustomCard1>
                                <Stack direction="row" >
                                    <HomeRounded sx={{ color: '#fff', fontSize: '2.3em', mt: '1px', mr: '10px' }} />
                                    {
                                        SearchTerm1 ?
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                            >
                                                Real Estate in {SearchTerm1}
                                            </Typography>
                                            :
                                            <Typography
                                                variant='h5'
                                                sx={{ color: '#fff', fontSize: '2em', textTransform: 'capitalize', textAlign: 'center' }}
                                            >
                                                Real Estate
                                            </Typography>
                                    }

                                </Stack>

                            </CustomCard1>
                    }

                </Grid>
            </Grid>
            <>
                {
                    BusinessData.sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1).map((agent) => {
                        return (
                            <>
                                {
                                    matches ?
                                        <Paper elevation={2} sx={{ bgcolor: '#eceff1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', p: '1em', m: '1em' }}>
                                            <Box
                                                sx={{
                                                    m: '1em',
                                                    fontSize: 'h6.fontSize',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: 'rgb(7 6 40 / 87%)',
                                                    fontFamily: 'Rubik'
                                                }}>
                                                Deepti Gupta Real Estate
                                            </Box>
                                            <Card sx={{ m: '8px' }} elevation={0}>
                                                <CardMedia
                                                    component="img"
                                                    alt={agent.businessName}
                                                    src="decoration and jeweleries"
                                                    sx={{
                                                        height: 'auto',
                                                        objectFit: 'cover'
                                                    }} image={agent.mainImage} />
                                                <CardContent sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Stack direction="column" spacing={1}>
                                                        <Box
                                                            sx={{
                                                                color: 'rgb(94, 96, 103)',
                                                                fontFamily: 'Jaldi',
                                                                fontSize: '8px',
                                                                fontWeight: 400,
                                                                lineHeight: '1.667em',
                                                                letterSpacing: '0.4px',
                                                            }}
                                                        >
                                                            Deepti Gupta Real Estate offers you the knowledge, experience, & guidance you need to make the process as smooth as possible in your journey to buy or sell home. We have guided clients through every type of market. Customers receive focused attention at every step of the process. We are working primarily in Bothell, Sammamish, Bellevue, Kirkland, Redmond, Millcreek, Lynnwood, Lake Stevens, Renton, Maple Valley, Puyallup and Issaquah.
                                                            Our team provides professional services in buying, selling and investing areas ensuring best results for our valued clients. Being a mother myself, I understand the importance of a home that’s more than just brick and mortar; a home that has character and provides the comfort and security of a loving home! I strive towards ensuring that all my clients get the best deals that exceed their expectations and bring them peace of mind.
                                                            So, what are you waiting for? Reach out to us and We will help you find a house that’ll make you feel at home!
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                color: 'rgb(94, 96, 103)',
                                                                fontFamily: 'Jaldi',
                                                                fontSize: '7px',
                                                                fontWeight: 400,
                                                                lineHeight: '1.667em',
                                                                letterSpacing: '0.4px',
                                                            }}
                                                        >

                                                        </Box>
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="start"
                                                            alignItems="start"
                                                            spacing={1}
                                                        >
                                                            <Stack direction="row">
                                                                <HouseRounded sx={{ fontSize: "15px" }} />
                                                                <Typography
                                                                    sx=
                                                                    {{
                                                                        fontSize: '10px',
                                                                        fontWeight: '600',
                                                                        pl: '5px'
                                                                    }}
                                                                >
                                                                    300+ Homes Closed
                                                                </Typography>
                                                            </Stack >
                                                            <Stack direction="row">
                                                                <Person sx={{ fontSize: "15px" }} />
                                                                <Typography
                                                                    sx=
                                                                    {{
                                                                        fontSize: '10px',
                                                                        fontWeight: '600',
                                                                        pl: '5px'
                                                                    }}
                                                                >
                                                                    1000+ Happy Clients
                                                                </Typography>
                                                            </Stack>
                                                        </Stack >
                                                        <Stack direction='row' spacing={0} sx={{ ml: '6px' }}>
                                                            <Link href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq" underline="none" target="_blank" rel="noopener noreferrer">
                                                                <IconButton aria-label="facebook">
                                                                    <Facebook
                                                                        sx={{
                                                                            fontSize: matches ? '15px' : '25px', color: 'rgb(7 6 40 / 87%)',
                                                                            '&:hover': {
                                                                                color: 'none',
                                                                            },
                                                                        }} />
                                                                </IconButton>
                                                            </Link>
                                                            <Link href="https://twitter.com/dguptahomes" underline="none" target="_blank" rel="noopener noreferrer">
                                                                <IconButton aria-label="twitter">
                                                                    <Twitter
                                                                        sx={{
                                                                            fontSize: matches ? '15px' : '25px', color: 'rgb(7 6 40 / 87%)',
                                                                            '&:hover': {
                                                                                color: 'none',
                                                                            },
                                                                        }} />
                                                                </IconButton>
                                                            </Link>
                                                            <Link href="https://www.youtube.com/channel/UCHWBnlM3LyDOJR5IuLbCtiw?view_as=subscriber" underline="none" target="_blank" rel="noopener noreferrer">
                                                                <IconButton aria-label="youTube">
                                                                    <YouTube
                                                                        sx={{
                                                                            fontSize: matches ? '15px' : '25px', color: 'rgb(7 6 40 / 87%)',
                                                                            '&:hover': {
                                                                                color: 'none',
                                                                            },
                                                                        }} />
                                                                </IconButton>
                                                            </Link>
                                                            <Link href="https://www.homewastate.com/" underline="none" target="_blank" rel="noopener noreferrer">
                                                                <IconButton aria-label="youTube">
                                                                    <LinkOutlined
                                                                        sx={{
                                                                            fontSize: matches ? '15px' : '25px', color: 'rgb(7 6 40 / 87%)',
                                                                            '&:hover': {
                                                                                color: 'none',
                                                                            },
                                                                        }} />
                                                                </IconButton>
                                                            </Link>
                                                        </Stack>
                                                        <Stack direction='row' spacing={1} sx={{ ml: '8px' }}>
                                                            <Link href="tel:425-588-8470" underline="none" target="_blank" rel="noopener noreferrer">
                                                                <Chip size="small" sx={{
                                                                    '& .MuiChip-label': {
                                                                        color: 'rgb(7 6 40 / 87%)',
                                                                        fontSize: "10px" // Change this to your desired label color
                                                                    },
                                                                    // Customize the styles for the icon
                                                                    '& .MuiChip-icon': {
                                                                        color: 'rgb(7 6 40 / 87%)', 
                                                                        fontSize: "15px"// Change this to your desired icon color
                                                                    },
                                                                }} icon={<Phone sx={{ color: 'rgb(7 6 40 / 87%)', }} />} label="425-588-8470" variant="outlined" clickable />
                                                            </Link>
                                                            <Link underline="none" target="_blank" rel="noopener noreferrer"
                                                                href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">
                                                                <Chip size="small" sx={{
                                                                    '& .MuiChip-label': {
                                                                        color: 'rgb(7 6 40 / 87%)',
                                                                        fontSize: "10px" // Change this to your desired label color
                                                                    },
                                                                    // Customize the styles for the icon
                                                                    '& .MuiChip-icon': {
                                                                        color: 'rgb(7 6 40 / 87%)',
                                                                        fontSize: "15px" // Change this to your desired icon color
                                                                    },
                                                                }} icon={<Email sx={{ color: 'rgb(7 6 40 / 87%)', }} />} label="dguptahomes@gmail.com" variant="outlined" clickable />
                                                            </Link>
                                                        </Stack>
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                            <Box
                                                sx={{
                                                    m: '1em',
                                                    fontSize: 'h6.fontSize',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: 'rgb(7 6 40 / 87%)',
                                                    fontFamily: 'Rubik'
                                                }}>
                                                Deepti Gupta Customer Testimonials
                                            </Box>
                                            <Grid container spacing={2}>
                                                {Testimonials
                                                    .sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
                                                    .slice(0, 3)
                                                    .map((item) => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={4} align={matches ? "start" : "start"}>
                                                                <Card
                                                                    style={{
                                                                        padding: '10px 20px', border: '1px solid #aaa', backgroundColor: '#ebebeb',
                                                                        boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                                                    }}
                                                                    elevation={0}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt="client testimonals"
                                                                        height="150"
                                                                        image={item.image}
                                                                        src="Client Testimonals"
                                                                    />
                                                                    <Grid container spacing={1} style={{ marginTop: '1em' }}>
                                                                        <Grid item xs={12} align="center">
                                                                            <Typography gutterBottom variant="h7" style={{ textAlign: 'center', color: '#fda12e' }}>
                                                                                {item.name}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} align="center">
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {item.date}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} align="center">
                                                                            <Rating style={{ color: "red" }} name="read-only" value={item.rating} readOnly size="small" />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <p style={{ fontSize: '12px' }}>
                                                                                <CustomReactReadMoreReadLess
                                                                                    readMoreClassName="reactMoreClassName"
                                                                                    charLimit={120}
                                                                                    readMoreText={<Button color="primary" size="small">Read more ▼</Button>}
                                                                                    readLessText={<Button color="primary" size="small">Read less ▲</Button>}

                                                                                >
                                                                                    {item.FeedBack}
                                                                                </CustomReactReadMoreReadLess>
                                                                            </p>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })}
                                                {
                                                    openImage ? null :
                                                        <>
                                                            {Testimonials
                                                                .sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
                                                                .slice(3, 4)
                                                                .map((item) => {
                                                                    return (
                                                                        <Grid item xs={12} sm={6} md={4} align={matches ? "start" : "start"}>
                                                                            <ImageListItem key={item.name} >
                                                                                <img
                                                                                    src={item.image}
                                                                                    //   srcSet={`${item.img}`}
                                                                                    width="100%"
                                                                                    height={250}
                                                                                    alt={item.name}
                                                                                    loading="eager"
                                                                                    style={{ filter: 'brightness(0.5)' }}
                                                                                />
                                                                                <Box
                                                                                    sx={{
                                                                                        position: 'absolute',
                                                                                        top: '50%',
                                                                                        left: '50%',
                                                                                        transform: 'translate(-50%, -50%)',
                                                                                    }}
                                                                                >
                                                                                    <Link href="https://www.homewastate.com/Reviews" underline="none" target="_blank" rel="noopener noreferrer">
                                                                                        <Button variant="outlined" sx={{
                                                                                            color: '#fff',
                                                                                            fontSize: 'smaller',
                                                                                            '&.MuiButton-outlined': {
                                                                                                borderColor: '#fff',
                                                                                                color: '#fff',
                                                                                                '&:hover': {
                                                                                                    cursor: 'pointer',
                                                                                                    transform: 'scale(1.05)',
                                                                                                    borderColor: '#fff',
                                                                                                    color: '#fff',
                                                                                                },
                                                                                            }
                                                                                        }}>
                                                                                            Load More
                                                                                        </Button>
                                                                                    </Link>
                                                                                </Box>
                                                                            </ImageListItem>
                                                                        </Grid>
                                                                    )
                                                                })}
                                                        </>

                                                }
                                            </Grid>
                                            <Box
                                                sx={{
                                                    m: '1em',
                                                    fontSize: 'h6.fontSize',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: 'rgb(7 6 40 / 87%)',
                                                    fontFamily: 'Rubik'
                                                }}>
                                                Deepti Gupta Past Listings
                                            </Box>
                                            <Grid container spacing={2}>
                                                {
                                                    Youtube
                                                        .slice(0, 3)
                                                        .map((image) => {
                                                            return (
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <div>
                                                                        <Card style={{ width: '100%', height: '200px', }} >
                                                                            <ReactPlayer width="100%" height="100%" url={image.Vedio} controls={true} />
                                                                        </Card>
                                                                    </div>
                                                                </Grid>
                                                            )
                                                        })}
                                                {
                                                    openImage ? null :
                                                        <>
                                                            {Youtube
                                                                .slice(3, 4)
                                                                .map((image) => {
                                                                    return (
                                                                        <Grid item xs={12} sm={6} md={3}>
                                                                            <div>
                                                                                <Card style={{ width: '100%', height: '200px', }} >
                                                                                    <CardActionArea>
                                                                                        <CardMedia
                                                                                            component="img"
                                                                                            image={Sold6}
                                                                                            alt="Image"
                                                                                            sx={{ filter: 'brightness(0.5)' }}
                                                                                        />
                                                                                        <Box
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                top: '50%',
                                                                                                left: '50%',
                                                                                                transform: 'translate(-50%, -50%)',
                                                                                            }}
                                                                                        >
                                                                                            <Link href="https://www.homewastate.com/Our-Listings" underline="none" target="_blank" rel="noopener noreferrer">
                                                                                                <Button variant="outlined" sx={{
                                                                                                    color: '#fff',
                                                                                                    fontSize: 'larger',
                                                                                                    '&.MuiButton-outlined': {
                                                                                                        borderColor: '#fff',
                                                                                                        color: '#fff',
                                                                                                        '&:hover': {
                                                                                                            cursor: 'pointer',
                                                                                                            transform: 'scale(1.05)',
                                                                                                            borderColor: '#fff',
                                                                                                            color: '#fff',
                                                                                                        },
                                                                                                    }
                                                                                                }}>
                                                                                                    Load More
                                                                                                </Button>
                                                                                            </Link>
                                                                                        </Box>
                                                                                    </CardActionArea>
                                                                                </Card>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                })}
                                                        </>
                                                }

                                            </Grid>
                                            <Box
                                                sx={{
                                                    m: '1em',
                                                    fontSize: 'h6.fontSize',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: 'rgb(7 6 40 / 87%)',
                                                    fontFamily: 'Rubik'
                                                }}>
                                                Deepti Gupta Past Sales
                                            </Box>
                                            <Grid container spacing={0}>
                                                {
                                                    Sold.slice(0, 9).map((card) => {
                                                        return (
                                                            <Grid xs={6} display="flex" justifyContent="center" alignItems="center">
                                                                <ImagePaper
                                                                    elevation={3}
                                                                    sx={{ backgroundImage: `url(${card.Image})` }}
                                                                    isMatch={matches}
                                                                />
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                                {
                                                    Sold.slice(9, 10).map((card) => {
                                                        return (
                                                            <Grid xs={6} display="flex" justifyContent="center" alignItems="center">
                                                                <Card sx={{
                                                                    height: '130px',
                                                                    width: '150px',
                                                                    margin: '1em',
                                                                    borderRadius: '15px',
                                                                }} >
                                                                    <CardActionArea>
                                                                        <ImagePaper
                                                                            elevation={3}
                                                                            sx={{ backgroundImage: `url(${card.Image})` }}
                                                                        />
                                                                        <Box
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                transform: 'translate(-50%, -50%)',
                                                                            }}
                                                                        >
                                                                            <Link href="https://www.homewastate.com/Our-Listings" underline="none" target="_blank" rel="noopener noreferrer">
                                                                                <Button variant="outlined" sx={{
                                                                                    color: '#fff',
                                                                                    fontSize: '9px',
                                                                                    '&.MuiButton-outlined': {
                                                                                        borderColor: '#fff',
                                                                                        color: '#fff',
                                                                                        '&:hover': {
                                                                                            cursor: 'pointer',
                                                                                            transform: 'scale(1.05)',
                                                                                            borderColor: '#fff',
                                                                                            color: '#fff',
                                                                                        },
                                                                                    }
                                                                                }}>
                                                                                    Load More
                                                                                </Button>
                                                                            </Link>
                                                                        </Box>
                                                                    </CardActionArea>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>

                                        </Paper>
                                        :
                                        <Paper elevation={2} sx={{ bgcolor: '#eceff1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', p: '1em', m: '1em' }}>
                                            <Box
                                                sx={{
                                                    m: '10px 0px',
                                                    fontSize: matches ? '15px' : 'h4.fontSize',
                                                    fontWeight: 500,
                                                    textAlign: 'left',
                                                    color: 'rgb(7 6 40 / 87%)',
                                                    fontFamily: 'Rubik'
                                                }}>
                                                Deepti Gupta Real Estate
                                            </Box>
                                            <Card sx={{ display: 'flex', marginBottom: '15px', width: 950, }}>
                                                <CardMedia
                                                    component="img"
                                                    alt={agent.businessName}
                                                    src="decoration and jeweleries"
                                                    sx={{
                                                        width: matches ? 150 : 350,
                                                        height: matches ? 300 : null,
                                                        objectFit: 'cover'
                                                    }} image={agent.mainImage} />
                                                <CardContent sx={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    ml: matches ? null : '1em',
                                                    mt: matches ? null : '1.3em'
                                                }}>
                                                    <Grid container spacing={matches ? 1 : 2} wrap={matches ? 'wrap' : "nowrap"} >
                                                        <Grid item xs
                                                            spacing={1}>

                                                            <Box
                                                                sx={{
                                                                    color: 'rgb(94, 96, 103)',
                                                                    fontFamily: 'Jaldi',
                                                                    fontSize: matches ? '7px' : '15px',
                                                                    fontWeight: 400,
                                                                    lineHeight: '1.667em',
                                                                    letterSpacing: '0.4px',
                                                                }}
                                                            >
                                                                Deepti Gupta Real Estate offers you the knowledge, experience, & guidance you need to make the process as smooth as possible in your journey to buy or sell home. We have guided clients through every type of market. Customers receive focused attention at every step of the process. We are working primarily in Bothell, Sammamish, Bellevue, Kirkland, Redmond, Millcreek, Lynnwood, Lake Stevens, Renton, Maple Valley, Puyallup and Issaquah.
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    color: 'rgb(94, 96, 103)',
                                                                    fontFamily: 'Jaldi',
                                                                    fontSize: matches ? '7px' : '15px',
                                                                    fontWeight: 400,
                                                                    lineHeight: '1.667em',
                                                                    letterSpacing: '0.4px',
                                                                }}
                                                            >
                                                                Our team provides professional services in buying, selling and investing areas ensuring best results for our valued clients. Being a mother myself, I understand the importance of a home that’s more than just brick and mortar; a home that has character and provides the comfort and security of a loving home! I strive towards ensuring that all my clients get the best deals that exceed their expectations and bring them peace of mind.
                                                                So, what are you waiting for? Reach out to us and We will help you find a house that’ll make you feel at home!
                                                            </Box>
                                                            <>
                                                                <Stack direction="row" spacing={1} sx={{ mt: '1em', mb: '1em' }}>
                                                                    <HouseRounded sx={{ fontSize: '20px' }} />
                                                                    <Typography
                                                                        sx=
                                                                        {{
                                                                            fontSize: '15px',
                                                                            fontWeight: '600',
                                                                        }}
                                                                    >
                                                                        300+ Homes Closed
                                                                    </Typography>
                                                                    <Person sx={{ fontSize: '20px' }} />
                                                                    <Typography
                                                                        sx=
                                                                        {{
                                                                            fontSize: '15px',
                                                                            fontWeight: '600',
                                                                        }}
                                                                    >
                                                                        1000+ Happy Clients
                                                                    </Typography>
                                                                </Stack>
                                                                <Box sx={{ display: 'flex', alignItems: 'start', mb: '5px' }}>
                                                                    <Link href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq" underline="none" target="_blank" rel="noopener noreferrer">
                                                                        <IconButton aria-label="facebook">
                                                                            <Facebook
                                                                                sx={{
                                                                                    fontSize: matches ? '10px' : '25px', color: 'rgb(7 6 40 / 87%)',
                                                                                    '&:hover': {
                                                                                        color: 'none',
                                                                                    },
                                                                                }} />
                                                                        </IconButton>
                                                                    </Link>
                                                                    <Link href="https://twitter.com/dguptahomes" underline="none" target="_blank" rel="noopener noreferrer">
                                                                        <IconButton aria-label="twitter">
                                                                            <Twitter
                                                                                sx={{
                                                                                    fontSize: matches ? '10px' : '25px', color: 'rgb(7 6 40 / 87%)',
                                                                                    '&:hover': {
                                                                                        color: 'none',
                                                                                    },
                                                                                }} />
                                                                        </IconButton>
                                                                    </Link>
                                                                    <Link href="https://www.youtube.com/channel/UCHWBnlM3LyDOJR5IuLbCtiw?view_as=subscriber" underline="none" target="_blank" rel="noopener noreferrer">
                                                                        <IconButton aria-label="youTube">
                                                                            <YouTube
                                                                                sx={{
                                                                                    fontSize: matches ? '10px' : '25px', color: 'rgb(7 6 40 / 87%)',
                                                                                    '&:hover': {
                                                                                        color: 'none',
                                                                                    },
                                                                                }} />
                                                                        </IconButton>
                                                                    </Link>
                                                                    <Link href="https://www.homewastate.com/" underline="none" target="_blank" rel="noopener noreferrer">
                                                                        <IconButton aria-label="youTube">
                                                                            <LinkOutlined
                                                                                sx={{
                                                                                    fontSize: matches ? '10px' : '25px',
                                                                                    color: 'rgb(7 6 40 / 87%)',
                                                                                    '&:hover': {
                                                                                        color: 'none',
                                                                                    },
                                                                                }} />
                                                                        </IconButton>
                                                                    </Link>
                                                                </Box>
                                                                <Stack direction='row' spacing={2} sx={{ ml: '6px', mt: '1em' }}>
                                                                    <Link href="tel:425-588-8470" underline="none" target="_blank" rel="noopener noreferrer">
                                                                        <Chip sx={{
                                                                            '& .MuiChip-label': {
                                                                                color: 'rgb(7 6 40 / 87%)', // Change this to your desired label color
                                                                            },
                                                                            // Customize the styles for the icon
                                                                            '& .MuiChip-icon': {
                                                                                color: 'rgb(7 6 40 / 87%)', // Change this to your desired icon color
                                                                            },
                                                                        }} icon={<Phone sx={{ color: 'rgb(7 6 40 / 87%)', }} />} label="425-588-8470" variant="outlined" clickable />
                                                                    </Link>
                                                                    <Link underline="none" target="_blank" rel="noopener noreferrer"
                                                                        href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">
                                                                        <Chip sx={{
                                                                            '& .MuiChip-label': {
                                                                                color: 'rgb(7 6 40 / 87%)', // Change this to your desired label color
                                                                            },
                                                                            // Customize the styles for the icon
                                                                            '& .MuiChip-icon': {
                                                                                color: 'rgb(7 6 40 / 87%)', // Change this to your desired icon color
                                                                            },
                                                                        }} icon={<Email sx={{ color: 'rgb(7 6 40 / 87%)', }} />} label="dguptahomes@gmail.com" variant="outlined" clickable />
                                                                    </Link>
                                                                </Stack>
                                                            </>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', p: '1em', m: '1em' }}>
                                                <Box
                                                    sx={{
                                                        m: '1em',
                                                        fontSize: matches ? '15px' : 'h4.fontSize',
                                                        fontWeight: 500,
                                                        textAlign: 'left',
                                                        color: 'rgb(7 6 40 / 87%)',
                                                        fontFamily: 'Rubik'
                                                    }}>
                                                    Deepti Gupta Customer Testimonials
                                                </Box>
                                                <Grid container spacing={2}>
                                                    {Testimonials
                                                        .sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
                                                        .slice(0, 4)
                                                        .map((item) => {
                                                            return (
                                                                <Grid item xs={12} sm={6} md={4} align={matches ? "start" : "start"}>
                                                                    <Card
                                                                        style={{
                                                                            padding: '10px 20px', border: '1px solid #aaa', backgroundColor: '#ebebeb',
                                                                            boxShadow: '0px 0px 5px #c7c7c7', maxWidth: '100%',
                                                                        }}
                                                                        elevation={0}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            alt="client testimonals"
                                                                            height={matches ? "120" : "320"}
                                                                            image={item.image}
                                                                            src="Client Testimonals"
                                                                        />
                                                                        <Grid container spacing={1} style={{ marginTop: '1em' }}>
                                                                            <Grid item xs={12} >
                                                                                <Typography gutterBottom variant={matches ? "h6" : "h6"} style={{ textAlign: 'center', color: '#fda12e' }}>
                                                                                    {item.name}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} align="center">
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    {item.date}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} align="center">
                                                                                <Rating style={{ color: "red" }} name="read-only" value={item.rating} readOnly size="small" />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <p>
                                                                                    <CustomReactReadMoreReadLess
                                                                                        readMoreClassName="reactMoreClassName"
                                                                                        charLimit={matches ? 120 : 150}
                                                                                        readMoreText={<Button color="primary" size="small">Read more ▼</Button>}
                                                                                        readLessText={<Button color="primary" size="small">Read less ▲</Button>}

                                                                                    >
                                                                                        {item.FeedBack}
                                                                                    </CustomReactReadMoreReadLess>
                                                                                </p>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })}
                                                    {
                                                        openImage ? null :
                                                            <>
                                                                {Testimonials
                                                                    .sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
                                                                    .slice(4, 5)
                                                                    .map((item) => {
                                                                        return (
                                                                            <Grid item xs={12} sm={6} md={4} align={matches ? "start" : "start"}>
                                                                                <ImageListItem key={item.name} >
                                                                                    <img
                                                                                        src={item.image}
                                                                                        //   srcSet={`${item.img}`}
                                                                                        width="100%"
                                                                                        height={250}
                                                                                        alt={item.name}
                                                                                        loading="eager"
                                                                                        style={{ filter: 'brightness(0.5)' }}
                                                                                    />
                                                                                    <Box
                                                                                        sx={{
                                                                                            position: 'absolute',
                                                                                            top: '50%',
                                                                                            left: '50%',
                                                                                            transform: 'translate(-50%, -50%)',
                                                                                        }}
                                                                                    >
                                                                                        <Link href="https://www.homewastate.com/Reviews" underline="none" target="_blank" rel="noopener noreferrer">
                                                                                            <Button variant="outlined" sx={{
                                                                                                color: '#fff',
                                                                                                fontSize: 'larger',
                                                                                                '&.MuiButton-outlined': {
                                                                                                    borderColor: '#fff',
                                                                                                    color: '#fff',
                                                                                                    '&:hover': {
                                                                                                        cursor: 'pointer',
                                                                                                        transform: 'scale(1.05)',
                                                                                                        borderColor: '#fff',
                                                                                                        color: '#fff',
                                                                                                    },
                                                                                                }
                                                                                            }}>
                                                                                                Load More
                                                                                            </Button>
                                                                                        </Link>
                                                                                    </Box>
                                                                                </ImageListItem>
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                            </>

                                                    }
                                                </Grid>
                                                <Box
                                                    sx={{
                                                        m: '1em',
                                                        fontSize: matches ? '15px' : 'h4.fontSize',
                                                        fontWeight: 500,
                                                        textAlign: 'left',
                                                        color: 'rgb(7 6 40 / 87%)',
                                                        fontFamily: 'Rubik'
                                                    }}>
                                                    Deepti Gupta Past Listings
                                                </Box>
                                                <Grid container spacing={2}>
                                                    {
                                                        Youtube
                                                            .slice(0, 7)
                                                            .map((image) => {
                                                                return (
                                                                    <Grid item xs={12} sm={6} md={3}>
                                                                        <div>
                                                                            <Card style={{ width: '100%', height: '230px', }} >
                                                                                <ReactPlayer width="100%" height="100%" url={image.Vedio} controls={true} />
                                                                            </Card>
                                                                        </div>
                                                                    </Grid>
                                                                )
                                                            })}
                                                    {
                                                        openImage ? null :
                                                            <>
                                                                {Youtube
                                                                    .slice(7, 8)
                                                                    .map((image) => {
                                                                        return (
                                                                            <Grid item xs={12} sm={6} md={3}>
                                                                                <div>
                                                                                    <Card style={{ width: '100%', height: '230px', }} >
                                                                                        <CardActionArea>
                                                                                            <CardMedia
                                                                                                component="img"
                                                                                                image={Sold6}
                                                                                                alt="Image"
                                                                                                sx={{ filter: 'brightness(0.5)' }}
                                                                                            />
                                                                                            <Box
                                                                                                sx={{
                                                                                                    position: 'absolute',
                                                                                                    top: '50%',
                                                                                                    left: '50%',
                                                                                                    transform: 'translate(-50%, -50%)',
                                                                                                }}
                                                                                            >
                                                                                                <Link href="https://www.homewastate.com/Our-Listings" underline="none" target="_blank" rel="noopener noreferrer">
                                                                                                    <Button variant="outlined" sx={{
                                                                                                        color: '#fff',
                                                                                                        fontSize: 'larger',
                                                                                                        '&.MuiButton-outlined': {
                                                                                                            borderColor: '#fff',
                                                                                                            color: '#fff',
                                                                                                            '&:hover': {
                                                                                                                cursor: 'pointer',
                                                                                                                transform: 'scale(1.05)',
                                                                                                                borderColor: '#fff',
                                                                                                                color: '#fff',
                                                                                                            },
                                                                                                        }
                                                                                                    }}>
                                                                                                        Load More
                                                                                                    </Button>
                                                                                                </Link>
                                                                                            </Box>
                                                                                        </CardActionArea>
                                                                                    </Card>
                                                                                </div>
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                            </>
                                                    }

                                                </Grid>
                                                <Box
                                                    sx={{
                                                        m: '1em',
                                                        fontSize: matches ? '15px' : 'h4.fontSize',
                                                        fontWeight: 500,
                                                        textAlign: 'left',
                                                        color: 'rgb(7 6 40 / 87%)',
                                                        fontFamily: 'Rubik'
                                                    }}>
                                                    Deepti Gupta Past Sales
                                                </Box>
                                                <Grid container spacing={2}>
                                                    {
                                                        Sold.slice(0, 11).map((card) => {
                                                            return (
                                                                <>
                                                                    <Grid item xs={12} sm={3} md={2} display="flex" justifyContent="center" alignItems="center">
                                                                        <ImagePaper
                                                                            elevation={3}
                                                                            sx={{ backgroundImage: `url(${card.Image})` }}
                                                                        />
                                                                    </Grid>
                                                                </>

                                                            )
                                                        })
                                                    }
                                                    {
                                                        Sold.slice(11, 12).map((card) => {
                                                            return (
                                                                <Grid item xs={12} sm={3} md={2} display="flex" justifyContent="center" alignItems="center" >
                                                                    <Card sx={{ width: matches ? '150px' : '230px', borderRadius: '15px' }}>
                                                                        <CardActionArea>
                                                                            <ImagePaper
                                                                                elevation={3}
                                                                                sx={{ backgroundImage: `url(${card.Image})` }}
                                                                            />
                                                                            <Box
                                                                                sx={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translate(-50%, -50%)',
                                                                                }}
                                                                            >
                                                                                <Link href="https://www.homewastate.com/Our-Solds" underline="none" target="_blank" rel="noopener noreferrer">
                                                                                    <Button variant="outlined" sx={{
                                                                                        color: '#fff',
                                                                                        fontSize: 'smaller',
                                                                                        '&.MuiButton-outlined': {
                                                                                            borderColor: '#fff',
                                                                                            color: '#fff',
                                                                                            '&:hover': {
                                                                                                cursor: 'pointer',
                                                                                                transform: 'scale(1.05)',
                                                                                                borderColor: '#fff',
                                                                                                color: '#fff',
                                                                                            },
                                                                                        }
                                                                                    }}>
                                                                                        Load More
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>
                                                                        </CardActionArea>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })
                                                    }

                                                </Grid>

                                            </Paper>
                                        </Paper>
                                }

                            </>
                        )
                    })
                }
            </>

        </Grid >
    )
}

export default Classified

const Testimonials = [
    {
        name: 'Pooja Shanbhag',
        date: '6/24/2023',
        FeedBack: 'I had the pleasure of working with Deepti and her team for my first-time home purchase. Their expertise and professionalism made the entire process smooth and stress-free. They listened to my needs, provided personalized property options, and displayed exceptional negotiation skills. Their responsiveness, attention to detail, and extensive professional network ensured a successful and satisfying experience. I highly recommend their services to anyone in search of a top-notch agent. Throughout the journey, their deep understanding of the real estate market and impressive knowledge of the local area instilled trust and reliability. They were always available to address concerns and provide guidance, making me feel empowered and supported. If you`re a first-time home buyer seeking an exceptional agent, I wholeheartedly recommend their services.',
        image: `${Sold1}`,
        rating: 5
    },
    {
        name: 'Manjary Modi',
        date: '06/20/2023',
        FeedBack: "We’re incredibly happy that we went with Deepti and team for our home purchase. Home buying process can be daunting, but the entire process was eased along the way.” This was our first home and being newbie in the market, Deepti not only helped us with finding our dream home but also educated us about what are we getting into, she explored our liking. She knows the market, also the need to client really well and help us getting into the the ideal contract with perfect negotiation, which exceeded our expectation. Beyond their expertise and professionalism, the entire team special mention to Elena and Becky were amicable and also come up with all the ground work done on the listing before the showing, which made us get into the home with no shock after purchase. They answered all my questions with in-depth explanations, ensuring we fully understood what we were doing and why it was necessary.” Thank you Deepti and team for helping us to get our first home. We highly recommend them to anyone looking for top-notch real estate services",
        image: `${Sold2}`,
        rating: 5
    },
    {
        name: 'Prathap Muniraju',
        date: '6/20/2023',
        FeedBack: "I first got introduced to Deepti in 2020. While I was not looking to buy a house then, a friend of mine said “Deepti is an awesome!” So I wanted to stay in touch. Fast forward three years, my wife and I ended up buying our dream home - thanks to Deepti. The market was hard to read, rates were high - if not for the right guidance from Deepti, we could have been still looking for a house now. Go with Team Deepti - you can never go wrong!",
        image: `${Sold3}`,
        rating: 5
    },
    {
        name: 'Sekhar Gummuluri',
        date: '6/3/2023',
        FeedBack: 'Our home buying experience with Deepti was unforgettable, and could not have gone better! Deepti is thorough, knows the market trends well and was patient with our questions/concerns, worked hard to meet our needs, and her expertise made us feel confident throughout the whole process. I worked with other agents (who mostly try to “sell”) but my experience with Deepti was different. She focused more on understanding my needs first and then educating us on the market. The amount of knowledge that Deepti shared, hours/days of time she spent to “educate” us on market trends, how to strategize offer submissions, etc was phenomenal. Though it wasn’t my first time buying a house, I felt I couldn’t have found the right property in right time for the right price without Deepti’s help. There were times when I was unknowingly making a wrong decision to put an offer, she proactively suggests not to, knowing my that the property may not be the right choice for my family needs or other issues with the property. Deepti also helped immensely in finding a right tenant for my other house which I appreciate so much. I highly recommend Deepti to anyone and can confidently say that she can be trusted. I don’t say this because I got my property through Deepti but I experienced that she truly likes her job and what she does for her customers, very helpful in nature, aggressive in going above and beyond to meet her client needs. If you are in the market to buy a property, please do not hesitate to talk to Deepti because you will experience good for yourself for sure.',
        image: `${Sold4}`,
        rating: 5
    },
    {
        name: 'Charu Arora',
        date: '5/31/2023',
        FeedBack: "Our realtor, Deepti, was incredibly helpful in finding homes that met our requirements. She was always available and went above and beyond to ensure our comfort throughout the entire process. Deepti was able to answer all our questions and get any information we needed promptly. She was also quick to arrange showings when requested. Most importantly, Deepti never pressured us into purchasing anything we didn't like or hesitate about. Her patience and friendly attitude were her greatest strengths as a realtor. Thanks to her hard work, we were able to secure our dream house for a fantastic price. Deepti has even continued to be responsive and helpful after the closing. She has truly become a part of our family. I highly recommend her to anyone looking for a realtor who can help them find their dream home at the right price.",
        image: `${Sold5}`,
        rating: 5
    }
]

const Youtube = [
    { Vedio: `https://youtu.be/PS1bSXglVhI` },
    { Vedio: `https://youtu.be/jCazxGcsDpk` },
    { Vedio: `https://youtu.be/1pjdqaIGgLo` },
    { Vedio: `https://youtu.be/WD7E0tt5YLo` },
    { Vedio: `https://youtu.be/3Q0eeC7BG3s` },
    { Vedio: `https://youtu.be/r-2WMHTkfZY` },
    { Vedio: `https://youtu.be/VyKAFrY-GWY` },
    { Vedio: `https://youtu.be/VyKAFrY-GWY` },
]

const Sold = [
    { Image: `${S1}` },
    { Image: `${S2}` },
    { Image: `${S3}` },
    { Image: `${S4}` },
    { Image: `${S5}` },
    { Image: `${S6}` },
    { Image: `${S7}` },
    { Image: `${S8}` },
    { Image: `${S9}` },
    { Image: `${S10}` },
    { Image: `${S11}` },
    { Image: `${S12}` },
]
// import * as firebase from 'firebase';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyADFPS4-39K7aHOLn3k5LVSimHVnyITDi8",
  authDomain: "testnewsite-ab90e.firebaseapp.com",
  databaseURL: "https://testnewsite-ab90e-default-rtdb.firebaseio.com",
  projectId: "testnewsite-ab90e",
  storageBucket: "testnewsite-ab90e.appspot.com",
  messagingSenderId: "1042412127498",
  appId: "1:1042412127498:web:cf78a5bb0c2ee8a097a6f3",
  measurementId: "G-PK0399494E"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const db = getFirestore(app);
export default app;
import React from 'react'
import { Box, Button, Card, CardContent, CardMedia, Container, CssBaseline, Divider, Grid, LinearProgress, Paper, Snackbar, styled, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import coverImg from '../../images/BgImages/cntct.jpg'
import coverImg1 from '../../images/BgImages/contact1.jpg'
import emailjs from '@emailjs/browser';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertError = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomPaper = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    padding: 30,
    width: '100%',
    height: 400,
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em'
}))


const CustomPaper1 = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4em',
    // marginLeft: '-1em',
    height: 200,
    padding: '30px'
}))

export default function ContactSUs() {
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"))

    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClickError = () => {
        setOpenError(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);

    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setProgress(true)
        setLoading(true)
        emailjs.sendForm(
            'service_99mb4qv',
            'template_x99g4zj',
            e.target,
            'w_MtG-p6OHY0Tu_2g')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);
                setProgress(false);
                setLoading(false);
            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
                setLoading(false);
                setProgress(false);
            });

        e.target.reset()
    }

    return (
        <Box>
            <Box component="main" sx={{ flexGrow: 1, p: isMatch ? 1 : 3 }}>
                {
                    isMatch ?
                        <CustomPaper1>
                            <Typography gutterBottom
                                sx={{
                                    pt: '25px',
                                    pb: '18px',
                                    color: '#fff',
                                    fontSize: '3em',
                                    textAlign: 'center',

                                }}>
                                Contact Us
                            </Typography>
                        </CustomPaper1>
                        :
                        <CustomPaper>
                            <Typography gutterBottom
                                sx={{
                                    pt: '2em',
                                    pb: '1em',
                                    color: '#fff',
                                    fontSize: '4em',
                                    textAlign: 'center',

                                }}
                            >
                                Contact Us
                            </Typography>
                        </CustomPaper>
                }
                <Container maxWidth="lg">
                    <CssBaseline />
                    <Card sx={{ display: 'flex', m: '3em 0em' }}>
                        {isMatch ?
                            null :
                            <CardMedia
                                component="img"
                                sx={{ width: '50%', height: '110vh' }}
                                image={coverImg1}
                                alt="Live from space album cover"
                            />}
                        <Box sx={{ display: 'flex', flexDirection: 'column', p: isMatch ? '1em' : '5em' }}>
                            <CardContent sx={{ flex: '2 0 auto', mt: isMatch ? '1em' : '7em' }}>
                                <Typography component="div" variant="h4" color="primary">
                                    Contact us
                                </Typography>
                                <Box component="form" onSubmit={handleEmailSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        type="email"
                                        autoFocus
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        name="name"
                                        autoComplete="name"
                                        className="text_contact"
                                        onChange={handleChange}
                                    />
                                    {/* <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="number"
                                    label="Number"
                                    name="number"
                                    type="number"
                                    autoComplete="number"
                                    onChange={handleChange}
                                /> */}
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="outlined-multiline-static"
                                        label="Message"
                                        name="message"
                                        multiline
                                        rows={4}
                                        autoComplete="message"
                                        onChange={handleChange}
                                    />
                                    {progress && <LinearProgress style={{ marginTop: "10px" }} />}
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        sx={{ mt: '1em' }}
                                    // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    >
                                        Send
                                    </Button>
                                    <Snackbar open={open} onClose={handleClose} autoHideDuration={6000} sx={{p:isMatch ? '20px' : '34px', ml: isMatch ? null : '12em'}}>
                                        <Alert onClose={handleClose} severity="success">
                                            Thank you for getting in touch! We appreciate you contacting us. One of our team members will get back in touch with you soon. Have a great day!
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={openError} onClose={handleClose} autoHideDuration={6000} sx={{p:isMatch ? '20px' : '34px', ml: isMatch ? null : '9em'}}>
                                        <Alert onClose={handleClose} severity="error">
                                            Please try again!
                                        </Alert>
                                    </Snackbar>
                                </Box>
                            </CardContent>

                        </Box>
                    </Card>
                </Container>
            </Box>
        </Box>
    )
}
//how to use Mu
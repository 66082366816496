import * as React from 'react';
import { DateRangeOutlined, DateRangeRounded, LocationOnRounded, NavigateBeforeOutlined, PeopleAltRounded, WorkspacePremiumRounded } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/joy';
import { Button, Card, CardContent, Divider, Fab, Grid, Paper, styled, } from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import { useNavigate } from 'react-router-dom';
import coverImg from '../../images/BgImages/Img15.jpg'
import GradeIcon from '@mui/icons-material/Grade';
import { MobClassified } from './Mobile/MobClassified';


const CustomPaper = styled(Paper)(({ theme }) => ({
  background: ` linear-gradient(
    rgb(197 192 192 / 7%), 
    rgb(0 0 0 / 28%)
  ),url(${coverImg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'norepeat',
  backgroundPosition: 'center center',
  overflow: 'hidden',
  // minHeight: '65vh',
  width: '100%',
  alignItems: 'start',
  justifyContent: 'start',
  marginTop: '7em'
}))

const CustomPaper1 = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#c90043f2',
  backgroundSize: 'cover',
  backgroundRepeat: 'norepeat',
  backgroundPosition: 'inherit inherit',
  overflow: 'hidden',
  width: '97%',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1em',
  borderRadius: '5px'
}))

const CustomPaper2 = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#1976d2',
  backgroundSize: 'cover',
  backgroundRepeat: 'norepeat',
  backgroundPosition: 'inherit inherit',
  overflow: 'hidden',
  width: '97%',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1em',
  borderRadius: '5px'
}))



const convertToSlug = (slug) => {
  return slug
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

function ClassifiedsList(props) {
  const { BusinessData, matches } = props;
  const history = useNavigate();
  const businesspages = BusinessData.map(item => item.businessType.title);
  const businessPages = [...new Set(businesspages)];
  const handleClick = () => {
    window.scroll({ behavior: 'smooth', top: 0 })
  }
  // const getHouse = (slug) => {
  //   let temphouse = [...BusinessData];
  //   const propertyslug = temphouse.find(home => home.slug === slug);
  //   console.log("slug",propertyslug)
  //   return propertyslug;
  // };

  console.log('date', BusinessData.filter(item => item.premium === 'Yes' && item.premium === 'Both'));
  return (
    <>
      <Grid container spacing={0}
        sx={{
          mt: matches ? '1em' : '5em',
          borderRadius: '5px',
          backgroundColor: 'rgb(255, 255, 255)',
          color: 'rgb(33, 43, 54)',
          transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundImage: 'none',
          overflow: 'hidden',
          boxShadow: 'rgb(145 158 171 / 16%) -16px 16px 56px -8px',
          borderRadius: '16px',
          position: 'relative',
          zIndex: 0,
        }}>
        {/* <CustomPaper1 xs={12}>
                <Typography textColor='#fff' sx={{ fontSize: '2.5em', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} startDecorator={<WorkspacePremiumRounded sx={{ fontSize: '1em', color: '#fff' }} />}>Premium Classified</Typography>
              </CustomPaper1> */}
        {/* <Grid xs={12} >
              <body
                style={{
                  padding: 10,
                  maxWidth: 600,
                  margin: '50px 0px 30px',
                  borderLeft: '14px solid',
                  paddingLeft: '20px',
                  borderColor: 'indianred',
                  color: '#4b4b4be0',
                  fontSize: '18px',
                  background: 'rgb(0 0 0 / 12%)',
                }}
              >
                <Typography
                  variant='h5'
                  sx={{ color: '#02367d', fontSize: '2em', fontFamily: 'ITC Clearface,serif', textTransform: 'capitalize' }}
                >
                  Category: {businessPages}
                </Typography>
              </body>
            </Grid> */}

        {
          BusinessData
            .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
            .map((item) => {
              return (
                <Grid item xs={6} sx={{ mt: matches ?'10px' : '1em', mb: '1em' }} align="center">
                  <Box sx={{ width:matches ? 200 :  700, minHeight: 350 }} key={item.title} align='center'>
                    <Card
                      elevation={5}
                      sx={{ ml: '5px' }}
                    >
                      <CardContent
                        sx={{ p: '32px 24px' }}>
                        <Grid xs={12} align='start'>
                          <Typography
                            sx={{ fontSize: '1.5rem', fontFamily: 'ITC Clearface,serif', }}
                            level="h1" fontSize="sm" textColor='#02367d' mb={1} fontWeight="md">{item.title}</Typography>
                        </Grid>
                        <Box sx={{ display: 'flex' }}>
                          <AspectRatio ratio="1" sx={{ width: 100, height: 50, borderRadius: '5px' }}>
                            <img
                              src={item.agentProfile[0]}
                              alt=""
                            />
                          </AspectRatio>
                          <div style={{ marginLeft: '1em' }}>
                            <Typography
                              sx={{
                                textAlign: 'start',
                                fontWeight: 500,
                                fontSize: '1.0825rem',
                                lineHeight: '1.375',
                                fontFamily: 'ITC Clearface,serif',
                                color: 'rgb(18, 24, 40)',
                                textDecoration: 'none',
                              }}
                            >
                              {item.businessType.title}
                            </Typography>
                            <Typography
                              level="h2"
                              mb={0.5}
                              startDecorator={<PeopleAltRounded sx={{ color: '#6c757d' }} />}
                              sx={{ color: '#000', }}
                            >
                              {item.advertiserName}
                            </Typography>
                            <Typography level="body1" textColor='#000' mb={0.5} startDecorator={<DateRangeRounded sx={{ color: '#6c757d' }} />}>Expire Date: {item.expireDate} </Typography>
                            <Typography level="body2" textColor='#6c757d' mb={0.5} startDecorator={<LocationOnRounded sx={{ color: '#ff0055' }} />}>{item.city.title}</Typography>
                          </div>
                        </Box>

                        <Divider sx={{ mt: '1em', }} />
                        <Grid container spacing={0} align='start' sx={{ mt: '1em', }}>
                          <Grid xs={12}> <Typography sx={{ fontSize: '18px' }} mb={0.5} fontSize="lg" >{item.shortDescription}</Typography></Grid>
                          <Grid xs={4} ><Button variant="contained" color='primary' onClick={() => history(`/classified/${item.businessSlug}`)} onClick1={handleClick}>More Details</Button></Grid>

                        </Grid>
                      </CardContent>


                    </Card>
                  </Box>
                </Grid>
              )
            })
        }
      </Grid>



    </>


  )
}

export default ClassifiedsList

import React from 'react'
import coverImg from '../../../images/BgImages/Img15.jpg';
import { useNavigate } from 'react-router-dom';
import { DateRangeOutlined, DateRangeRounded, LocationOnRounded, PeopleAltRounded, WorkspacePremiumRounded } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Grid, Paper, styled, Typography, useMediaQuery } from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import GradeIcon from '@mui/icons-material/Grade';

const CustomPaper = styled(Paper)(({ theme }) => ({
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${coverImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'center center',
    overflow: 'hidden',
    // minHeight: '65vh',
    width: '110%',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '6em',
    marginLeft: '-1em',
    padding: '45px'
}))

const CustomPaper1 = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: '#c90043f2',
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'inherit inherit',
    overflow: 'hidden',
    width: '97%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0em',
    borderRadius: '5px'
}))

const CustomPaper2 = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: '#1976d2',
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    backgroundPosition: 'inherit inherit',
    overflow: 'hidden',
    width: '97%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0em',
    borderRadius: '5px'
}))

export const MobClassified = (props) => {
    const { BusinessData } = props;
    const isMatch = useMediaQuery('(max-width:350px)');
    const history = useNavigate();

    const handleClick = () => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }
    return (
        <Box>
            <CustomPaper >
                <Typography gutterBottom sx={{ color: '#fff', fontSize: '1.5em', fontFamily: 'ITC Clearface,serif' }}>Seattle Indian Classifieds</Typography>
            </CustomPaper>
            <Grid container spacing={1}
                sx={{
                    mt: '3em',
                    borderRadius: '5px'
                }}>
                <CustomPaper1 xs={12}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <WorkspacePremiumRounded
                            sx={{ fontSize: '1.5em', color: '#fff' }} />
                        <Typography textColor='#fff'
                            sx={{
                                fontSize: '1.5em',
                                textAlign: 'center',
                                fontFamily: 'ITC Clearface,serif',
                                color: '#fff',
                                ml: '10px'
                            }}
                        >
                            Premium Classified
                        </Typography>
                    </Box>
                </CustomPaper1>
                {
                    BusinessData
                        .filter(item => item.premium === 'Both' || item.premium === 'Yes')
                        .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
                        .map((item) => {
                            return (
                                <Grid item xs={12} sx={{ mt: '1em', mb: '1em' }} >
                                    <Box sx={{ minHeight: 350 }} key={item.title} align='center'>
                                        <Card
                                            elevation={2}
                                            sx={{
                                                backgroundColor: 'rgb(255, 255, 255)',
                                                color: 'rgb(18, 24, 40)',
                                                transition: 'boxShadow 300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
                                                borderRadius: '5px',
                                                boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
                                                backgroundImage: 'none',
                                                overflow: 'hidden',

                                            }}

                                        >
                                            <CardContent
                                                sx={{ p: '20px 24px' }}>
                                                <Grid xs={12} align='start'>
                                                    <Typography
                                                        sx={{ fontSize: isMatch ? '1.3rem' : '1.3rem', fontFamily: 'ITC Clearface,serif', color: '#02367d', fontWeight: '500' }}
                                                        level="h1" fontSize="sm" textColor='#02367d' mb={1} fontWeight="md">{item.title}</Typography>
                                                </Grid>
                                                <Box sx={{ display: 'flex' }}>
                                                    <AspectRatio ratio="1" sx={{ width: 100, height: 50, borderRadius: '5px' }}>
                                                        <img
                                                            src={item.agentProfile[0]}
                                                            alt=""
                                                        />
                                                    </AspectRatio>
                                                    <div style={{ marginLeft: '1em' }}>
                                                        <Typography
                                                            sx={{
                                                                ml: '2px',
                                                                fontWeight: 500,
                                                                fontSize: '1.0825rem',
                                                                lineHeight: '1.375',
                                                                fontFamily: 'ITC Clearface,serif',
                                                                color: 'rgb(18, 24, 40)',
                                                                textDecoration: 'none',
                                                                textAlign: 'initial'
                                                            }}
                                                        >
                                                            {item.businessType.title}
                                                        </Typography>
                                                        <Box sx={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                            alignItems: 'start', mt: '5px'
                                                        }}>
                                                            <PeopleAltRounded sx={{ fontSize: isMatch ? '18px' : '20px', color: '#6c757d' }} />
                                                            <Typography
                                                                sx={{
                                                                    color: '#000',
                                                                    fontSize: isMatch ? '14px' : '17px',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    ml: '5px'
                                                                }}
                                                            >
                                                                {item.advertiserName}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                            alignItems: 'start', mt: '5px'
                                                        }}>
                                                            <DateRangeRounded sx={{ fontSize: isMatch ? '18px' : '20px', color: '#6c757d' }} />
                                                            <Typography
                                                                sx={{
                                                                    color: '#000',
                                                                    fontSize: isMatch ? '13px' : '16px',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    // ml: '5px'
                                                                }}
                                                            >
                                                                Expire Date: {item.expireDate}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                            alignItems: 'start', mt: '5px'
                                                        }}>
                                                            <LocationOnRounded sx={{ fontSize: isMatch ? '18px' : '20px', color: '#ff0055' }} />
                                                            <Typography
                                                                sx={{
                                                                    color: '#6c757d',
                                                                    fontSize: isMatch ? '14px' : '17px',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    ml: '5px'
                                                                }}
                                                            >
                                                                {item.city.title}
                                                            </Typography>
                                                        </Box>

                                                    </div>
                                                </Box>
                                                <Divider sx={{ mt: '1em', }} />
                                                <Grid container spacing={0} align='start' sx={{ mt: '1em', }}>
                                                    <Grid xs={12}> <Typography sx={{ fontSize: isMatch ? '16px' : '17px', fontFamily: 'ITC Clearface,serif', }} mb={0.5} fontSize="lg" >{item.shortDescription}</Typography></Grid>
                                                    <Grid xs={12} align='end'>
                                                        <Box >
                                                            <Button
                                                                variant='contained'
                                                                size='medium'
                                                                onClick={() => history(`${item.businessSlug}`)}
                                                                onClick1={handleClick}
                                                            >
                                                                More Details
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            )
                        })
                }
            </Grid>
            <Grid container spacing={1}
                sx={{
                    mt: '3em',
                    borderRadius: '5px'
                }}>
                <CustomPaper2 xs={12}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <GradeIcon
                            sx={{ fontSize: '1.5em', color: '#fff' }} />
                        <Typography textColor='#fff'
                            sx={{
                                fontSize: '1.5em',
                                textAlign: 'center',
                                fontFamily: 'ITC Clearface,serif',
                                color: '#fff',
                                ml: '10px'
                            }}
                        >
                            Latest Classified
                        </Typography>
                    </Box>
                </CustomPaper2>
                {
                    BusinessData
                        .filter(item => item.premium === 'Both' || item.premium === 'No')
                        .sort((a, b) => (b.releaseDate > a.releaseDate) ? 1 : -1)
                        .map((item) => {
                            return (
                                <Grid item xs={12} sx={{ mt: '1em', mb: '1em' }} >
                                    <Box sx={{ minHeight: 350 }} key={item.title} align='center'>
                                        <Card
                                            elevation={2}
                                            sx={{
                                                backgroundColor: 'rgb(255, 255, 255)',
                                                color: 'rgb(18, 24, 40)',
                                                transition: 'boxShadow 300ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
                                                borderRadius: '5px',
                                                boxShadow: 'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px',
                                                backgroundImage: 'none',
                                                overflow: 'hidden',

                                            }}

                                        >
                                            <CardContent
                                                sx={{ p: '20px 24px' }}>
                                                <Grid xs={12} align='start'>
                                                    <Typography
                                                        sx={{ fontSize: isMatch ? '1.3rem' : '1.3rem', fontFamily: 'ITC Clearface,serif', color: '#02367d', fontWeight: '500' }}
                                                        level="h1" fontSize="sm" textColor='#02367d' mb={1} fontWeight="md">{item.title}</Typography>
                                                </Grid>
                                                <Box sx={{ display: 'flex' }}>
                                                    <AspectRatio ratio="1" sx={{ width: 100, height: 50, borderRadius: '5px' }}>
                                                        <img
                                                            src={item.agentProfile[0]}
                                                            alt=""
                                                        />
                                                    </AspectRatio>
                                                    <div style={{ marginLeft: '1em' }}>
                                                        <Typography
                                                            sx={{
                                                                ml: '2px',
                                                                fontWeight: 500,
                                                                fontSize: '1.0825rem',
                                                                lineHeight: '1.375',
                                                                fontFamily: 'ITC Clearface,serif',
                                                                color: 'rgb(18, 24, 40)',
                                                                textDecoration: 'none',
                                                                textAlign: 'initial'
                                                            }}
                                                        >
                                                            {item.businessType.title}
                                                        </Typography>
                                                        <Box sx={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                            alignItems: 'start', mt: '5px'
                                                        }}>
                                                            <PeopleAltRounded sx={{ fontSize: isMatch ? '18px' : '20px', color: '#6c757d' }} />
                                                            <Typography
                                                                sx={{
                                                                    color: '#000',
                                                                    fontSize: isMatch ? '14px' : '17px',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    ml: '5px'
                                                                }}
                                                            >
                                                                {item.advertiserName}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                            alignItems: 'start', mt: '5px'
                                                        }}>
                                                            <DateRangeRounded sx={{ fontSize: isMatch ? '18px' : '20px', color: '#6c757d' }} />
                                                            <Typography
                                                                sx={{
                                                                    color: '#000',
                                                                    fontSize: isMatch ? '13px' : '16px',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    // ml: '5px'
                                                                }}
                                                            >
                                                                Expire Date: {item.expireDate}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{
                                                            display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                            alignItems: 'start', mt: '5px'
                                                        }}>
                                                            <LocationOnRounded sx={{ fontSize: isMatch ? '18px' : '20px', color: '#ff0055' }} />
                                                            <Typography
                                                                sx={{
                                                                    color: '#6c757d',
                                                                    fontSize: isMatch ? '14px' : '17px',
                                                                    textAlign: 'center',
                                                                    fontFamily: 'ITC Clearface,serif',
                                                                    ml: '5px'
                                                                }}
                                                            >
                                                                {item.city.title}
                                                            </Typography>
                                                        </Box>

                                                    </div>
                                                </Box>
                                                <Divider sx={{ mt: '1em', }} />
                                                <Grid container spacing={0} align='start' sx={{ mt: '1em', }}>
                                                    <Grid xs={12}> <Typography sx={{ fontSize: isMatch ? '16px' : '17px', fontFamily: 'ITC Clearface,serif', }} mb={0.5} fontSize="lg" >{item.shortDescription}</Typography></Grid>
                                                    <Grid xs={12} align='end'>
                                                        <Box >
                                                            <Button
                                                                variant='contained'
                                                                size='medium'
                                                                onClick={() => history(`${item.businessSlug}`)}
                                                                onClick1={handleClick}
                                                            >
                                                                More Details
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            )
                        })
                }
            </Grid>
        </Box >
    )
}

//how to create agent profile page in reactjs
import React from 'react'
import { Card, CardMedia, Grid, Paper } from '@mui/material';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import defaultimg from '../../images/NoImage_Available.png';

function ImageContainer(props) {
    const { Images, Name, matches } = props;

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        },
        thumbnails: {
            showThumbnails: false
        }
    }

    return (
        <>
            {
                matches ?
                    null
                    :
                    <>
                        {Images ? null : <SRLWrapper options={options}>
                            <Card elevation={5} style={{ height: 450 }} >
                                <CardMedia
                                    style={{ height: "100%", width: "100" }}
                                    component="img"
                                    image={defaultimg}
                                    title={Name}
                                />
                            </Card>
                        </SRLWrapper>}
                        {(() => {
                            if (Images) {
                                if (Images.length <= 6) {
                                    return <SimpleReactLightbox>
                                        <Paper elevation={0}>
                                            <Grid container spacing={2}  >
                                                <Grid item xs >
                                                    {Images[0] && <SRLWrapper options={options}>
                                                        <Card elevation={5} style={{ height: 220 }} >
                                                            <CardMedia
                                                                style={{ height: "100%", width: "100" }}
                                                                component="img"
                                                                image={Images[0] || defaultimg}
                                                                src="Images"
                                                                title={Name}
                                                            />
                                                        </Card>
                                                    </SRLWrapper>}
                                                </Grid>
                                                <Grid item xs>
                                                    {Images[1] && <SRLWrapper options={options}>
                                                        <Card elevation={5} style={{ height: 220 }} >
                                                            <CardMedia
                                                                style={{ height: "100%", width: "100" }}
                                                                component="img"
                                                                image={Images[1] || defaultimg}
                                                                src="Images"
                                                                title={Name}
                                                            />
                                                        </Card>
                                                    </SRLWrapper>}
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}  >
                                                <Grid item xs>
                                                    {Images[2]
                                                        && <SRLWrapper options={options}>
                                                            <Card elevation={5} style={{ height: 220 }} sx={{ mt: '10px' }}>
                                                                <CardMedia
                                                                    style={{ height: "100%", width: "100" }}
                                                                    component="img"
                                                                    image={Images[2] || defaultimg}
                                                                    src="Images"
                                                                    title={Name}
                                                                />
                                                            </Card>
                                                        </SRLWrapper>}
                                                </Grid>
                                                <Grid item xs>
                                                    {Images[3] && <SRLWrapper options={options}>
                                                        <Card elevation={5} style={{ height: 220 }} sx={{ mt: '10px' }}>
                                                            <CardMedia
                                                                style={{ height: "100%", width: "100" }}
                                                                component="img"
                                                                image={Images[3] || defaultimg}
                                                                src="Images"
                                                                title={Name}
                                                            />
                                                        </Card>
                                                    </SRLWrapper>}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </SimpleReactLightbox>
                                }

                                if (Images.length >= 7) {
                                    return <SimpleReactLightbox>
                                        <Paper elevation={0}>
                                            <Grid container spacing={2}  >
                                                <Grid item xs >
                                                    {Images[0] && <SRLWrapper options={options}>
                                                        <Card elevation={5} style={{ height: 220 }} >
                                                            <CardMedia
                                                                style={{ height: "100%", width: "100" }}
                                                                component="img"
                                                                image={Images[0] || defaultimg}
                                                                src="Images"
                                                                title={Name}
                                                            />
                                                        </Card>
                                                    </SRLWrapper>}
                                                </Grid>
                                                <Grid item xs>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs>
                                                            {Images[1] && <SRLWrapper options={options}>
                                                                <Card elevation={5} style={{ height: 220 }} >
                                                                    <CardMedia
                                                                        style={{ height: "100%", width: "100" }}
                                                                        component="img"
                                                                        image={Images[1] || defaultimg}
                                                                        src="Images"
                                                                        title={Name}
                                                                    />
                                                                </Card>
                                                            </SRLWrapper>}
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}  >
                                                <Grid item xs>
                                                    {Images[2]
                                                        && <SRLWrapper options={options}>
                                                            <Card elevation={5} style={{ height: 215 }} sx={{ mt: '1em' }}>
                                                                <CardMedia
                                                                    style={{ height: "100%", width: "100" }}
                                                                    component="img"
                                                                    image={Images[2] || defaultimg}
                                                                    src="Images"
                                                                    title={Name}
                                                                />
                                                            </Card>
                                                        </SRLWrapper>}
                                                </Grid>
                                                <Grid item xs sx={{ mt: '1em' }}>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs>
                                                            <Grid container spacing={2} direction="column" >
                                                                <Grid item xs>
                                                                    {Images[3] && <SRLWrapper options={options}>
                                                                        <Card elevation={5} style={{ height: 100 }} >
                                                                            <CardMedia
                                                                                style={{ height: "100%", width: "100" }}
                                                                                component="img"
                                                                                image={Images[3] || defaultimg}
                                                                                src="Images"
                                                                                title={Name}
                                                                            />
                                                                        </Card>
                                                                    </SRLWrapper>}
                                                                </Grid>
                                                                <Grid item xs>
                                                                    {Images[4] && <SRLWrapper options={options}>
                                                                        <Card elevation={5} style={{ height: 100 }} >
                                                                            <CardMedia
                                                                                style={{ height: "100%", width: "100" }}
                                                                                component="img"
                                                                                image={Images[4] || defaultimg}
                                                                                src="Images"
                                                                                title={Name}
                                                                            />
                                                                        </Card>
                                                                    </SRLWrapper>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Grid container spacing={2} direction="column" >
                                                                <Grid item xs>
                                                                    {Images[5] && <SRLWrapper options={options}>
                                                                        <Card elevation={5} style={{ height: 100 }} >
                                                                            <CardMedia
                                                                                style={{ height: "100%", width: "100" }}
                                                                                component="img"
                                                                                image={Images[5]}
                                                                                src="Images"
                                                                                title={Name}
                                                                            />
                                                                        </Card>
                                                                    </SRLWrapper>}
                                                                </Grid>
                                                                <Grid item xs>
                                                                    {Images[6] && <SRLWrapper options={options}>
                                                                        <Card elevation={5} style={{ height: 100 }} >
                                                                            <CardMedia
                                                                                style={{ height: "100%", width: "100" }}
                                                                                component="img"
                                                                                image={Images[6] || defaultimg}
                                                                                src="Images"
                                                                                title={Name}
                                                                            />
                                                                        </Card>
                                                                    </SRLWrapper>}
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </SimpleReactLightbox>
                                }
                            }
                        })()}
                    </>
            }
        </>

    )
}

export default ImageContainer